/*------------------------------------*\
    $FORMS
\*------------------------------------*/
form ol, form ul {
	list-style: none;
	margin-left: 0;
}

fieldset {
	margin: 0;
	border: solid 1px $gray-lighter;
	//border-top: 3px solid $blue-dark;
	//padding: em($space-half);
	@include rem(margin-bottom, $space);
	@include rem(padding, $space-half);
	&.collapsed {
		border-top: 0;
	}

	.fieldset-title {
		@extend h4;
	}
}

label {
	display: block;
	@include rem(padding-bottom, $space-half);

}

button, input, select, textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0 1px 0;
}

input, textarea {
	width: 100%;
	border: 1px solid $gray;
	//padding: em($pad-half) 0.65rem;
	@include rem(padding, $pad-half 6px);
}

input[type=text], input[type=search], input[type=url], input[type=number], textarea, input[type="submit"] {
	-webkit-appearance: none;
	border-radius: 0;
}
.l-main {
	button, input[type="submit"] {
		//padding: $pad-half;
		//background: $gray-dark;
		//border: 1px solid $gray;
		@include button;
    @include rem(font-size, $font-size-base);
    line-height: $line-height-base;
		@include rem(margin, $space 0);
		cursor: pointer;
		width: auto;
	}
}
footer {
  button, input[type="submit"] {
    box-shadow: none;
  }
}
button {
  svg {
    @include rem(width, 28px);
  }
  .fill {
    fill: #FFF;
  }
  .stroke {
    stroke: #fff;
  }
  &.btn-svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form-type-checkbox,
.form-type-radio,
.views-exposed-form .form-type-bef-checkbox {
	@include rem(padding-left, $pad);

}
input[type="checkbox"],
input[type="radio"] {
    width: auto;
	@include rem(margin-right, 4px);
	@include rem(margin-left, -20px);
}
.checkbox {
	text-align: left;
}


input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

//Form Field Container
.field-container {
	@include rem(margin-bottom, $space);

}

.inline-form {
	fieldset, .inline-container {
		position: relative;
	}
	.l-main & {
		input[type=submit], button, .btn {
			@include rem(font-size, $font-size-base);
			@include rem(padding, $space-half 15px);

			background: $gray-dark;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			width: auto;

			&:hover, &:focus {
				background: $gray;
				color: white;
			}
		}
	}

}

/* Validation */
.has-error {
	border-color: $error;
}
.is-valid {
	border-color: $valid;
}


/*------------------------------------*\
    $SPECIFIC FORMS
\*------------------------------------*/

/* Search Form */
//.search-field {
//	padding-right: 3em;
//	@include rem(padding-right, $font-size-base);
//
//}
//.inline-form .search-submit {
//	background: none;
//	padding: 0.78em 1em;
//	border: 0;
//	border-left: 1px solid $gray;
//	color: $gray;
//}


//::-webkit-input-placeholder { /* Chrome/Opera/Safari */
//	color: $gray;
//}
//::-moz-placeholder { /* Firefox 19+ */
//	color: $gray;
//}
//:-ms-input-placeholder { /* IE 10+ */
//	color: $gray;
//}
//:-moz-placeholder { /* Firefox 18- */
//	color: $gray;
//}
