.node-type-movie {
  .l-main {
    @include rem(padding-bottom, $space-double);
  }
  .pane-views-panes {
    @include rem(margin-bottom, $space-and-half);

    border-bottom: 1px solid $gray-lighter;
    @include rem(padding-bottom, $pad);
  }
  .field--name-field-tagline {
    font-style: normal;
  }
  .pane-node-content {
    .field--name-field-image {
      float: left;
      @include rem(margin, 0 $space-half $space-half 0);
    }
  }

  .view-grouping-content {
    h4 {
      @include rem(margin-bottom, $space);
    }
  }
  .pane-node-field-rating {
    @include rem(margin, 8px 0);

  }
  .pane-node-field-ticket-info {
    @include rem(margin-top, 8px);
  }
  .two-col-top {
    background: $gray-darkest;
    //background: darken($gray-darkest, 5%);
    .l-container {
      width: 100%;
    }
    //padding: em($space) em($space-half);
    * {
      color: white;
    }
    .panel-col-last {
      padding: em($space) em($space-half);
    }
  }
  .two-col-bottom {
    @include rem(margin, $space-and-half 0);
  }
  @include breakpoint($tab) {
    .two-col-bottom {
      .panel-col-last {
        position: relative;
      }
      .pane-addthis-addthis-block {
        .wrapper--addthis {
          @include rem(top, -16px);
        }
      }
      .pane-node-content {
        @include rem(padding-top, $space);
      }
    }
  }
  @include breakpoint('screen' 760px) {
    .two-col-bottom {
      .panel-col-first {
        @include span(5);
      }
      .panel-col-last {
        @include span(7 last);
      }
    }

  }
  @include breakpoint($desk) {
    .two-col-top {
      .panel-col-first {
        @include span(6);
      }
      .panel-col-last {
        @include span(6 last);
        padding: 1em 0;
      }
      .panel-panel {
        margin-bottom: 0;
      }
    }
    .two-col-bottom {
      @include rem(margin, $space*2.5 0 $space-double);
      .pane-addthis-addthis-block {
        .wrapper--addthis {
          @include rem(top, -40px);

        }
      }
      .pane-node-content {
        padding-top: 0;
      }
      .panel-col-first {
        width: span(6);
      }
      .panel-col-last {
        width: span(6);
      }
    }
    .pane-views-panes {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }
  }

}

.node--movie {
  &.node-teaser,
  &.node--teaser {
    * {
      color: white;
    }
    .h2 a,
    h2 a {
      @include link-colors(white, $yellow, $yellow, white, white);
    }
    .btn-text {
      margin: 0;
      &:hover {
        color: $link-hover;

      }
    }
    background: darken($gray-darkest, 5%);
    .page-taxonomy-term & {
      .field--name-field-theater-id {
        display: none;
      }
    }
    &.ds-2col {
      > .group-left,
      > .group-right {
        float: none;
        width: 100%;
      }
    }
    .group-right {
      @include rem(padding, $space);
      padding-left: 2%;
      padding-right: 2%;
    }
    .paragraphs-item-ticket-info {
      @include rem(padding-top, 16px);
      @include rem(margin-top, -16px);
      @include rem(padding-left, 45px);

      //@include svg-background('movie-ticket');
      background-image: image-url('movie-ticket.png');
      background-repeat: no-repeat;
      background-position: 0 24px;
      //.btn {
      //  margin: em($space-half, $btn-font-size) 0 em($space, $btn-font-size);
      //}
      @include rem(margin-bottom, $space);
      .view-imax-festival & {
        @include rem(margin-bottom, $space-half);

      }
      &:last-child {
        margin-bottom: 0;
      }

    }
    @include breakpoint($desk) {
      &.ds-2col {
        > .group-left,
        > .group-right {
          @include span(6)
        }
        > .group-left {
          @include last;
          a {
            display: block;
            img {
              height: 100% !important;
              width: auto !important;
              min-height: 260px;
            }
          }
        }
      }
      .group-right {
        @include rem(padding, $space);
        padding-left: gutter();
        padding-right: gutter();

      }
      .group-summary {
        display: none;
      }
    }
    @include breakpoint($wide) {
      .group-right {
        @include rem(padding, $space-and-half);
        padding-left: gutter();
        padding-right: gutter();

      }
      &.ds-2col {
        > .group-left {
          a {
            img {
              height: auto !important;
              width: 100% !important;
            }
          }
        }
      }
    }
    @include breakpoint('screen' 1680px) {
      .group-summary {
        display: block;
      }
    }
  }
}

.entity-paragraphs-item.imax-description {
  @include rem(padding, $space-and-half 0);
  margin: 0;
}

.entity-paragraphs-item.imax-theaters {
  @include rem(margin, 0 0 $space-double);
  .node--teaser-long .inner {
    background: $gray;
    border-color: darken($gray, 2%);
  }
  .h2 a,
  h2 a {
    @include link-colors(white, $yellow, $yellow, white, white);
  }
}

.node-type-movie,
.node--movie--teaser {
  .paragraphs-item-ticket-info {
    .field--type-taxonomy-term-reference {
      @include breakpoint($tab) {
        display: inline-block;
       // @include rem(margin-right, $pad);
      }
      //@include breakpoint($desk) {
      //  display: block;
      //  @include rem(margin-right, $pad);
      //}
      //@include breakpoint($wide) {
      //  display: inline-block;
      //  @include rem(margin-right, $pad);
      //}
    }
  }
}

