@use "sass:math";

.view {
  .item-list {
    ul {
      @include reset-list;
    }
    li.spacing-bottom {
      @include rem(margin-bottom, $space);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .contextual-links-wrapper {
    z-index: 2000;
  }
  .views-row {
    .item-list ul {
      @extend %reset-list;
      li.spacing-bottom {
        @include rem(margin-bottom, $space-half);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    //&.spacing-bottom:last-child {
    //  margin-bottom: 0;
    //}

  }

  .more-link {
    @include font-icon($fa-var-angle-double-right, 10px, 14px, true);
    &:after {
      color: inherit;
      &:hover {
        color: inherit;
      }
    }
    &:hover {
      color: $link-hover;
    }
    @include rem(margin-top, $space);
  }
  h3 {
    @include rem(margin-bottom, $space-half);
  }
  .node--teaser h3,
  .node--teaser-long h3 {
    @include rem(margin, math.div($space, 3) 0);
  }
  .view-header,
  .view-filters {
    @include rem(margin-bottom, $space);
  }
  .view-header {
    font-weight: bold;
  }
}

.view-showtimes {
  .views-row {
    display: inline-block;
    @include rem(margin-right, 4px);
  }
  .btn.orange {
    // @include button(#e76e3c, white, darken(v, 10%));
    width: 75px;
    padding-right: 0;
    padding-left: 0;
    @include rem(margin-bottom, $space-half);
  }
  .view-empty {
    @include rem(font-size, $font-size-small);
  }
  .view-content {
    margin-bottom: 0;
  }

  .views-field-field-image-single a {
    display: inline-block;
  }
  .view-grouping {
    @include rem(margin-bottom, $space);
    .wrapper--rows {
      @include rem(margin-bottom, $space);
    }
  }
}


.view-book-children.swiper-container {
  .views-field a {
    @include rem(padding, $space-half $pad-half);
    border: 1px solid transparent;
    display: block;
    &.active {
      background: darken($gray-darkest, 10%);
      border-color: $gray-light;
    }
    &:hover {
      background: $gray-dark;
      border-color: $gray-light;
    }
    span {
      display: block;
    }
    .title {
      font-weight: bold;
      span {
        font-weight: normal;
        font-size: 75%;
      }
    }
  }
}
.view-jobs {
  @include breakpoint($tab) {
    th.views-field-title {
      width: 20%;
    }
    .two-col-top {
      > .l-container {
        justify-content: space-between;
      }
      .panel-col-last {
        margin-left: 4%;
        width: 40%;
      }
    }
  }
  .panel-display {
    .h4 {
      margin-bottom: 0;
    }
    .views-field-field-job-unit,
    .views-label {
      font-weight: 900;
    }
    .field-content {
      display: inline-block;
    }
    .views-field {
      max-width: 760px;
    }
  }
}
.view-book-children.view-display-id-panel_pane_1 {
  .views-row {
    background: white;
    @include rem(margin, $space auto);
    .node {
      @include set_layout($max-width);
    }
    .node__title {
      text-align: center;
    }
  }
  .views-row-first {
    @include rem(margin-top, $space);
  }
  @include breakpoint($tab) {
    .views-row {
      margin: 0 auto;
      @include rem(padding, $space-and-half 0);
      height: 500px;
      height: 60vh;
      img {
        width: 100%;
      }
      overflow: hidden;
      &.views-row-odd {
        background: $gray-lightest;
      }
    }
    .views-row-first {
     margin-top: 0;
    }
    .node--teaser-list.has-media,
    .node--teaser-featured.has-media {
      .b-media-wrapper,
      .b-text-wrapper {
        @include span(5);
        max-width: span(5);
      }
      .b-media-wrapper img {
        max-width: 100% !important;
        display: block;
        margin: auto;
      }
      .b-text-wrapper {
        @include span(7 last);
        max-width: span(7);

      }
    }
    .views-row-even {
      .node--teaser-list.has-media,
      .node--teaser-featured.has-media {
        .b-media-wrapper {
          @include last;
          order: 1;
        }
        .b-text-wrapper {
          margin-right: gutter();
        }
      }
    }
  }

  @include breakpoint($wide) {
    .node--teaser-list.has-media,
    .node--teaser-featured.has-media {
      .b-media-wrapper,
      .b-text-wrapper {
        @include span(6);
        max-width: span(6);
      }
      .b-text-wrapper {
        @include last;
      }
    }
    .views-row {
      height: 600px;
      height: 50vh;

    }
    .views-row-even {
      .node--teaser-list.has-media,
      .node--teaser-featured.has-media {
        .b-media-wrapper {
          @include last;
          order: 1;
        }
        .b-text-wrapper {
          margin-right: gutter();
        }
      }
    }
  }

}
.view-content.owl-carousel {
  .owl-stage {
    display: flex;
  }
  &.multiple {
    background: $gray-darkest;

    .owl-item {
      background: rgba(#FFF,.06);
      &:hover {
        background: darken($gray-dark, 5%);
      }
      a:hover {
        border-bottom: none;
      }
      img {
        max-height: 600px;
        object-fit: cover;
      }
      > .views-row > .views-field-item-id {
        @include rem(padding, $space-half);
      }
      * {
        color: white;
      }
      .tooltip-inner {
        color: $text-color;
        background: #fff;
      }
      .tooltip.in {
        opacity: .8;
      }
    }
    .btn {
      &.orange {
       // @include button(#f7ca18, white, darken(#f7ca18, 10%));
        &.inactive {
          opacity: .5;
        }
      }
    }
    .views-field-rendered-entity {
      .views-label {
        color: $orange-alt;
        font-weight: bold;
      }
    }
  }// end multiple
  &.single {
    .owl-item {
      background: darken($gray-darkest, 5%);
    }
    .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
      top: 20%;
    }
    .owl-dots {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

    }
    @include breakpoint($desk) {
      .owl-dots {
        width: span(6);
        @include rem(bottom, $space-half);
      }
    }

  }
}
.si-filters {
  @include set_layout($max-width);
  font-weight: 600;
  .inline-list.reset > li {
    @include rem(margin-right, $pad-half);
  }
}
.view-theaters {
  .wrapper--view-content {
    @include rem(padding, $space-half 0);
    background: $gray-darkest;
    .si-filters {
      @include rem(margin-bottom, $space-half);
      text-align: center;
      a:hover,
      a.active {
        color: $orange-alt;
      }
    }
    .filter-label {
      @include svg-background('filter');
      color: white;
      background-repeat: no-repeat;
      padding-left: 1.5em;
      background-size: 70% 70%;
      background-position: -20px 4px;
    }

  }
}
.view-id-theaters {
  @include breakpoint($tab) {
    >.view-title {
      @include rem(margin-right, 170px);
    }
  }

}

.view-related-content {
  .view-grid.horizontal {
    @include rem(margin-bottom, $space);
  }
}

.view-imax-theaters {
  .views-field {
    display: flex;
    width: 100%;
  }
  .node--teaser-long {
    width: 100%;
  }
  @include breakpoint($desk) {
    .center-media {
      .b-media-wrapper {
        height: 280px;
      }
    }
  }
  &.view-display-id-panel_pane_2 {
    .center-media {
      .b-media-wrapper {
        height: 160px;
      }
      h3 {
        margin-left: 0;
      }
    }
  }
}


.view-newsdesk-contacts-page {
  h2 {
    @include rem(margin, $space-double 0 $space);
  }
  h3 {
    @include rem(margin-bottom, $space-half);
  }
  //.b-media-wrapper {
  //  line-height: $line-height-base;
  //  @include rem(font-size, 10px);
  //}
  .attachment {
    @include rem(margin-top, $space);
    h2 {
      font-family: $font;
      color: $text-color;
      margin: 0;
      font-style: normal;
    }
  }
  @include breakpoint($tab) {
    .media-list {
      .b-text-wrapper {
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: span(6);
            max-width: span(6);
          }
        }
      }
    }
  }

  @include breakpoint($desk) {
    .node--teaser-featured.has-media .b-media-wrapper, .node--teaser-list.has-media .b-media-wrapper {
      width: 30%;
      max-width: 30%;
    }
  }
}
