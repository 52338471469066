.l-region--sidebar-first,
.l-region--sidebar-second,
 .l-region--left-aside,
 .l-region--right-aside {
   .entity-paragraphs-item {
     &,
     &.panel-pane {
       border: 1px solid darken($gray-lightest, 5%);
       background: $teaser-bg;
       .newsdesk & {
         background: #fff;
       }
     }


     //padding: em($pad-half) em($pad);
     //@include rem(padding, $space $pad);
     @include rem(padding, $space-half $pad);
     &.paragraphs-item-teaser-block,
     &.paragraphs-item-image-text-overlay {
        padding: 0;
     }
     .group-header {
       margin: 0;
       .title {
         padding: 0;
         background: none;
         text-align: left;
       }
     }
     &.no-style {
       padding: 0;
       background: none;
       border: none;
     }
   }
 }