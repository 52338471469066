/*------------------------------------*\
    $Table
\*------------------------------------*/
table {
	border-collapse: collapse;
	border-spacing: 0;
	border: 1px solid $gray;
	width: 100%;
  caption {
    @include rem(margin, $space 0);
    font-size: 112.5%;
    line-height: $baseLineHeight;
  }
}
th {
	text-align: left;
  background: $gray-lighter;
}

th, td {
  border: 1px solid #b9c0c7;
  @include rem(padding,8px 3px);
  vertical-align: top;

}
