
.views-exposed-form {
  .views-submit-button,
  .views-reset-button {
    display: inline-block;
    //box-shadow: none;
  }
  .fieldset {
    @include rem(margin-bottom, $space-half);
  }
  .form-item-field-unit,.form-item-field-unit-tid {
    @include rem(margin-top, $space-half);
  }
  //.form-type-checkbox .views-exposed-form .form-type-bef-checkbox {
  //  padding-left:0;
  //}
  input[type="submit"].js-hide {
    display: none;
  }
  .ctools-auto-submit-processed & {
    .views-submit-button {
      display: none;
    }
  }
  //.form-type-date-popup {
  //  position: relative;
  //}
  .form-item-created-value {
    @include rem(top, -28px);
  }
  .form-item-created-op {
    margin-left: 48px;
  }
  .bef-group-heading {
    @extend h4;
    @include rem(margin, $space-half 0);
  }
  .bef-group:first-child .bef-group-heading {
    margin-top: 0;
  }

  .view-grouping {
    h4 {
      @include rem(margin-bottom, $space-half);
    }
  }
  .views-exposed-widget {
    float: none;
    padding: 0;
    .form-submit {
      margin-top: 0;
    }

  }
  .node & {
    fieldset.collapsible .fieldset-wrapper {
      padding-left: 0;
    }
  }

#edit-secondary-wrapper,
#edit-field-media-type-value-wrapper {
  @include rem(margin-top, $space);
}
  .view-newdesk-search & {
    .form-item-sort-by,
    .views-widget-sort-order {
      label {
        display: inline-block;
      }
    }
  }
  .view-newsdesk-content &,
  .view-newsdesk-contacts-page & {
    .views-widget-filter-search_api_views_fulltext,
    .views-widget-filter-combine {
      display: inline-block;
      width: 100%;
      margin-right: 0 !important;
      input {
        @include rem(height, $space-double);
      }
    }
  }
  //.view-newsdesk-content & {
  //    //  .views-widget-filter-search_api_views_fulltext,
  //    //  .views-widget-filter-combine {
  //    //    width: 80%;
  //    //  }
  //    //}
  .form-item-field-unit {
    option {
      @include rem(padding-left, 10px);
    }
    option[value="220"],
  option[value="221"] {
      @include rem(padding-left, 5px);
  }
  }
  @include breakpoint($tab) {
    .view-jobs & {
      .views-exposed-widgets {
        display: flex;
        > div {
          @include rem(margin-right, $pad-half);
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .views-widget-filter-combine {
        @include rem(margin-top, 36px);

      }
      select {
        @include rem(height, 44px);
      }
      .views-submit-button {
        @include rem(margin-top, 48px);

      }
    }
    #edit-secondary-wrapper,
    #edit-field-media-type-value-wrapper {
      margin-top: 0
    }

    .view-newsdesk-content &,
    #view-newsdesk-content-panel-pane-3 &,
    .view-newsdesk-unit &,
    .view-newdesk-search &,
    .view-newsdesk-index-taxonomy & {
      .views-exposed-widgets {
        &:before,
        &:after {
          flex-basis: 0;
          order: 1;
        }
        display: flex;
        flex-wrap: wrap;
      }
      .views-exposed-widget {
        //width: span(6);
        //max-width: span(6);
        //@include rem(padding, $space-half 15px 0 0);
        .no-flexbox & {
          float: left;
        }
      }
      .views-exposed-widget {
        margin-right: gutter();
        &.views-submit-button,
        &.views-reset-button {
          margin-right: 0;
        }
      }
    }

    //.view-newsdesk-content & {
    //  .views-widget-filter-search_api_views_fulltext,
    //  .views-widget-filter-combine {
    //    width: 80%;
    //  }
    //}
    #view-newsdesk-content-panel-pane-3 & {
      .views-widget-filter-title {
        width: 43%;
        max-width: 43%;
      }
      .views-widget-filter-field_media_type_value,
      .views-widget-filter-secondary {
        width: span(5);
        max-width: span(5);
      }
    }
    .view-newdesk-search & {
      .views-widget-filter-search_api_views_fulltext,
      .views-widget-filter-secondary {
        width: span(6);
        max-width: span(6);
      }
      .views-widget-filter-secondary {
        margin-right: 0;
      }
      //.form-item-sort-by {
      //  width: 200px;
      //  max-width: 200px;
      //}
      //.views-widget-sort-order {
      //  width: 135px;
      //  max-width: 135px;
      //}
    }
    .view-newsdesk-index-taxonomy & {
      .views-widget-filter-search_api_views_fulltext {
        width: span(6);
        max-width: span(6);
      }
      .views-widget-filter-type {
        width: span(4);
        max-width: span(4);
      }
    }
    .l-region--right-aside & {
      display: block;
    }
    .views-exposed-widget {

      .search-form-wrapper & {
        padding: 0;
        width: 100%;
        max-width: 100%;
      }
    }
    .ctools-auto-submit-processed .views-submit-button {
      width: 0;
    }
  }
  @include breakpoint($wide) {
    //.views-exposed-widgets {
    //  .views-exposed-widget {
    //    width: span(5);
    //    max-width: span(5);
    //    .search-form-wrapper & {
    //      width: 100%;
    //      max-width: 100%;
    //    }
    //  }
    //  .views-widget-filter-title {
    //    width: span(7);
    //    max-width: span(7);
    //  }
    //}
    #view-newsdesk-content-panel-pane-3 & {
      .views-widget-filter-field_media_type_value {
        width: 30%;
        max-width: 30%;
      }
    }
    .ctools-auto-submit-processed & {
      .views-submit-button {
        width: 0;
      }

    }
  }

}
