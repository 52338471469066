.press-image {
  img {
    width: 100%;
  }
}
.node--media-event.node--full .content {
  >.field {
    @include rem(margin-bottom, $space);
    p {
      @include rem(margin-bottom, $space-half);
    }
    p:last-child {
      margin-bottom: 0;
    }
    .vocabulary-media-contacts {
      @include rem(margin-bottom, $space-half);
      p {
        margin-bottom: 0;
      }
    }
  }
}
.section-newsdesk,
.newsdesk {
  h1 {
    @include rem(font-size, $font-size-h1-sm);
    line-height: $line-height-h1-sm;
    font-weight: 500;
  }
  .l-content-wrapper {
    @include rem(padding-top, $space-and-half);
  }
  &.unit-page {
    .l-content-wrapper {
      padding-top: 0;
    }
  }
  .pane-page-header {
    margin-top: 0;
  }
  .related-slider {
    .pane-title {
      @include set_layout($max-width);
    }
  }
  .node--teaser-featured figcaption {
    display: none;
  }
  .node--teaser-featured.node--photo figcaption {
    display: block;
  }
  .side-nav {
    .menu .menu {
      display: none;
    }
  }
  .l-main .group-header {
    max-width: 100%;
    width: 100%;
    @include rem(margin, 0 0 $space);
  }
  .node--teaser {
    .b-text-wrapper {
      position: relative;
    }
    .field--name-field-tab-title-long {
      font-family: $font-secondary;
    }
    .metadata {
      width: 100%;
      //position: absolute;
      //@include rem(bottom, $space-half);
      //@include rem(left, $pad);
      //@include rem(right, $pad);
      @include rem(margin-top, space-half);
      > div {
        div {
          display: inline-block;
          @include rem(margin-right, $pad*.75);
        }
        //.group-general {
        //  > div {
        //    &:after {
        //      content: ' •  ';
        //    }
        //    &:last-child:after {
        //      content: none;
        //    }
        //  }
        //}

        .file a:after {
          content: none;
        }
      }
      a {
        @include link-colors($text-color, $link-hover, $link-hover, $text-color, $text-color);
        &:after {
          content: ' •  ';
        }
        &:last-child:after {
          content: none;
        }
      }
      //.group-other {
      //  text-align: right;
      //}
      &,
      .preface {
        font-family: $font;
        @include rem(font-size, 13px);
        line-height: $line-height-small;
      }
    }
    //&.node--teaser-featured {
    //  .metadata {
    //    background-color: $gray-lightest;
    //    border: 1px solid darken($gray-lightest, 2%);
    //    padding: em($space/3) em($pad-half);
    //
    //  }
    //}
  }
  .panel-3col-stacked {
    .panel-col-top,
    .center-wrapper {
      @include set_layout($max-width);
    }
  }
  .language-switcher-locale-url {
    @extend %reset-list;
    .active {
      display: none;
    }
  }
  //.press-image {
  //  figcaption {
  //    display: none;
  //  }
  //}

  .l-main .entity-paragraphs-item .group-header {
    //text-align: left;
    @include rem(margin, 0 auto $space-and-half);
  }
  .tagline {
    //font-family: $font-secondary;
    font-style: italic;
    @include rem(font-size, $font-size-tagline);
    line-height: $line-height-tagline;
    @include rem(margin-top, $space);
    //@include rem(margin-bottom, $space-half);
    color: $header-color;
  }
  .pane-page-header > .row-2 {
    @include rem(margin-top, $space);
    //@include rem(margin, $space auto);
    .small {
      &,
      div,
      p {
        display: inline;
      }
      &:before {
        content: ' •  ';
      }
      &:first-child:before {
        content: '';
      }
      .field-label {
        font-weight: normal;
        margin-right: 0;
        &:after {
          content: '';
        }
      }
    }

    p {
      margin: 0;
    }
    .pane-locale-language-content, .pane-node-links {
      @include rem(margin, $space-half 0);
      a {
        @include link-colors($link-color, $link-hover, $link-hover, $link-color, $link-color);
      }
    }
  }
  .field--name-field-links {
    &.reset-list {
      margin-bottom: 0;
    }
    li {
      @include rem(margin, $space $pad $space 0);
      &:last-child {
        margin-right: 0;
      }
      .fa {
        @include rem(font-size, $font-size-h4);
      }
    }
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: auto;
    top: 0;
  }
  .l-region--sidebar-first,
  .l-region--sidebar-second,
  .l-region--left-aside,
  .l-region--right-aside {
    .views-promotions {
      .node--teaser-long .b-text-wrapper, .paragraphs-item-image-text-overlay.view-mode-grid .b-text-wrapper {
        @include rem(padding, $space-half 0);
      }
    }

  }
  .press-image {
    margin-top: 0;
    border: 1px solid transparent;
    //  @include rem(margin-top, $space);
    @include rem(padding, $space-half);
  }
  .group-header .press-image {
    border: 1px solid darken($gray-lightest, 2%);
  }
  &.node-type-press-page {
    .press-image {
      padding: 0;
      border: none;
    }
  }
  .node--full {
    //.field--name-body {

    //}
    .field--name-field-newsdesk-tags {
      a:after {
        content: ' •  ';
      }
      a:last-child:after {
        content: none;
      }
    }
    .field--name-body {
      //  margin-bottom: em($space-double);
      font-family: $font-secondary;
        h2 {
          @extend h3;
        }
        h3 {
          @extend h4;
        }
        h4 {
          @extend h5;
        }
      //h2 {
      //  @include rem(font-size, $font-size-h3);
      //  line-height: $line-height-h3;
      //  @include rem(margin-bottom, $space);
      //}
      //h3 {
      //  @include rem(font-size, $font-size-h4);
      //  line-height: $line-height-h4;
      //  @include rem(margin-bottom, $space);
      //}
      //h4 {
      //  @include rem(font-size, $font-size-h5);
      //  line-height: $line-height-h5;
      //  @include rem(margin-bottom, $space);
      //}
    }

    .group-header .field--type-image {
      img {
        max-height: 500px;
        width: auto !important;
        display: block;
        margin: 0 auto;
      }
    }
    .press-image {
      margin: 0;
      text-align: center;
      img {
        margin: 0;
      }
    }

    .col-sm-3,
    .group-right.col-sm-6 {
      @include rem(font-size, $font-size-small);
      p,
      > div {
        @include rem(margin-bottom, $space-half);
      }
      .vocabulary-media-contacts {
        @include rem(margin-bottom, $space-half);
        p {
          margin-bottom: 0;
        }
      }
    }

    //.newsdesk & {
    //    figcaption {
    //      display: block;
    //    }
    //  }

  }
  // end node--full

  @include breakpoint($tab) {
    &.node-type-press-page {
      .press-image {
        border: 1px solid darken($gray-lightest, 2%);
        //  @include rem(margin-top, $space);
        @include rem(padding, $space-half);
      }
    }
    .node--full.ds-2col-stacked-fluid {
      .group-left, .group-right {
        width: 100%;
        margin: 0;
        float: none;
      }
      .group-left.col-sm-6 {
        @include span(9 last);
        order: 1;
      }
      .group-right.col-sm-6 {
        @include span(3);
      }
    }
    .l-content-wrapper {
      @include rem(padding-top, $space-double);
    }
    .pane-page-header {
      margin-top: 0;
    }
    .group-media {
      .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .taxonomy-term {
          width: span(6);
          max-width: span(6);
        }
      }
      .items .taxonomy-term,
      .vocabulary-newsdesk-unit {
        @include rem(margin-bottom, $space-half);
      }
      @include rem(margin, $space-half $pad $space-half 0);

      &:last-child {
        margin-right: 0;
      }
      .fa {
        @include rem(font-size, $font-size-base);
      }
    }
    .vocabulary-newsdesk-unit {
      .field--type-text-long {
        @include rem(margin, $space-half 0);
      }
      .field--label-inline {
        @include breakpoint($tab) {
          margin-left: 0;
          .field-label {
            float: none;
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }

    .node--teaser-featured,
    .node--teaser-list {
      .metadata {
        @include clearfix;
        .group-general {
          @include span(6);
        }
        .group-other {
          @include span(6 last);
        }
      }
    }
  }

}

// end newsdesk

.node--photo {
  .field--name-post-date {
    @include rem(font-size, $font-size-small);
    @include rem(margin-bottom, $space);
  }

  &.color-box-wrapper {
    .inner {
      position: relative;
      //@include rem(padding-bottom, $space-double);
    }
    .node__title {
      display: block;
      @include rem(padding, $space-half $pad*.75);
    }
    .other-info {
      @include rem(padding, 0 $pad*.75 $space);
    }
    //.metadata {
    //  padding: 0 em($pad, $font-size-small) em($space-half, $font-size-small);
    //}
    .metadata {
      @include rem(padding, 0 $pad*.75 $space);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: span(6);
        max-width: span(6);
      }
      .file-download-link {
        text-align: right;
      }
    }
  }
  &.view-mode-full {
    position: relative;
    .group-right {
      .download {
        @include rem(margin-top, $space-and-half);
      }
      .metadata {
        @include rem(font-size, $font-size-small);
        line-height: $line-height-small;
        // @include rem(margin-top, $space-and-half);
        .taxonomy-term--newsdesk-unit a:before,
        a:before {
          content: ' •  ';
        }

        //a:first-child:before {
        //  content: none;
        //}
        .field--type-taxonomy-term-reference,
        .taxonomy-term--newsdesk-unit {
          display: inline;
        }
        .taxonomy-term--newsdesk-unit:first-child {
          a:before {
            content: none;
          }
        }
        > a:first-child {
          &:before {
            content: none;
          }
        }
        .taxonomy-term--newsdesk-unit ~ a:first-child {
          &:before {
            content: ' •  ';
          }
        }
      }

      @include breakpoint($tab) {
        @include rem(padding-bottom, $space-double);
        .metadata {
          position: absolute;
          //@include rem(bottom, $space);
          bottom: 0;
        }
      }
    }
  }
}
