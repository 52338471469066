/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover,
.flex-container a:focus,
.flex-slider a:focus {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider-container,
.flexslider {
  a:hover {
    border-bottom: none;
  }
  ul {
    @extend %reset-list;
  }
  @include clearfix;
  position: relative;
}
.flexslider-container {
  //  margin-bottom: em($space-double);
  .entity-paragraphs-item & {
    @include rem(margin, $space-and-half 0 $space-double);
    border: 1px solid $gray-lightest;
    border-width: 1px 0;
    @include rem(padding, $space-and-half 0);
  }
}
.flexslider {
  padding: 0;
  margin: 0 auto;
  background: none;
  border: none;
  position: relative;
  zoom: 1;
  .slides {
    zoom: 1;
    margin: 0;
    padding: 0;
    > li {
      display: none;
      -webkit-backface-visibility: hidden;
    }
    img {
      height: auto;
      width: 100%;
      display: block;
    }
    @include clearfix;
    .no-js & {
      > li {
        &:first-child {
          display: block;
        }
      }
    }
  }
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}

.flex-viewport {
  max-height: 2000px;
  @include transition(all 1s ease);

  .loading & {
    max-height: 300px;
  }
}

//
// Direction Nav
//

.flex-direction-nav {
  *height: 0;
  // Global styles
  a {
    text-decoration: none;
    display: block;
    position: absolute;
    z-index: 10;
    margin: 0;
    top: 40%;
    width: 30px;
    height: 30px;
    opacity: 0.5;
    overflow: hidden;
    background: {
      image: image-url("prev.svg");
      repeat: no-repeat;
      size: contain;
      position: 0 50%;
    }
    cursor: pointer;
    @include transition(all 0.3s ease-in-out);
  }

  // Prev
  .flex-prev {
    left: 1%;
  }

  // Next
  .flex-next {
    right: 1%;
    text-align: right;
    background: {
      image: image-url("next.svg");
      position: 100% 50%;
    }
  }
  .flexslider-container.vertical & {
    a,
    .flex-next {
      width: 8%;
      background-position: 50% 82%;
      z-index: 1000;
    }
    .flex-prev {
      left: 5px;
    }
    .flex-next {
      right: 5px;
    }
  }
  @include breakpoint($tab) {
    a {
      @include rem(width, 50px);
      @include rem(height, 50px);
    }
    .flexslider-container.vertical & {
      a,
      .flex-next {
        @include rem(width, 50px);
      }
      .flex-prev {
        left: 10px;
      }
      .flex-next {
        right: 10px;
      }
    }
  }
  @include breakpoint($wide) {
    //.flexslider-container.horizontal & {
    a {
      @include rem(width, 60px);
      @include rem(height, 60px);
      top: 45%;
    }

    // }
  }
  // Hover states
  .flex-prev {
    &:hover {
      opacity: 1;
    }
  }
  .flex-next {
    &:hover {
      opacity: 1;
    }
  }

  //
  // Disabled
  //
  .flex-disabled {
    opacity: 0 !important;
    filter: alpha(opacity=0);
    cursor: default;
  }
}

.flex-pauseplay,
.flex-direction-nav {
  a span {
    @extend %hide-text;
  }
}

//
// Pause/Play
//
.flex-pauseplay {
  position: absolute;
  bottom: 8px;
  right: 4%;
  a {
    position: relative;
    display: block;
    width: 24px;
    height: 20px;
    opacity: 0.6;
    //  opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #fff;

    @include font-icon($fa-var-pause);

    &:hover {
      opacity: 0.8;
    }

    &.flex-play:before {
      content: unquote('"#{$fa-var-play}"');
    }

    //@include breakpoint($desk) {
    //  &:before {
    //    font-size: em(16px);
    //  }
    //}
  }
  @include breakpoint($tab) {
    bottom: 40px;
    a {
      height: 24px;
    }
    //right: 6%;
    a:before {
      @include rem(font-size, 17px);

      //color:#000;
      //.front & {
      //  color: #fff;
      //}
    }
  }
}

//
// Control Nav
//

.flex-control-nav {
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
  li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
}

//
// Control paging
//

.flex-control-paging li {
  a {
    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    text-indent: -9999px;
    box-shadow: 0 0 3px inset rgba(0, 0, 0, 0.3);
    border-radius: 20px;

    &:hover {
      background: #333;
      background: rgba(0, 0, 0, 0.5);
    }

    &.flex-active {
      background: #000;
      background: rgba(0, 0, 0, 0.8);
      cursor: default;
    }
  }
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;

  li {
    width: 25%;
    float: left;
    margin: 0;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    opacity: 0.7;
    cursor: pointer;
    @include transition(all 1s ease);

    &:hover {
      opacity: 1;
    }
  }

  .flex-active {
    opacity: 1;
    cursor: default;
  }
}
