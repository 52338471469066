.l-region--hero {
  .col--right {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.pane-page-header {
  @include rem(margin, $space auto);

  $this: &;

  #{$this}__eyebrow {
    @include link-colors(
      $link-color,
      $link-hover,
      $link-hover,
      $link-color,
      $link-color
    );
  }

  //&.has-share-btn {
  //  margin-right: ;
  //}
  //background: $gray-darker;
  h2.alpha {
    font-family: $font;
    margin-bottom: 0;
    //font-size: em($font-size-h1, $font-size-h2);
    color: $text-color;
  }
  &.page-title {
    text-align: center;
  }
  &.bg {
    @include font-smoothing;
    @include rem(padding, $space-half 0);
  }
  img {
    display: block;
    width: 100%;
  }

  a {
    @include link-colors(
      $text-color,
      $link-hover,
      $link-hover,
      $text-color,
      $text-color
    );
  }
  &.bg a {
    @include link-colors(white, white, white, white, white);
  }
  //.col--left {
  //  padding: em($pad-half);
  //  //
  //  //.page-title {
  //  //  margin-bottom: em($space-half, $font-size-h1);
  //  //}
  //}
  //#mini-panel-page_header {
  //  position: relative;
  //}
}

@include breakpoint($tab) {
  .pane-page-header {
    @include rem(margin, $space-and-half auto);

    //  @include rem(margin, $space-double auto);
    &.l-container {
      position: relative;
    }
    .wrapper--preface-buttons {
      position: absolute;
      right: 0;
      @include rem(top, -$space);
      .wrapper--ogmt & {
        top: auto;
        @include rem(bottom, -$space);
      }
    }
    .wrapper--ogmt & {
      .link-wrapper {
        display: flex;
      }
      .wrapper--addthis {
        @include rem(padding, 0 0 8px);
      }
    }
    .row-1.has-other-content {
      @include rem(margin-right, 170px);
      .newsdesk & {
        margin-right: 0;
      }
    }
    &.page-title {
      .wrapper--preface-buttons {
        position: relative;
        top: auto;
        right: auto;
      }
    }
  }
} //end breakpoint($tab)
// end .pane-page-header

@include breakpoint($desk) {
  .l-region--hero {
    .col--right {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.wrapper--si-unit {
  a {
    @include link-colors(
      $text-color,
      $link-hover,
      $link-hover,
      $text-color,
      $text-color
    );
  }
  .pane-node-field-tagline {
    a {
      display: block;
    }
    //font-size: em($font-size-h5);
    &.has-unit-code {
      font-family: $font;
      font-style: normal;
      text-align: left;
      //font-size: em(16px);
      //@include breakpoint('all' 1010px) {
      //  font-size: em(16px, 18px);
      //}
      //span {
      //  font: $font-secondary;
      //}
      @include rem(padding, 8px 0);
      //a {
      //  //margin-top: em($space-half, $font-size-h4);
      //  padding: em(8px) 0;
      //  padding-left: 54px;
      //  background: {
      //    image: url('/sites/default/files/icons/si-icons-white.png');
      //    repeat: no-repeat;
      //    position:  0 -130px;
      //  }
      //  min-height: em(36px);
      //}
      //&.npg a{
      //  background-position: 0 -835px;
      //  padding-left: 41px;
      //}
      //&.nasm,
      //&.nasmac,
      //&.nasm_bl,
      //&.nasm_yt{
      //  a{
      //    //background: {
      //    //  image: url('/sites/default/files/icons/si-icons-white.png');
      //    //  repeat: no-repeat;
      //    //  position:  0 -649px;
      //    //}
      //    background-position: 0 -649px;
      //    padding-left: 70px;
      //  }
      //}
      //
      //&.naa,
      //&.nmnh,
      //&.nmnhanth,
      //&.nmnhbirds,
      //&.nmnhbotany,
      //&.nmnhento,
      //&.nmnhfishes,
      //&.nmnhherps,
      //&.nmnhinv,
      //&.nmnhmammals,
      //&.nmnhminsci,
      //&.nmnhpaleo,
      //&.nmnh_bl,
      //&.nmnh_pc,
      //&.nmnh_yt,
      //&.ceph,
      //&.fbr,
      //&.hsfa,
      //&.hsfa_yt,
      //&.mmam {
      //  a {
      //    padding-left: 70px;
      //    background-position: 0 -937px;
      //
      //  }
      //}
      //&.acm,
      //&.acma,
      //&.acma_yt,
      //&.acm_yt {
      //  a {
      //    padding-left: 70px;
      //    background-position: 0 8px;
      //  }
      //}
      //
      //&.aeci,
      //&.saam,
      //&.saampaik,
      //&.saamphoto,
      //&.saam_bl,
      //&.saam_pc,
      //&.saam_yt,
      //&.ari {
      //  a {
      //    padding-left: 38px;
      //    background-position: 0 -745px;
      //    min-height: em(50px);
      //    span {
      //      padding-top: em(8px);
      //    }
      //  }
      //}
      //&.nmaahc,
      //&.nmaahc_yt {
      //  a {
      //    padding-left: 55px;
      //    background-position: 0 -1023px;
      //  }
      //}
      //
      //&.nmah,
      //&.nmah-af,
      //&.nmahlc_yt,
      //&.nmah_bl,
      //&.nmah_yt,
      //&.acah {
      //  a {
      //    padding-left: 65px;
      //    background-position: 0 -263px;
      //  }
      //}
      //
      //&.fsg,
      //&.fsg_bl,
      //&.fsg_yt {
      //  a {
      //    padding-left: 63px;
      //    background-position: 0 -78px;
      //  }
      //}
      //&.chndm,
      //&.chndm_bl,
      //&.chndm_yt {
      //  a {
      //    padding-left: 69px;
      //    background-position: 0 -1213px;
      //  }
      //}
      //&.hmsg,
      //&.hmsg_yt {
      //  a {
      //    padding-left: 70px;
      //    background-position: 0 -455px;
      //  }
      //}
      //&.nmafa,
      //&.nmafa_yt,
      //&.eepa {
      //  a {
      //    padding-left: 70px;
      //    background-position: 0 -178px;
      //  }
      //}
      //&.nmai,
      //&.nmaia,
      //&.nmai_bl,
      //&.nmai_pc,
      //&.nmai_yt {
      //  a {
      //    padding-left: 57px;
      //    background-position: 0 -549px;
      //  }
      //}
      //&.npm,
      //&.npm_bl,
      //&.npm_yt {
      //  a {
      //    padding-left: 55px;
      //    background-position: 0 -1124px;
      //  }
      //}
      //&.nzp,
      //&.nzp_yt,
      //&.simbc_yt {
      //  a {
      //    padding-left: 68px;
      //    background-position: 0 -366px;
      //  }
      //}
    }
  }
}

.l-region--hero.bg {
  @include rem(margin-bottom, $space-and-half);
  .page-title {
    @include font-smoothing;
  }
}

.l-main .bg-none.page-title .group-header {
  .header-theme {
    background: $blue-darkest;
  }
  .title {
    color: $burgundy;
    font-family: $font-secondary;
    font-weight: 400;
    padding: 0.5em 1em;
    @include rem(padding, 8px 16px);
  }
}

.page-title span {
  @include rem(font-size, $font-size-base);
}
