.b-media-wrapper {
  line-height: 1;
  font-style: italic;
  @include rem(font-size, $font-size-small);
  .file {
    font-style: normal;
    @include rem(font-size, $font-size-base);
    line-height: $line-height-base;
  }
  .media {
    display: block;
  }
  .field--type-text {
    @include rem(margin-top, $space-half);
  }
  figcaption {
    .field--type-text {
      margin-top: 0;
    }
  }
  .title {
    font-style: normal;
    @include rem(padding, $space-half $pad * 0.75);
    display: block;
    margin: 0;
  }
}

.b-text-wrapper {
  h3,
  h2,
  h4,
  .h2,
  .h3,
  .h4,
  .beta,
  .gamma,
  .delta,
  .epsilon {
    @include rem(margin-bottom, $space-quarter);
  }
}
.node--teaser,
.view-mode-teaser_long,
.view-mode-teaser {
  position: relative;

  // TODO: Replace missing Headings mixin / function
  // #{headings()} {
  //   font-family: $font;
  //   letter-spacing: 0;
  //   &.secondary {
  //     font-family: $font-secondary;
  //   }
  // }

  p {
    @include rem(margin, 0 0 $space-half);
  }
  img {
    display: block;
    width: 100%;
  }
  h3,
  h2,
  h4,
  .h2,
  .h3,
  .h4,
  .beta,
  .gamma,
  .delta,
  .epsilon {
    // @include rem(margin, $space/6 0);
    @include rem(font-size, $font-size-h4);
    line-height: $line-height-h5;
    color: $gray-darker;
  }
  a:hover {
    text-decoration: none;
  }
  .preface {
    font-family: $font-secondary;
    // color: lighten($text-color, 5%);
  }
}
.node--teaser:not(.node--book--teaser),
.node--teaser-long,
.paragraphs-item-image-text-overlay.view-mode-grid {
  width: 100%;
  .inner {
    background-color: $teaser-bg;
    border: 1px solid $teaser-border;
    width: 100%;
    .bg-gray-light & {
      background: none;
      border: none;
    }
    display: block;
    position: relative;
    z-index: 1;
    border-radius: inherit;
    @include link-colors(
      $text-color,
      $text-color,
      $text-color,
      $text-color,
      $text-color
    );
    &:after,
    &:before {
      border-radius: inherit;
      z-index: -1;
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
      -webkit-transition: -webkit-box-shadow 0.7s 10ms
        cubic-bezier(0.19, 1, 0.22, 1);
      transition: -webkit-box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1),
        -webkit-box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:after {
      @include opacity(0.0625);
      -webkit-box-shadow: 0 2px 0 0 #000;
      box-shadow: 0 2px 0 0 #000;
      .bg-gray-light & {
        opacity: 0;
      }
    }
    .l-region--sidebar-first &,
    .l-region--sidebar-second &,
    .l-region--left-aside &,
    .l-region--right-aside & {
      border: none;
      background: transparent;
      &:after {
        opacity: 0;
      }
    }
  }
  a.inner:hover,
  a.inner:focus {
    background-color: $teaser-bg-hover;
    .bg-gray-light & {
      background: none;
    }
  }
  .b-text-wrapper {
    @include rem(padding, $space-half $pad * 0.75);
    &,
    span {
      display: block;
      &.fa {
        display: inline-block;
        @include rem(margin-left, 5px);
        @include rem(margin-right, 5px);
      }
    }
  }
  &.has-title {
    .b-text-wrapper {
      @include rem(padding, 0 $pad * 0.75 $space-half);
    }
  }
  .slides & {
    .inner {
      background: none;
      border: none;
      &:after {
        box-shadow: none;
        opacity: 0;
      }
    }
    a.inner:hover {
      background: none;
    }
  }
  //.description {
  //  @include word-break(break-word);
  //  @include hyphenation;
  //}
  img {
    width: 100% !important;
  }
  &.boder-only {
    .inner {
      background: transparent;
      border: 1px solid darken($gray-lightest, 2%);
    }
  }
}
.node--teaser-long.center-media {
  .b-media-wrapper {
    @include rem(padding, $space $pad * 0.75);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}
.node--teaser-long.border {
  background: none;
}
.node--teaser-featured,
.node--teaser-list {
  img {
    width: 100%;
  }
  .b-text-wrapper {
    //.layout-constrain .content &,
    //.l-container & {
    //  @include rem(padding, $space-half 0 $space);
    //}
    //&,
    //.layout-constrain.view.flexslider-container .content & {
    //  @include rem(padding, $space-half $pad*.75 $space);
    //}
    //.spacing &,
    //.spacing-bottom &,
    //.spacing-extra & {
    //  @include rem(padding, $space-half 0 0);
    //}
    //&.node--teaser-long .b-text-wrapper {
    //  @include rem(padding, $space-half $pad*.75);
    //}
  }
}

@include breakpoint($tab) {
  .node--book--teaser {
    .inner {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }
  }
  .node--teaser-long,
  .paragraphs-item-image-text-overlay.view-mode-grid {
    display: flex;
  }
  .node--teaser-list,
  .node--teaser-featured,
  .node--teaser-long {
    .has-align-bottom,
    .b-text-wrapper.has-align-bottom {
      position: relative;
      @include rem(padding-bottom, $space * 2.5);
    }
    .align-bottom {
      position: absolute;
      //@include rem(bottom, $space-half);
      bottom: 0;
    }
  }
  .node--teaser-featured:not(.node--book--teaser),
  .node--teaser-list:not(.node--book--teaser) {
    &,
    .layout-constrain .content &,
    .l-container &,
    .spacing &,
    .spacing-bottom &,
    .spacing-extra &,
    .layout-constrain.view.flexslider-container .content & {
      .b-text-wrapper {
        padding: 0;
      }
    }
    .inner {
      background: none;
      border: none;
      &:after {
        box-shadow: none;
        opacity: 0;
      }
    }
    a.inner:hover {
      background: none;
    }

    &.has-media {
      .inner {
        display: flex;
        .no-flexbox & {
          @include clearfix;
        }
      }
      .b-media-wrapper {
        @include span(5);
        max-width: span(5);
        img {
          width: 100%;
          .newsdesk .swiper-container &,
          .newsdesk .flexslider & {
            max-height: 400px;
            width: auto;
          }
        }
        flex: 1 0 auto;
      }
      .b-text-wrapper {
        @include span(7 last);
        max-width: span(7);
        flex: 1 0 auto;
      }
      &.image--circle {
        .b-media-wrapper,
        .b-text-wrapper {
          width: 100%;
          max-width: 100%;
          float: none;
          margin-right: 0;
          img {
            width: auto;
          }
        }
        .b-media-wrapper {
          margin-bottom: em($space-half);
        }
      }
    }
    &.node--teaser-long {
      .b-media-wrapper {
        margin-right: 0;
      }
      .b-text-wrapper {
        width: 60%;
        max-width: 60%;
        @include rem(padding, $space-half $pad * 0.75);
      }
    }
    &.node--slideshow {
      .b-media-wrapper {
        width: 100%;
        max-width: 100%;
        float: none;
      }
    }
  }
  .node--teaser-list,
  .node--teaser-featured,
  .node--teaser-long {
    .has-align-bottom,
    .b-text-wrapper.has-align-bottom {
      position: relative;
      @include rem(padding-bottom, $space * 2.5);
    }
  }
  .view--list .has-media .node--teaser-long {
    @include rem(margin-bottom, $space);
    &,
    .inner {
      display: flex;
      .no-flexbox & {
        @include clearfix;
      }
    }
    .b-media-wrapper {
      @include span(5);
      max-width: span(5);
      img {
        width: 100%;
        .newsdesk .swiper-container &,
        .newsdesk .flexslider & {
          max-height: 400px;
          width: auto;
        }
      }
      flex: 1 0 auto;
    }
    .b-text-wrapper {
      @include span(7 last);
      max-width: span(7);
      flex: 1 0 auto;
    }
    .title {
      @include rem(padding-right, $space-and-half);
    }
  }
}

@include breakpoint("all" 1010px) {
  // .b-text-wrapper
}
