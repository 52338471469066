.webform-client-form {

  legend {
    background-color: #fff;
    position: relative;
    top: 0;
    @include rem(padding, 0 $pad-half);

    .fieldset-legend {
      font-weight: bold;
    }
  }

  .fieldset-wrapper {
    padding: 20px;
    border: 1px solid #000;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    .form-item {
      flex: 1 1 40%;
      margin-right: 20px;

      label {
        //font-size: .75em;
        @include rem(font-size, $font-size-small);
      }

      &.webform-component--theaters--visit-siedu,
      &.webform-component--dining--please-visit,
      &.webform-component-date,
      &.webform-component-time,
      &.webform-component-number {
        flex-basis: 100%;
      }

      &.webform-component-number {
        flex-basis: 100%;
        input {
          max-width: 15%;
          max-height: 30px;
        }
      }

      &.webform-component--theaters--lm-title,
      &.webform-component--theaters--airbus-title,
      &.webform-component--theaters--einstein-title {
        flex-basis: 100%;
        input {
          max-width: 40%;
        }
      }
    }
  }

  .fieldset-description {
    flex-basis: 100%;
    order: 20;
    font-size: 75%;
  }

  select {
    font-size: 80%;
  }

}


.webform-confirmation {
  width: 96%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 10%;
  text-align: center;

  .links {
    text-align: center;
    position: relative;
    margin-bottom: 20%;
  }
}

.webform-datepicker {
  position: relative;
  .webform-calendar {
    position: absolute;
    width: 34px;
    border: none;
    right: -20px
  }
}