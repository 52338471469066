@use "sass:math";

.flexslider {
  .flex-caption {
    //margin-top: em($space);
    //padding-right: 1%;
    //padding-left: 1%;
    font-style: normal;
    @include rem(padding, $space);
    border: 1px solid lighten($gray-dark, 5%);
    background: $gray-dark;
    * {
      color: white;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  h3, h2 {
    // line-height: $line-height-base*1.25/$font-size-h3;
    margin: 0;
    color: $gray-darkest;
  }
  p {
    @include rem(margin-bottom, $space-half);
    &:last-child {
      margin: 0;
    }
  }
  .slides {
    .entity {
      position: relative;
    }

  }
  .caption {
    @include rem(font-size, $font-size-base);
    line-height: $line-height-base;
  }

  //end slides

  .related-slider & {
    margin-bottom: 0;
    .flex-direction-nav {
      .flex-prev, .flex-next {
        position: absolute;
        top: 50%;
        width: 27px;
        height: 44px;
        margin-top: -22px;
        z-index: 10;
        cursor: pointer;
        -moz-background-size: 27px 44px;
        -webkit-background-size: 27px 44px;
        background-size: 27px 44px;
        background-position: center;
        background-repeat: no-repeat;
        &.swiper-button-disabled {
          opacity: 0;
          //  opacity: 0.35;
          cursor: auto;
          pointer-events: none;
        }
      }
      .flex-prev {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
        left: 0;
        right: auto;
      }

      .flex-next {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
        right: 0;
        left: auto;
      }
    }
    .flex-control-nav {
      bottom: auto;
      @include rem(top, -$space);
    }
    .flex-control-paging li a {
      width: 8px;
      height: 8px;
    }

  }
}
.bg-gray-lighter .flex-pauseplay a {
  color: $gray-darkest;
}

.flexslider-container.border {
  @include rem(padding-bottom, $space-double);
  border-bottom: 1px solid darken($gray-lighter, 5%);
}

.pane-slideshows-layout-constrain {
  @include rem(margin-top, $space-double);

}
.pane-page-header + .pane-slideshows-layout-constrain {
  margin-top: 0;
}
.layout-constrain.view.flexslider-container .content {
  width: 100%;
}
.pane-node-field-slide, .flexslider-container.views {
  @include rem(margin-bottom, $space-and-half);
}

.front .flexslider-container.views {
  margin-bottom: 0;
}
.view-slideshows {
  @include rem(margin-bottom, $space-double);

  &.layout-constrain {
    @include rem(margin-bottom, $space*3);
  }

}

.pane-node-field-slide,
.view.l-hero {
  .flexslider {
    .slides li {
      position: relative;
    }
    .flex-caption,
    .caption,
    .b-text-wrapper {
      @include rem(padding, math.div($space,3));
      margin: 0;
      * {
        color: white;
      }
      position: absolute;
      bottom: 6%;
      left: 12%;
      max-width: 76%;
      background: url('../images/rgbapng/00000066-5.png?1558022920');
      border: none;
    }

    text-shadow: rgba(black, 0.35) 1px 1px 2px;
    .slide-title {
      font-weight: 700;
      @include rem(font-size, $font-size-h4);
      line-height: $line-height-h4;
      color: white;
      margin: 0;
    }

    p {
      margin-bottom: 0;
    }

    p {
      display: none;

    }
    .read-more {
      display: none;
    }
    .node--slideshow--teaser {
      .preface,
      .slide-title {
        display: inline-block;
      }
    }

    .flex-direction-nav a {
      width: 10%;
    }
  }

  //end flexslider
}


.pane-node-field-slide.default .flexslider, .paragraphs-item-slideshow .flexslider {
  background: $gray-lighter;

  .flex-caption {
    //   border: 1px solid lighten($gray-light, 5%);
    background: $gray-lighter;
    padding: .75em 1em;
    border: none;
    * {
      color: $text-color;
    }
  }
  .flex-pauseplay {
    bottom: .5em;
    right: .75em;
    text-shadow: none;
    a:before {
      color: $text-color;
    }
  }
}


//.constrained .flexslider-container.horizontal {
//  padding-bottom: em($space-and-half);
//  border-bottom: 1px solid $gray-lighter;
//  .flex-pauseplay a {
//    right: 0;
//    bottom: -2em;
//    &:before {
//      color: $text-color;
//    }
//  }
//}
@include breakpoint($tab) {
  .view,
  .pane-node-field-slide {
    .flexslider {
      .caption,
      .flex-caption,
      .b-text-wrapper {
        padding: 1em;
        width: auto;
        bottom: 15%;
      }
      //p {
      //  font-size: em($font-size-h5);
      //  line-height: $line-height-h5;
      //}
    }

  }
  //.pane-node-field-slide {
  //  .slide-title {
  //    font-size: em($font-size-h3);
  //    line-height: $line-height-h3;
  //    margin: em(6px, $font-size-h3) 0;
  //  }
  //}


}

@include breakpoint($desk) {

  .flexslider .preface {
    @include rem(font-size, $font-size-base);
    line-height: $baseLineHeight;
  }
  .pane-node-field-slide .flexslider {
    p,
    .read-more {
      display: block;
    }
  }
  .layout-constrain.view.flexslider-container > .content {
    width: 96%;
    max-width: $max-width;

  }
  .pane-node-field-slide.default, .layout-constrain.view.flexslider-container.l-hero {
    .flexslider {
      .flex-caption,
      .caption,
      .b-text-wrapper {
        position: static;
        bottom: auto;
        left: auto;
        text-shadow: none;

      }
      p {
        display: block;
        @include rem(margin-bottom, $space-half);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .slide-title {
      @include rem(margin, math.div($space,3) 0);
      @include rem(font-size, $font-size-h3);
      line-height: $line-height-h3;
    }
  }


  .layout-constrain.view.flexslider-container {
    .flexslider {
      .flex-caption,
      .caption,
      .b-text-wrapper {
        * {
          color: $text-color;
        }
        .slide-title ,
        .slide-title * {
          color: $header-color;
        }
        background: none;
      }
      .flex-pauseplay {
        bottom: em(-$line-height-base);
        text-shadow: none;
        a {
          color: #000;
        }
      }
      .flex-control-nav {
        bottom: em(-$line-height-base);
      }
      .read-more {
        color: $link-color;
        margin-top: em($space-half);
        display: inline-block;
      }

    }
    .node--teaser-featured,
    .node--teaser-list {

        .b-media-wrapper {
          @include span(5);
          max-width: span(5);
        }
        .b-text-wrapper {
          width: span(7);
          max-width: span(7);
        }

    }
  }

  .full-width.view, .pane-node-field-slide.full-width {
    .flexslider {
      .preface {
        line-height: $baseLineHeight;
      }
      .slide-title {
        @include rem(font-size,$font-size-h2);
        @include rem(font-size, $font-size-h2);
        line-height: $line-height-h2;
        color: white;
        margin: 0 0 em(6px, $font-size-h2);
      }
      p {
        display: inline;
        @include rem(font-size,$font-size-h5);
        line-height: $line-height-h5;
      }
    }

  }
}

//end desktop

@include breakpoint('screen' 1340px) {
  .related-slider {
    .flexslider .flex-direction-nav {
      .flex-prev {
        left: -32px;
      }
    .flex-next {
      right: -32px
    }
    }
  }

  //  .full-width.view, .pane-node-field-slide.full-width {
//    .flexslider {
//        //  width: 60%;
//        //bottom: 20%;
//        .preface {
//          @include rem(font-size,$font-size-h5);
//          line-height: $line-height-h5;
//        }
//        .slide-title {
//          @include rem(font-size,$font-size-mega);
//          line-height: $line-height-mega;
//          color: white;
//          margin: em(6px, $font-size-mega) 0;
//        }
//        p {
//          @include rem(font-size,$font-size-h3);
//          line-height: $line-height-h3;
//        }
//      }
//  }
}

//@include breakpoint('screen' 93em) {
//  //.flexslider-container.horizontal {
//  //  &.full-width,
//  //  .full-width & {
//  //
//  //  }
//  //}
//  .front .flexslider {
//    .caption {
//      .preface {
//        @include rem(font-size,$font-size-h4);
//        line-height: $line-height-h4;
//      }
//      .slide-title {
//        @include rem(font-size,$font-size-giga);
//        line-height:  $line-height-giga;
//        color: white;
//        margin: em(6px, $font-size-giga) 0;
//      }
//      p {
//        @include rem(font-size,$font-size-h2);
//        line-height: $line-height-h2;
//      }
//    }
//  }
//}
