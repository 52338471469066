.node--book {
  position: relative;
  .node__title {
    span,
    .small {
      display: block;
      text-transform: uppercase;
      font-weight: 600;
    }
    font-family: $font;
  }
  h1 {
    span,
    .small {
      @include rem(font-size, $font-size-h4);
      line-height: ($line-height-base*2)/$font-size-h4;
    }
  }

  h2 {
    span,
    .small {
      @include rem(font-size, $font-size-h5);
      line-height: ($line-height-base*1.5)/$font-size-h5;
    }

  }
  @include breakpoint($tab) {
    &.node--teaser-featured.has-media {
      .inner {
        align-items: center;
      }
    }
  }
}
.bg-video {

  .file-video {
    display: none;
  }
  .no-video & {
    video {
      display: none;
    }

  }
  @include breakpoint('screen' $bp-med) {
    background-color: #000;
    html.video & {
      .file-video {
        display: block;
      }
    }
    position: relative;
    .file-video {
      position: absolute;
      top: -30%;
      left: -40%;
      width: 240%;
      height: 240%;
      .flex-pauseplay {
        right: 35%;
        top: 52%;
      }
    }

    video {
      -webkit-animation: fadein .2s;
      -moz-animation: fadein .2s;
      -ms-animation: fadein .2s;
      -o-animation: fadein .2s;
      animation: fadein .2s;
      //-webkit-animation-delay: .5s;
      //-moz-animation-delay: .5s;
      //animation-delay: .5s;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      min-height: 60%;
      min-width: 60%;
      width: auto;
      height: auto;
    }
  }

  @include breakpoint('screen' 1280px) {
    .file-video {
      height: 200%;
      width: 200%;
      left: -25%;
      top: -25%;
      video {
        width: auto;
        height: auto;
      }
      .flex-pauseplay {
        right: 39%;
        top: 58%;
      }
    }
  }
  @include breakpoint('screen' 1920px) {
    .file-video {
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      video {
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
      }
      .flex-pauseplay {
        right: 4%;
        top: 90%;
      }
    }

  }
}
//end bg-video

/* Keyframes for the fade-in */
@keyframes fadeInVideo {
  from {
    opacity: 0;
    //  display: none;
  }
  to {
    opacity: 1;
    //  display: block;
  }
}
@keyframes fadeInVideo {
  from {
    opacity: 0;
    // display: none;
  }
  to {
    opacity: 1;
    // display: block;
  }
}
@keyframes fadeInVideo {
  from {
    opacity: 0;
    // display: none;
  }
  to {
    opacity: 1;
    //display: block;
  }
}
#fullpage {
  > .section {
    .read-more {
      padding-right: 0;
    }
    @include breakpoint('screen' $bp-med) {
      position: relative;
      height: 500px;
      height: calc(100vh - 78px);
      overflow: hidden;
      .inner {
        @include rem(padding-left, $space-double);
      }

    }

    @include breakpoint('screen' 1380px) {
      .inner {
        padding-left: 0;
      }
    }
  }
}
.book--other,
.book--landing {
  .bg-image {
    position: relative;
    padding-top: 340px;
    padding-top: 45vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 0%;
    &.page-hero {
      height: 500px;
      height: calc(100vh - 78px);
      background-position: 50% 50%;
      background-size: cover;
      padding-top: 0;
      overflow: hidden;
    }
    .wrapper--title {
      @include rem(padding, $space 0 $space-and-half);
      background-color: #fff;
      text-align: center;
      span {
        display: block;
        &.read-more {
          display: none;
        }
      }
    }

  }
  .page-hero {
    .wrapper--title {
      background-color: transparent;
      position: absolute;
      @include gradient-vertical-three-colors(rgba(0, 0, 0, 0), rgba(0, 0, 0, .46), 46%, #000);
      min-height: 200px;
      width: 100%;
      bottom: 0;
      @include rem(padding-bottom, $space-quad);
      padding-bottom: 15vh;

      * {
        color: white;
      }
    }
    h1 {
      span,
      .small {
        display: block;
        @include rem(font-size, $font-size-h4);
        line-height: ($line-height-base*2)/$font-size-h4;
      }
    }
  }
  @include breakpoint ('screen' $bp-med) {
    .bg-image {
      padding-top: 0;
      height: calc(100vh - 68px);
      overflow: hidden;
      &.node {
        height: 100%;
      }
      background-position: 50% 50%;
      background-size: cover;
      .wrapper--title {
        position: absolute;
        background-color: transparent;
        @include gradient-vertical-three-colors(rgba(0, 0, 0, 0), rgba(0, 0, 0, .46), 46%, #000);
        min-height: 200px;
        width: 100%;
        bottom: 0;
        @include rem(padding-bottom, $space-quad);
        padding-bottom: 15vh;
        * {
          color: white;
        }
        &.vertically-centered {
          padding-bottom: 0;
          top: 0;
        }
      }
      span.read-more {
        display: block;
        @include rem(font-size, $font-size-h5);
        line-height: ($line-height-base*2)/$font-size-h5;
        font-weight: bold;
        &:after {
          content: none;
        }
      }
    }
  }

  @include breakpoint($desk) {
    .bg-image {
      &,
      &.page-hero {
        height: calc(100vh - 60px);
      }
    }
  }

}
.book--landing {
  .arrow {
    @include breakpoint($tab) {
      @include rem(margin-left, 56px);
    }
    @include breakpoint($wide) {
      margin-left: 0;
    }
  }
  .section {
    .wrapper--title {
      text-align: center;
    }
  }
  .page-hero {
    .wrapper--title {
      top: 0;
      .vertically-centered .inner {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
  @include breakpoint($tab) {
    .bg-image {
      h2 {
        @include rem(font-size, $font-size-h1);
        line-height: $line-height-h1;
        span,
        .small {
          @include rem(font-size, $font-size-h5);
          line-height: ($line-height-base*1.5)/$font-size-h5;
        }
      }
      h1 {
        @include rem(font-size, $font-size-h1-alt);
        line-height: $line-height-h1-alt;
        span,
        .small {
          display: block;
          @include rem(font-size, $font-size-h3);
          line-height: ($line-height-base*2)/$font-size-h3;
        }
      }
    }
  }
  @include breakpoint($desk) {
    &.page-hero {
      .wrapper--title {
        text-align: left;
      }
    }
  }
}
.book-export {
  .bg-image {
    img {
      width: 100%;
    }
  }

  .entity-paragraphs-item .entity-paragraphs-item:last-child p {
    margin: 0;
  }
  .fullpage > .wrapper--book-node > .entity {
    > .group-header,
    &.vertically-centered > div {
      display: none;
    }
    &.vertically-centered > div.content {
      display: block;
    }
  }

  .book--cover {
    background-size: contain;
    min-height: 825px;
    > .wrapper--title {
      background: $gray-darkest;
      @include rem(padding, 16px);
      text-align: center;
      img {
        display: block;
        max-width: 200px !important;
        margin: auto;
      }
      .node__title {
        text-align: center;
        @include rem(font-size, $font-size-h1-alt);

      }
    }
  }
  .book--cover,
  .book--landing {
    > .wrapper--title .node__title {
      color: white;
    }
  }

  .book--landing {
    > .wrapper--title.has-image {
      padding: 0;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      min-height: 200px;
      .inner {
        @include gradient-vertical-three-colors(rgba(0, 0, 0, 0), rgba(0, 0, 0, .46), 56%, #000);
        min-height: 200px;
      }
      .node__title {
        @include rem(padding, $space-half auto);
      }
    }
  }

  .book--sub-goal {
    h1 {
      @include rem(font-size, $font-size-h2);
    }
    @include rem(margin, $space auto);

  }
  #node-328692 > .wrapper--book-node {
    .bg-blue {
      background: none;
      padding: 0;
      * {
        color: #000;
      }
    }
    .paragraphs-item-embed-book-page {
      //margin-bottom: 0;
      //padding: em($space) 0;
      //background: $blue;
      //* {
      //  color: white;
      //}
      h2 {
        margin-bottom: 0;
        font-family: $font-secondary;
        span {
          font-family: $font;
        }
      }
    }
  }
}

