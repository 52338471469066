.tabs {
	overflow: hidden;

	ul {
		display: table;
		width: 100%;
	}

	li {
		display: table-cell;
		text-align: center;
		border-right: 1px solid $gray-lightest;

		&:last-child {
			border-right: 0;
		}
	}

	a {
		display: block;
		padding: $pad-half;
		background: $gray;
		@include link-colors($gray-lightest, $link-hover, $link-hover, $gray-lightest, $gray-lightest);
		&:hover, &:focus {
			background: $gray-light;
		}

		&.active {
			background: $gray-light;
			color: $link-hover;
		}
	}
}


.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }


.card-body {
  flex: 1 1 auto;
  @include rem(padding, 24px 24px);
}
.card-header {
  .btn {
    margin: 0;
    display: block;
    border: none;
    width: 100%;
    text-align: left;
  }
}

.accordion {
  clear: both;
  > .card {
    overflow: hidden;
    margin-bottom: -1px;
    //&:first-of-type {
    //	border-bottom: 0;
    //}
  }
}

.tabWrapper {
  .tabHeading {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(170px, 1fr) );
    button,.btn {
      margin: 0 !important;
      //@include rem(font-size, $font-size-base);
      //line-height: $line-height-base;
      @include rem(padding, $space-half 5px);
    }
    .btn + .btn {
      margin: 0 !important;
    }
    button.btn {
      border: 1px solid desaturate(darken($gray-lighter, 5%), 5%);
      border-bottom-color: #fff;
      background: #fff;
      color: $text-color;
      &.collapsed {
        @include button($gray-lighter, $gray-dark, darken($gray-lighter, 10%));
        //@include rem(font-size, $font-size-base);
        //line-height: $line-height-base;
      }
    }
  }
  .tab-pane {
    border: 1px solid desaturate(darken($gray-lighter, 5%), 5%);
    border-top-color: #fff;
    @include rem(padding, $space $pad);

  }

}

.accordion-tab {
  @include rem(margin-top, $space-half);
  .tab-header {
    @include link-colors($text-color, $text-color, $text-color, $text-color, $text-color);

    .icon {
      // border: 3px solid $gray-lighter;
      @include rem(width, 14px);
      @include rem(height, 16px);
      //@include rem(border-radius, 32px);
      @include rem(margin-right, 5px);
      position: relative;
      background: none;
      display: inline-block;
      @include rem(top, 2px);

      &:before, &:after {
        background: $text-color;
        display: block;
        transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
        content: '';
        @include rem(top, 8px);
        @include rem(height, 2px);
        @include rem(width, 12px);
        position: absolute;
        left: 0;

      }
      &:after {
        transform: rotate3d(0, 0, 1, -90deg);
      }
    }
    &[aria-expanded=true] {
      .icon:after {
        transform: rotate3d(0, 0, 1, 0deg);
      }
    }
  }
}
