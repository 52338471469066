@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: rgba(0,0,0,0.004) 1px 1px 1px;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
        text-shadow: none;
    }
}
