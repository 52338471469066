.block--edan-searchtab {
  .block__title {
    @extend  %hide-text;
  }
}
//.edan-search-form, .edan-searchtab-block-search-form, .search-form, .views-exposed-form-newdesk-search-search-form  {
//}
//.views-exposed-form-newdesk-search-search-form {
//  //  .container-inline,
//  //  .edan-searchtab-block-search-form{
//  //  position: relative;
//  //}
//  input[type="submit"] {
//    background: {
//     // image: image-url("search.svg");
//      repeat: no-repeat;
//      position:  50%;
//      size: 80%;
//      color: transparent;
//    }
//    @include svg-background('search');
//    position: absolute;
//    top: 0;
//    right: 0;
//    text-indent: -99999px;
//    height: 36px;
//    width: 36px;
//    padding: 0;
//    margin: 0;
//    border: 0;
//  }
//
//}
.ctools-auto-submit-full-form,
.views-newsdesk-search form,
.views-exposed-form-newdesk-search-search-form,
.edan-search-form,
.search-form,
.si-ossearch-search-form {
  position: relative;
  button.btn-svg {
    background: transparent;
    border: none;
    margin: 0;
    .label {
      @extend %hide-text;
    }
      position: absolute;
      right: 0;
      top: 0;
      @include rem(height, $space-double);
      @include rem(width, $space-double);
    .fill {
      fill: $link-color;
    }
    &:hover,
    &:focus {
      background: transparent;
      .fill {
        fill: $burgundy;
      }
    }
    svg {
      @include rem(width, 38px);
    }
  }
  .form-item-media-usage {
    label {
      font-weight: bold;
    }
  }
}
.edan-search-form,
.search-form {
  button.btn-svg.reset {
    display: none;
  }
  &.search-executed {
    .search {
      @include rem(right, 42px);
    }
    button.btn-svg.reset {
      display: block;
    }
  }
  .entity-paragraphs-item & {
    button {
      background: $yellow;
      .fill {
        fill: #FFF;
      }
      &:hover {
        background: $blue;
        .fill {
          fill: #FFF;
        }
      }
    }
  }
}

.views-newsdesk-search form {
  button.btn-svg.search {
    @include rem(right, 42px);
  }
}
.btn-svg.reset {
  svg {
    @include rem(width, 26px !important);
  }
}
//.siedu-custom-facets-form {
//  .btn-svg {
//    @include rem(padding, $pad-half 6px);
//  }
//}
.l-content-wrapper {
  .search-form-wrapper {
    position: relative;
  }
  .edan-search-form,
  .search-form,
  .views-exposed-form-newdesk-search-search-form,
  .si-ossearch-search-form {
      input[type="text"] {
        display: inline-block;
        width: 100%;
        @include rem(height, $space-double);
        @include rem(padding-right, $space-double);
        border-radius: 0;
        -webkit-appearance: none;
        border: 1px solid darken($gray-lighter, 5);
        @include rem(padding-left, 10px);
        background-image: none;
      }

      .form-text {
        @include input-placeholder {
          color: $gray-dark;
          text-transform: uppercase;
        }
      }

  }

  //.views-exposed-form-newdesk-search-search-form input[type="submit"] {
  //  top: 9px
  //}
}
html.js .form-autocomplete {
  background-image: none;}
.l-header #block-edan-searchtab-edan-searchtab-block-search-form {
  form {
    float: right;
    position: relative;
    width: 35px;
    min-width: 34px;
    height: auto;
    min-height: 34px;
    //overflow: hidden;
    // float: right;
    @include transition(min-width .4s ease);
    //-webkit-transition: width 0.4s;
    //-moz-transition: width 0.4s;
    //transition: width 0.4s;
    .form-type-textfield {
      //position: absolute;
      //top: 0;
      //left: 0;
      //border: none;
      //outline: none;
      //height: 33px;
      margin: 0;
      display: block;
      z-index: 0;
      //    width: 0;
      min-width: 0;
      opacity: 0;
      //padding-right: 35px;
      //    line-height: 33px;
      @include transition(all .4s ease);
      input {
        background: white;
      }
    }

    input[type="text"] {
      -webkit-appearance: none;
      // width: em(143px);
      height: 36px;
      padding-right: 40px;
      @include rem(font-size, $font-size-small);
      color: $text-color;
      background-image: none;

    }
    button.search {
      height: 36px;
      width: 44px;
      padding: 0;
      display: flex;
      align-items: center;
      svg {
        height: 32px;
        .fill {
          fill: #FFF
        }
      }
    }
    .form-item, .form-actions {
      margin: 0;
    }
  }

  -webkit-backface-visibility: hidden;
  &.sb-search-open,
  .no-js & {
    form {
      width: 100%;
      height: auto;
      //overflow: visible;
      min-width: 100%;
      .form-type-textfield {
       // right: 30px;
        min-width: 100%;
        opacity: 1;
        //@include transition(top .4s ease, visibility top .4s ease);
      }
    }
    button.search {
      .fill {
        fill: $link-color;
      }
    }
  }
} //end .l-header #block-search-form

.siedu-custom-facets-form {
  .reset-search {
    display: none !important;
  }
  &.search-executed {
    .reset-search {
      display: block !important;
    }
  }
}

@include breakpoint($desk) {
  .l-main {
    .search-form .container-inline {
      input[type="text"] {
        //height: em(37px);
        padding-right: 32%;
        padding-left: 4px;

        .page--landing & {
          border: none;
          text-align: right;
        }
      }

      .form-text {
        @include input-placeholder {
          color: $gray;
          text-transform: capitalize;
        }
      }
    }
  }

} //end breakpoint

//@include breakpoint($wide) {
//  .block--search-form {
//    left: round($max-width*($push-left/100%));
//  }
//}
