//
// Tooltips
// --------------------------------------------------
.tooltip-wrapper {
	position: relative;
}

$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-modal-background:  1040 !default;
$zindex-modal:             1050 !default;
//** Tooltip max width
$tooltip-max-width:           300px !default;
//** Tooltip text color
$tooltip-color:               #FFF !default;
//** Tooltip background color
$tooltip-bg:                  $blue-darkest !default;

//** Tooltip arrow width
$tooltip-arrow-width:         8px !default;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg !default;
$tooltip-opacity: 1;

//== Tooltips
//
//##



// Base class
.tooltip {
	position: absolute;
	z-index: $zindex-tooltip;
	display: block;
	// Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
	// So reset our font and text properties to avoid inheriting weird values.
	@include reset-text;
	@include rem(font-size, $font-size-small);
	line-height: $line-height-small;

	opacity: 0;

	&.in { @include opacity($tooltip-opacity); }
	&.top { margin-top: -3px; padding: $tooltip-arrow-width 0; }
	&.right { margin-left: 3px; padding: 0 $tooltip-arrow-width; }
	&.bottom { margin-top: 3px; padding: $tooltip-arrow-width 0; }
	&.left { margin-left: -3px; padding: 0 $tooltip-arrow-width; }
}

// Wrapper for the tooltip content
.tooltip-inner {
	max-width: $tooltip-max-width;
	padding: 3px 8px;
	color: $tooltip-color;
	text-align: center;
	background-color: $tooltip-bg;
	.orange & {
		background-color: $orange;
	}
	.purple & {
		background-color: $purple;
	}
	.brand-blue & {
		background-color: $blue-brand;
	}
	.green & {
		background-color: #31864e;
	}
	.burgundy & {
		background-color: $burgundy;
	}
}

// Arrows
.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
	&.top .tooltip-arrow {
		bottom: 0;
		left: 50%;
		margin-left: -$tooltip-arrow-width;
		border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
		border-top-color: $tooltip-arrow-color;
	}
	&.top-left .tooltip-arrow {
		bottom: 0;
		right: $tooltip-arrow-width;
		margin-bottom: -$tooltip-arrow-width;
		border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
		border-top-color: $tooltip-arrow-color;
	}
	&.top-right .tooltip-arrow {
		bottom: 0;
		left: $tooltip-arrow-width;
		margin-bottom: -$tooltip-arrow-width;
		border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
		border-top-color: $tooltip-arrow-color;
	}
	&.right .tooltip-arrow {
		top: 50%;
		left: 0;
		margin-top: -$tooltip-arrow-width;
		border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
		border-right-color: $tooltip-arrow-color;
		.orange & {
			border-right-color: $orange;
		}
		.purple & {
			border-right-color: $purple;
		}
		.brand-blue & {
			border-right-color: $blue-brand;
		}
		.green & {
			border-right-color: #31864e;
		}
		.burgundy & {
			border-right-color: $burgundy;
		}
	}
	&.left .tooltip-arrow {
		top: 50%;
		right: 0;
		margin-top: -$tooltip-arrow-width;
		border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
		border-left-color: $tooltip-arrow-color;
		.blue & {
			border-left-color: $blue-darkest;
		}
		.orange & {
			border-left-color: $orange;
		}
		.purple & {
			border-left-color: $purple;
		}
		.brand-blue & {
			border-left-color: $blue-brand;
		}
		.green & {
			border-left-color: #31864e;
		}
		.burgundy & {
			border-left-color: $burgundy;
		}
	}
	&.bottom .tooltip-arrow {
		top: 0;
		left: 50%;
		margin-left: -$tooltip-arrow-width;
		border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
		border-bottom-color: $tooltip-arrow-color;
	}
	&.bottom-left .tooltip-arrow {
		top: 0;
		right: $tooltip-arrow-width;
		margin-top: -$tooltip-arrow-width;
		border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
		border-bottom-color: $tooltip-arrow-color;
	}
	&.bottom-right .tooltip-arrow {
		top: 0;
		left: $tooltip-arrow-width;
		margin-top: -$tooltip-arrow-width;
		border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
		border-bottom-color: $tooltip-arrow-color;
	}
	&.top,
	&.top-left,
	&.top-right {
		.tooltip-arrow {
			.blue & {
				border-top-color: $blue-darkest;
			}
			.orange & {
				border-top-color: $orange;
			}
			.purple & {
				border-top-color: $purple;
			}
			.brand-blue & {
				border-top-color: $blue-brand;
			}
			.green & {
				border-top-color: #31864e;
			}
			.burgundy & {
				border-top-color: $burgundy;
			}
		}
	}
}
