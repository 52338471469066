.entity-paragraphs-item {
  p:last-child {
    margin-bottom: 0;
  }
  @include rem(margin-bottom, $space-and-half);
  //padding: em($space) 0 ;
  .entity-paragraphs-item {
    padding: 0;
    @include rem(margin-bottom, $space);
    &.spacing {
      @include rem(margin, $space auto);
    }
    &:last-child {
      margin-bottom: 0;
      p {
        @include rem(margin-bottom, $space);
      }
    }
  }
  &.paragraphs-item-teaser-block {
    .node--teaser {
      @include rem(margin-bottom, $space-and-half);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.slider {
    .content {
      width: 96%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.bg-blue,
  &.bg-gray,
  &.bg-gray-light,
  &.bg-gray-lighter,
  &.bg-green,
  &.bg-orange,
  &.bg-purple {
    @include rem(padding, $space $pad);
    &.layout-constrain,
    &.full-width {
      padding-left: 0;
      padding-right: 0;
    }
  //  @include rem(padding-bottom, $space);
  }
  &.padding-extra {
    @include rem(padding, $space-double 0);
  }

  &.no-spacing {
    margin: 0 auto;
  }

  &.spacing-top {
    @include rem(margin-top, $space-and-half);
  }

  &.spacing-bottom {
    @include rem(margin-bottom, $space-and-half);
  }
  &.spacing-extra {
    @include rem(margin, $space-double auto);
  }
  .col .inner + .entity-paragraphs-item {
    margin-top: 0;
  }
  &.constrain-text {
    max-width: 100%;
    > .content {
      max-width: 1000px;
    }
  }
} //end entity-paragraphs-item
.padding,
.padding-extra,
.padding-all {
  > .entity-paragraphs-item {
    margin-bottom: 0;
  }
}
.paragraphs-item-text-block {
  li {
    @include rem(margin,0 0 0 $space);
  }
  ul.spacing {
    li {
      @include rem(margin, 0 0 $space $space);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.pane-node-field-block-content {
  > .entity-paragraphs-item:last-child {
    @include rem(margin-bottom, $space-and-half);

    .node-type-landing-page & {
      margin-bottom: 0;
      @include rem(padding-bottom, $space-and-half);
    }
  }
}


.col {
  > .entity-paragraphs-item:first-child {
    > .group-header {
      margin-top: 0;
    }
  }
}

.group-left,
.group-right {
  .bg-blue,
  .bg-gray,
  .bg-gray-light,
  .bg-gray-lighter,
  .bg-green,
  .bg-orange,
  .bg-purple {
    @include rem(padding, $space $pad);
  }
}

//.pane-node-field-block-content > .entity-paragraphs-item {
//   margin: em($space) 0 0;
//    padding: em($pad-half) 0;
//}
@include breakpoint($tab) {
  //.l-region--main-content.col-md-9  .paragraphs-item-two-column-right {
  //  .col-md-9 {
  //    @include span(6 of 9);
  //  }
  //  .col-md-3 {
  //    @include span(3 of 9 last);
  //    img {
  //      width: 100% !important;
  //      height: auto !important;
  //    }
  //    figure {
  //      margin-right: 0;
  //    }
  //  }
  //}
}
@include breakpoint('all' 768px) {
  //.paragraphs-item-two-column-right.layout {
  //  @include background-image(linear-gradient(to right, #fff 77.14286%, $gray-lighter 77.14286%,$gray-lighter 77.14286%, #fff 77.26209%));
  //
  //  .col-md-9 & {
  //    @include background-image(linear-gradient(to right, #fff 69.23077%, $gray-lighter 69.23077%,$gray-lighter 69.35%, #fff 69.35%));
  //    //.col-md-3 {
  //    //  padding-left: gutter(9);
  //    //}
  //  }
  //}
  .l-region--main-content.col-md-9 .paragraphs-item-two-column-right {
    .col-md-3 {
      //padding-left: 3.84615%;
    }
  }
  .paragraphs-item-two-column-left.layout {
    //background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIyMi44NTcxNCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiLz48c3RvcCBvZmZzZXQ9IjIyLjg1NzE0JSIgc3RvcC1jb2xvcj0iI2QwZDdkZSIvPjxzdG9wIG9mZnNldD0iMjIuOTc2MzclIiBzdG9wLWNvbG9yPSIjZDBkN2RlIi8+PHN0b3Agb2Zmc2V0PSIyMi45NzYzNyUiIHN0b3AtY29sb3I9IiNmZmZmZmYiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    //background-size: 100%;
    //background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(22.85714%, #ffffff), color-stop(22.85714%, #d0d7de), color-stop(22.97637%, #d0d7de), color-stop(22.97637%, #ffffff));
    //background-image: -moz-linear-gradient(left, #ffffff 22.85714%, #d0d7de 22.85714%, #d0d7de 22.97637%, #ffffff 22.97637%);
    //background-image: -webkit-linear-gradient(left, #ffffff 22.85714%, #d0d7de 22.85714%, #d0d7de 22.97637%, #ffffff 22.97637%);
    //background-image: linear-gradient(to right, #ffffff 22.85714%, #d0d7de 22.85714%, #d0d7de 22.97637%, #ffffff 22.97637%);
    .col-md-3 {
      //padding-right: gutter();
      image {
        width: 100% !important;
        height: auto !important;
      }
    }
    //.col-md-9 & {
    //  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIzMC43NjkyMyUiIHN0b3AtY29sb3I9IiNmZmZmZmYiLz48c3RvcCBvZmZzZXQ9IjMwLjc2OTIzJSIgc3RvcC1jb2xvcj0iI2QwZDdkZSIvPjxzdG9wIG9mZnNldD0iMzAuODg4NDYlIiBzdG9wLWNvbG9yPSIjZDBkN2RlIi8+PHN0b3Agb2Zmc2V0PSIzMC44ODg0NiUiIHN0b3AtY29sb3I9IiNmZmZmZmYiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    //  background-size: 100%;
    //  background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(30.76923%, #ffffff), color-stop(30.76923%, #d0d7de), color-stop(30.88846%, #d0d7de), color-stop(30.88846%, #ffffff));
    //  background-image: -moz-linear-gradient(left, #ffffff 30.76923%, #d0d7de 30.76923%, #d0d7de 30.88846%, #ffffff 30.88846%);
    //  background-image: -webkit-linear-gradient(left, #ffffff 30.76923%, #d0d7de 30.76923%, #d0d7de 30.88846%, #ffffff 30.88846%);
    //  background-image: linear-gradient(to right, #ffffff 30.76923%, #d0d7de 30.76923%, #d0d7de 30.88846%, #ffffff 30.88846%);
    //  //.col-md-3 {
    //  //  padding-right: gutter(9);
    //  //}
    //}
  }
}

