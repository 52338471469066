.node--promotion.node--full {
  > .content {
    background: #fff;
    padding-top: 32px;
    p:last-child {
      margin-bottom: 0;
    }
    //@include breakpoint($desk) {
    //  padding-top: 2.7em;
    //}
    text-align: center;
  }
  a:hover {
    border-bottom: none;
  }
}
