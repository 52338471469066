/* Links */
a {
	 //link-colors($normal, $hover, $active, $visited, $focus)
	 @include link-colors($link-color, $link-hover, $link-hover, $link-color, $link-color);
	 text-decoration: none;
	 &.active {
		 color: $link-hover;
	 }
	 &:hover {
		 text-decoration: none;
		 cursor: hand;
		 cursor: pointer;
		 //text-decoration: u;

		 //.l-content-wrapper & {
			// border-bottom: 1px dotted;
		 //}
	 }
 }