.field--type-image {
  line-height: 0;
}

figure.field--type-image {
  line-height: $line-height-small;
}


.field--label-inline {
  &.field-group-html-element {
  > div:first-child:after {
      content: ':';
    }
  }
  > div:first-child:after {
    content: ':';
  }
  > div:first-child,
  .field-label {
    font-weight: bold;
    @include rem(margin-right, 5px);
  }
  @include breakpoint($tab) {
    .node-type-media-event & {
      @include rem(margin-left, 120px);
    }
    //@include rem(margin-left, 120px);

    > div:first-child,
    .field-label {
      display: inline-block;
      .node-type-media-event & {
        @include rem(width, 115px);
        @include rem(margin-left, -120px);
        float: left;
      }
    }
  }

}

.field--name-field-media-info {
  @include rem(font-size, $font-size-small);
  line-height: $line-height-small;
}

.field--name-field-tagline,
.pane-node-field-tagline {
  //font-family: $font-secondary;
  font-style: italic;
  @include rem(font-size, $font-size-tagline);
  line-height: $line-height-tagline;
  @include rem(margin-top, $space-half);
 // color: $header-color;
  .vocabulary-media-contacts & {
    @include rem(font-size, $font-size-base);
    margin-top: 0;
  }
}

.file-video {
  position: relative;
  height: 100%;
  .newsweek & {
    height: auto;
  }

  .flex-pauseplay {
    top: 80vh;
    bottom: auto;
    .no-cssvhunit & {
      @include rem(bottom, $space-and-half);
      top: 75%;
    }
    a {

      opacity: .5;
      &:hover {
        opacity: .8;
      }
    }
  }
}

.file-download-link {
  a {
    @include font-icon($fa-var-download, 25px);
  }
  .file-size {
    display: block;
    //@include rem(font-size, $font-size-small);
    font-size: 81.25%;
    color: $gray-dark;
    @include rem(padding-left, 25px);
  }
}

.field--name-field-media-contact {
  .field-label {
    font-weight: bold;
  }
}

.colorbox-link-close img {
  width: 100% !important;
}
