/*------------------------------------*\
    $MEDIA ELEMENTS
\*------------------------------------*/

/* Flexible Media */
img, video, object, iframe {
	max-width: 100% !important;
	//height: auto !important;
	border: none;
}
img {
	height: auto !important;
	//height: auto;
}
//iframe {
//	margin-bottom: $space;
//	//max-width: 100%;
//	//height: auto;
//}

figure {
	background: transparent;
	border: 1px solid $teaser-border;
	@include rem(margin-bottom, $space-half);
	@include rem(margin-right, $pad-half);

	//img {
	//	@include rem(margin-bottom, $space-half);
	//}
}
figcaption,
.img-caption {
	//font-style: italic;
	max-width: 98%;
	@include rem(font-size, $font-size-small);
	line-height: $line-height-small;
	@include rem(margin-top, $space-half);
	p {
		font-style: normal;
		@include rem(margin-bottom, $space-half);
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.image {
  display: block;
}

//canvas {
//	height: 500px !important;
//}

.wrapper--map {
	padding-bottom: 75%;
	@include breakpoint($tab) {
      padding-bottom: 52%;
	}
  @include breakpoint($desk) {
    padding-bottom: 33.333%;
  }

}

.wrapper--video {
	padding-bottom: 56.25%; /* 16:9 */
  @include breakpoint($tab) {
    .view-mode-grid_view & {
      padding-bottom: 75%;
    }
  }
}

.wrapper--video,
.wrapper--map {
	position: relative;
	height: 0;
	.media-inner & {
		position: static;
		height: 100%;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% ;
		height: 100%;
		margin: 0;
	}
}


//.js {
//  @-webkit-keyframes rotateplane {
//    0% {
//      -webkit-transform: perspective(120px);
//    }
//    50% {
//      -webkit-transform: perspective(120px) rotateY(180deg);
//    }
//    100% {
//      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
//    }
//  }
//
//  @keyframes rotateplane {
//    0% {
//      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
//    }
//    50% {
//      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
//    }
//    100% {
//      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
//    }
//  }
//
//  /* The lazyloaded element: IMG, IFRAME, DIV. */
//  .media{
//    display: block;
//    height: auto;
//    min-height: 1px;
//    opacity: 0;
//    transition: opacity 500ms ease-in-out;
//    &.complete {
//      opacity: 1;
//    }
//  }
//
//
//  .media--loading {
//    -webkit-backface-visibility: hidden;
//    backface-visibility: hidden;
//    position: relative;
//  }
//
//  .media--loading::before {
//    content: '';
//    display: block;
//    width: 30px;
//    height: 30px;
//    max-width: 30px;
//    background: #2eaae0;
//    position: absolute;
//    left: 50%;
//    top: 50%;
//    margin-left: -15px;
//    margin-top: -15px;
//    font-size: 0;
//    z-index: 22;
//    -webkit-animation: rotateplane 1.2s infinite ease-in-out;
//    animation: rotateplane 1.2s infinite ease-in-out;
//  }
//
//}

.b-lazy {
	min-height: 100px;
	&.b-error {
		min-height: 0;
		height: 0 !important;
	}
}
