.icon--floor-plan {
  @include button($gray-lighter, $gray-dark, darken($gray-lighter, 10%));
  //@include link-colors($text-color, $text-color, $text-color, $text-color, $text-color);
  span {
    display: block;
    padding-right: 36px;
    background: {
      repeat: no-repeat;
      size: contain;
      position: 100% 50%;
    };
    @include svg-background('floorplan');
  }
}

.back-link a {
  @include font-icon($fa-var-angle-double-right, 10px, 14px, true);
}

.wrapper--addthis {
  display: none;
  > div {
    display: inline-block;
  }

  > li {
    display: inline-block;
    margin: 0;
    vertical-align: text-top;

    ul > li {
      display: inline;
    }
  }

  .at-icon-wrapper {
    background: transparent !important;
  }

  .addthis_toolbox {
    display: flex;
    .node-type-book & {
      display: none;
    }
    a {
      margin-bottom: 0;
    }
  }

  position: relative;

  .addthis_button_google_classroom svg {
    height: 34px !important;
    width: 33px !important;
  }

  .addthis_button_pinterest {
    margin-top: 6px;

    > div {
      width: 20px;
      height: 20px;
      @include svg-background('pinterest-logo');
      background-repeat: no-repeat;

      .node-type-book & {
        @include svg-background('pinterest-white');
      }

      span {
        display: none;
      }

    }

    &:hover {
      > div {
        .node-type-book & {
          @include svg-background('pinterest-yellow');
        }
      }
    }
  }

  .social-media {
    visibility: hidden;
    position: absolute;
    min-width: 196px;
    border: 1px solid $gray;
    background: white;
    justify-content: space-evenly;
    top: 100px;
    right: 20%;
    .at-icon-wrapper {
      width: 34px !important;
    }
    .node-type-book & {
      background: $preface-bg;
      border: none;
    }

    a {
      // padding: 4px 0;
      display: block;
      float: left;
      line-height: 1;
    }
    @include transition(all .4s cubic-bezier(.55, .085, 0, .99));
  }

  //end social-media
  .other {
    li > a {
      border: 1px solid transparent;

      &:hover {
        height: 32px;
        //border: 1px solid $gray;
      }
    }

  }

  //end other

  a {
    svg path {
      fill: $gray !important;

      .node-type-book & {
        fill: white !important;
      }
    }

    &:hover {
      svg path {
        fill: $link-hover !important;

        .node-type-book & {
          fill: $yellow !important;
        }
      }
    }
  }
  @include breakpoint($desk) {
    display: flex;
    position: absolute;
    padding: 0;
    right: 0;
    @include rem(top, -32px);

    .page--theater &,
    .node-type-imax-page & {
      @include rem(padding, 0 0 24px);
      @include rem(top, 8px);
    }
    .edan-record &,
    .node-type-book & {
      top: 0;
    }

    .social-media.active {
      z-index: 1;
      display: flex !important;
      @include rem(top, 32px);
      .wrapper--ogmt &,
      //.edan-record & {
      //  @include rem(top, 35px);
      //
      //}
      .node-type-book & {
        @include rem(top, 55px);
      }
    }
  }
}

.pane-addthis-addthis-block {
  position: relative;
}

a.print {
  display: block;
  height: 32px;
  width: 36px;
  float: left;
  border: 1px solid transparent;
  position: relative;
  background: {
    repeat: no-repeat;
    position: 50% 50%;
    color: white;
    size: 80%;
  }
  @include svg-background('print-gray');


  &:hover,
  &.active {
    //border: 1px solid $gray;
    @include svg-background('print-burgundy');
  }
}

button.share-icon.btn-svg {
  svg {
    @include rem(width, 24px);
  }
  .fill {
    fill: $gray;
  }
  .svg-bg {
    stroke: $gray;
  }

  z-index: 5;
  position: relative;
 // @include transition(all .4s cubic-bezier(.55, .085, 0, .99));

  .node-type-book & {
    @include rem(height, 55px);

    .fill {
      fill: #fff;
    }

    .svg-bg {
      stroke: #fff;
    }
  }
  &:hover,
  &.active {
    .fill {
      fill: $burgundy;
    }

    .svg-bg {
      stroke: $burgundy;
    }

    .node-type-book & {
      .fill {
        fill: $yellow;
      }

      .svg-bg {
        stroke: $yellow;
      }
    }
  }

  &.active {
    &:before {
      @include rem(bottom, -8px);
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #fff;
      border-width: 10px;
      margin-left: -10px;
      opacity: 1;
      animation: menuArrow .4s cubic-bezier(.55, .085, 0, .99) forwards;
      z-index: 10;
    }

    &:after {
      @include rem(bottom, -7px);
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: $gray;
      border-width: 10px;
      margin-left: -10px;
      opacity: 1;
      z-index: 5;
      animation: menuArrow .4s cubic-bezier(.55, .085, 0, .99) forwards
    }

    .node-type-book & {
      &:before,
      &:after {
        bottom: 0;
        border-bottom-color: $preface-bg;
      }
    }
  }
}

.wrapper--preface-buttons {
  p {
    margin: 0;
  }

  .link-wrapper {
    .btn.btn-alt {
      margin: 0;

      span {
        margin: 3px 6px 0 0;
        @include transition(transform 300ms ease-in);
      }

      &:hover,
      &:active {
        span {
          @include transform(translateX(-8px));
        }
      }
    }

    ul.links {
      @include float-list('inline-block');
      float: right;

      span {
        @extend %hide-text;
      }

      a {
        @include rem(padding, 1px 15px);

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}


