@use "sass:math";

.masonry-grid-wrapper {
  margin: 0 auto;
  .fillers {
    display: none;
  }
  .filler,
  .mason-filler,
  .filler-fade {
    background: $gray-dark;
  }
}
.masonry-grid {
  position: relative;
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
  .grid-item.shadow {
    .inner {
      @include box-shadow(rgba(0, 0, 0, 0.1) 1px 1px 4px);
    }
  }
  .grid-item .node--teaser {
    @include rem(margin-bottom, $space);
  }

  @include breakpoint("screen" em(499px)) {
    @include clearfix;
    .grid-item {
      float: left;
      //  //position: relative;
      // margin: 0 5px 5px 0;
      &.col-sm-6 {
        margin: 0;
        width: 50%;
        //width:  percentage((1/2)-.015);
      }
      .node--teaser {
        padding-right: 12px;
        @include rem(padding, 0 $space-half $space-half 0);
        margin-bottom: 0;
      }
      .node--teaser-long {
        display: block;
      }
    }
    &.hover-title {
      .has-media .node--teaser.node--teaser-long,
      .has-media.node--teaser.node--teaser-long {
        .b-text-wrapper {
          opacity: 0;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          z-index: 100;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          text-align: center;
          background-color: rgba(0, 0, 0, 0.6);
          @include transition(opacity 0.2s ease-out);
        }
        .inner {
          display: block;
          position: relative;
          background: none;
          &:hover {
            color: white;
            .b-text-wrapper {
              opacity: 1;
            }
          }
        }
        h3,
        h2,
        h4,
        .beta,
        .gamma,
        .delta,
        .epsilon {
          color: white;
        }
      }
    }
  }

  @include breakpoint($tab) {
    .grid-item {
      &.col-sm-4 {
        margin: 0;
        width: percentage(math.div(1, 3));
      }
    }
  }
  @include breakpoint($desk) {
    .grid-item {
      &.col-md-4 {
        margin: 0;
        width: percentage(math.div(1, 3));
        //width:  percentage((1/4)-.01);
      }
      &.col-md-3 {
        margin: 0;
        width: percentage(math.div(1, 4));
        //width:  percentage((1/4)-.01);
      }
    }
  }
  @include breakpoint($wide) {
    .grid-item {
      &.col-wd-2 {
        margin: 0;
        width: percentage(math.div(1, 6));
        //width: percentage((1/6)-.0065);
      }
      &.col-wd-3 {
        margin: 0;
        width: percentage(math.div(1, 4));
        //width:  percentage((1/4)-.01);
        //&:nth-child(3n+3) {
        //  margin: 0 5px 5px 0;
        //
        //}
        //&:nth-child(4n+4) {
        //  margin-right: 0;
        //}
      }
    }
  }
}
