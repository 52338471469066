//Intro text
.intro {
	@extend h4;
	font-family: $font;
}

//Pullquote
.pullquote {
	font-family: $font-secondary;
	font-size: $font-size-h5;
}


.byline {
	@include rem(font-size, $font-size-small);
	font-style: italic;
}

//Passages of text
.text {
	a {
		text-decoration: underline;
	}

	ul {
		list-style: disc;
		@include rem(margin,0 0 $space $pad);

		ul {
			margin-bottom: 0;
		}
	}

	ol {
		list-style: decimal;
		@include rem(margin,0 0 $space $pad);

		ol {
			margin-bottom: 0;
		}
	}
}

.giga {
	@include rem(font-size, $font-size-giga);
	line-height: $line-height-giga;
}

.mega {
	@include rem(font-size, $font-size-mega);
	line-height: $line-height-mega;
}

.secondary {
	font-family: $font-secondary;
}

.primary {
	font-family: $font;
}
.small {
	font-size: 87.5%;
	line-height: $line-height-small;
}

/* Subheadings */
.subheading {
	font-family: $font-secondary;
	font-weight: normal;
}

.page-title span {
	@include rem(font-size, $font-size-h4);
	font-family: $font-secondary;
	display: block;
	@include rem(margin, $space-half 0);
}

.block-display {
	display: block;
}



h1, h2, h3 {
	&.spacing-top {
		@include rem(margin-top, $space-and-half);
	}
	&.spacing-bottom {
		@include rem(margin-bottom, $space-and-half);
	}
	&.spacing {
		@include rem(margin, $space-and-half auto);
	}
}

