//Pagination

.pager,
.pagination {
  @extend %reset-list;
  @include rem(margin, $space 0 !important);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;

  .pager-wrapper & {
    margin-top: 0;
  }

  li,
  li.pager-current,
  li.pager__item {
    @include rem(height, 45px);
    @include rem(margin, $space-quarter 0);
    @include rem(width, 45px);
    @include rem(padding, 6px 5px);

    align-items: center;
    display: flex;
    border-width: 1px 1px 1px 0;
    font-weight: bold;
    justify-content: center;
    position: relative;
    text-align: center;

    a {
      @include link-colors(
        $text-color,
        $link-hover,
        $link-hover,
        $text-color,
        $text-color
      );

      text-decoration: none;
    }

    &:after {
      bottom: 0;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      @include transition(all 0.4s cubic-bezier(0.55, 0.085, 0, 0.99));
    }

    &.pager-current,
    &:hover {
      &:after {
        border-bottom-color: $gray-light;
        border-width: 10px;
        margin-left: -10px;
      }
      a {
        border: none;
      }
    }

    &:hover:after {
      animation: menuArrow 0.7s cubic-bezier(0.55, 0.085, 0, 0.99) forwards;
      border-bottom-color: $link-hover;
    }

    &.pager__item--previous {
      width: auto;
      box-sizing: border-box;

      a {
        @include font-icon($fa-var-angle-left, 10px);
      }
    }
    &.pager__item--next {
      a {
        @include font-icon($fa-var-angle-right, 10px);
      }
    }
    &.pager__item--previous,
    &.pager__item--next {
      padding: 0;
      .text {
        @include hide-text;
      }
      a {
        padding: 0;
        position: static;
        &:before {
          left: 45%;
        }
      }
      &:hover a:before {
        color: $link-hover;
        //&:after {
        //  border-bottom: none;
        //}
      }
    }
    &:first-child {
      border-left-width: 1px;
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }
    &.pager__item--first,
    &.pager__item--last,
    &.pager__item--ellipsis {
      display: none;
    }

    &.pager__item {
      &--current {
        border: 1px solid $text-color;
      }
    }
  }
}

.pagination li.active {
  padding: 0;
  a {
    background: $blue;
  }
}
//.js .search-results-container .pager,
.js .search-results-container .edan-loader,
.js .si-pager .pager {
  margin: em($space-and-half) 0 auto;
  text-align: center;
  li.pager__item {
    float: none;
    display: none;
  }
  li.pager__item--next {
    display: inline-block !important;
  }
  li.pager__item--next a,
  button.edan-loader__button {
    @include button($gray-lighter, $gray-dark, darken($gray-lighter, 10%));
    // font-size: $font-size-h4;
    padding: em($space) em($pad);
    text-transform: capitalize;
  }
}
