footer {
	background: $footer-bg;
	@include rem(margin-top, $space);
	@include transition(all .2s ease-in);
	.fixed-footer & {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
	}
	.show-page-nav & {
		@include rem(padding-bottom, $fab-size*1.5);
	}
	.node-type-landing-page & {
		margin-top: 0;
	}
	a {
		@include link-colors(white, $link-color-alt, $link-color-alt, white, white);
		&:hover span {
			color: $link-color-alt;
		}
	}
	* {
		color: white;
	}

	p {
		margin: 0;
	}
	.l-region--footer {
		.l-container {
			max-width: 100%;
		}
		padding: em($space) 0;
		.si-logo {
			display: block;
			background: {
				image: image-url('logos/logo-white.png');
				repeat: no-repeat;
				position:  top left;
				size: contain;
			}
			@include rem(height, 60px);
			@include rem(margin-bottom, $space);
		}


		.menu {
			@extend %reset-list;
			display: flex;
		//	@include justify-content(center);
			.no-flexbox & {
				@include clearfix;
			}
			li {
				flex: 1 1 auto;
				.no-flexbox & {
					float: left;
				}
			}
			a {
				display: block;
			}
		} // end .menu
		.pane-menu-menu-footer {
			@include rem(margin-bottom, $space);
			.menu {
				flex-wrap: wrap;
				li {
					width: 50%;
					max-width: 50%;
				}
			}
		}
		.pane-menu-menu-social-media {
			.menu-title {
				@extend %hide-text;
			}
			.menu {
				margin: 0 auto;
				align-items: center;
				max-width: 320px;
			}
			a {
				@include rem(padding, $space $pad);
			}
		}
		.panel-col-bottom {
			border-top: 1px solid lighten($footer-bg, 5%);
		}
		.sign-up-wrapper {
			@include rem(margin-top, $space);
			label {
				padding-bottom: 0;
				float: left;
				width: 45%;
				flex: 1 0 auto;
				text-align: right;
			}
			input#cons_email {
				line-height: 1;
				padding-right: 40px;
				border-width: 0 0 1px;
				width: 55% - gutter();
				margin-left: gutter();
				float: right;
				flex: 1 0 auto;
				background: transparent;
				text-align: center;
			}
			input[type=submit] {
				text-indent: -10000px;
				@include svg-background('double-arrow-right');
				background-color: transparent;
				background-repeat: no-repeat;
				background-size: 45%;
				background-position: 50%;
				width: 32px;
				height: 30px;
				position: absolute;
				right: -1px;
				border: none;
				bottom: 6px;
			}
			input[type=image] {
				 width: 32px;
				 height: auto;
				 position: absolute;
				 right: -1px;
				 border: none;
			}
			input::-webkit-input-placeholder {
				color: #FFF !important;
				opacity: 1;
			}
			input:-moz-placeholder {
				color: #FFF !important;
				opacity: 1;

			}
			input::-moz-placeholder {
				color: #FFF !important;
				opacity: 1;
			}
			input:-ms-input-placeholder {
				color: #FFF !important;
				opacity: 1;
			}
		}
		#signup-footer-container {
			@include rem(font-size, $font-size-small);
			padding-left: 50%;
			@include opacity(.6);
			span {
				display: block;
			}
			font-style: italic;

		}
		.error {
			color: #EF4836; // $red-bright
			padding-left: 50%;
		}
		.form-details {
			position: relative;
			@include clearfix;
			&:before,
			&:after {
				flex-basis: 0;
				order: 1;
			}
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			@include rem(margin-bottom, $space-half);
		}

		@include breakpoint($tab) {
			.panel-panel {
				@include clearfix;
			}
			.logo-wrapper {
				@include span(3);
				@include rem(max-height, 100px);
			}
			.pane-menu-menu-footer {
				@include pre(1);
				@include span(8 last);
			}
			//.si-logo {
			//	padding-top: 25%
			//}
			.panel-col-bottom {
				label {
					@include rem(padding-top, 16px);
				}
				input[type=submit] {
					bottom: 2px;
				}
				#signup-footer-container span {
					display: block;
				}
			}

		} //end breakpoint($tab)
		@include breakpoint($desk) {
			.pane-menu-menu-footer .menu li {
				width: 33%;
				max-width: 33%;
			}

			.pane-menu-menu-social-media {
				@include span(5);
				.menu {
					margin: 0;
					a {@include rem(padding-top, 16px);}
				}
			}
			.sign-up-wrapper {
				@include span(7 last);
				margin-top: 0;
				label {
					width: 50%;
				}
				input#cons_email {
					width: 50% - gutter();
				}
			}
			#si-email-signup-form {
				float: right;
			}
			#signup-footer-container {
				padding-left: 50%+gutter();
			}
			.panel-col-bottom {
				padding-top: em($space);
				#signup-footer-container span {
					display: block;
				}
			}

		}
		@include breakpoint($wide) {
			//.si-logo {
			//	padding-top: 20%
			//}
		.panel-col-bottom #signup-footer-container span {
			display: inline;
		}
			.pane-menu-menu-social-media {
				width: span(4);

			}
			.sign-up-wrapper {
				width: span(8);
			}

		} //end breakpoint($wide)

	} // end l-region--footer
}
