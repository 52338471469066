#si-email-signup-form {
  .subform {
    label,
    input {
      @extend .hide;
    }
  }
}

//.l-content-wrapper {
  .form-text {
    @include input-placeholder {
      color: $gray;
      text-transform: capitalize;
    }
  }
//}