.entity-field-collection-item {
  &.field-collection-item-field-agenda {
    @include rem(margin, $space-half 0);
    p {
      @include rem(margin-bottom, $space-half);
    }
    @include breakpoint($tab) {
      &.has-time {
        @include clearfix;
        .time-default,
        .agenda-detail {
          float: left;
        }
        .time-default {
          width: 20%;
          margin-right: 10px;
          @include rem(max-width, 100px);
        }
        .agenda-detail {
          width: 75%;
        }
      }
    }
  }
}

.field-collection-view-links,
.action-links {
  @extend %reset-list;
  li {
    display: inline-block;
    @include rem(margin-right, $pad);
  }
}