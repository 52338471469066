.google-appliance-keymatch-results li,
.google-appliance-keymatch {
	&,
	.reset-list & {
		background-color: $gray-lightest;
		border: 1px solid darken($gray-lightest, 2%);
		@include rem(padding, $space-half $pad-half);
	}
	.h3, h3 {
		margin-bottom: 0;
	}
}
