.l-region--sidebar-first,
.l-region--sidebar-second,
.l-region--left-aside,
.l-region--right-aside {
  .title,
  .pane-title,
  h2 {
    font-family: $font;
   // color: $burgundy;
   // .newsdesk & {
   //   color: $blue-dark;
   // }
  }
  h2, .beta {
    @include rem(font-size, $font-size-h3);
    @include rem(margin-bottom, $space);
    line-height: $line-height-h3;
    color: $blue-darkest;
  }
  h3, .gamma {
    @include rem(font-size, $font-size-h4);
    line-height: $line-height-h4;
    @include rem(margin-bottom, $space-half);
  }
  h4, .epsilon {
    @include rem(font-size, $font-size-h5);
    line-height: $line-height-h5;
    margin-bottom: 0;
  }
  > div {
    @include rem(margin-bottom, $space-and-half);
    //&:last-child {
    //  margin-bottom: 0;
    //}
  }
  ul {
    @include rem(margin, 0 0 $space $pad-and-half);
    &.reset-list {
      margin-left: 0;
    }
  }
  .views-exposed-widgets {
    margin-bottom: 0;
  }

}

.l-region--right-aside,
.l-region--sidebar-second {
  #mini-panel-newsdesk_menu {
    > .panel-pane {
      @include rem(margin-bottom, $space-and-half);
      .entity-paragraphs-item {
        margin-bottom: 0;
      }
    }
  }

}
