.l-main {
  .group-header {
  //  margin: em($space-half) auto;
    text-align: center;
    @include set_layout($max-width);
    .title,
    .pane-title {
      text-transform: none;
      color: $blue-darkest;
    }
    &.panel-title {
      @include rem(margin, $space-and-half 0);
    }
  }

  //.slider {
  //  > h2,
  //  .title {
  //    @include set_layout($bp-mega);
  //  }
  //}
  .title-center {
    > h2,
    .title {
      text-align: center;
    }
  }
  //.bg-none {
  //  > .group-header {
  //    //position: relative;
  //    //.header-theme {
  //    //  position: absolute;
  //    //  padding-top: em($pad-half);
  //    //  @include breakpoint($tab) {
  //    //    @include background-image(linear-gradient(to right, #fff, $gray-lighter 10%,$gray-lighter 90%, #fff));
  //    //  }
  //    //  z-index: -10;
  //    //  width: 100%;
  //    //  top: em(42px);
  //    //}
  //    .title,
  //    .pane-title{
  //      z-index: 10;
  //      //padding: em($pad) em($pad) 0;
  //      //background: white;
  //      //display: inline-block;
  //      text-transform: none;
  //      margin-bottom: 0;
  //      position: relative;
  //    }
  //  }
  //}
  .entity-paragraphs-item .group-header {
    @include rem(margin, $space-and-half auto $space);
  }
  .section {
    @include rem(margin, 0 0 $space);
    .node-type-book & {
      margin: 0;
    }
  }

  .section-title {
    @include rem(margin-bottom, $space-half);
  }
}

.wrapper-edan-object .group-header {
  @include rem(margin, $space-double 0);
}
//.slider {
//  .title {
//    width: 98%;
//    margin-left: auto;
//    margin-right: auto;
//  }
//}
.l-region--bottom {
  .entity-paragraphs-item > h2,
  h2.title {
    text-align: center;
  }
  .title-left {
    &.entity-paragraphs-item {
     h2.title,
      > h2 {
       text-align: left;
     }
    }
  }
}