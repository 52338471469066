.page-exhibitions {
  // margin-bottom: em($space-and-half);
  .unit-title {
    @extend h4;
  }
  .location-wrapper {
    @include rem(padding, $space 0);
    position: relative;

    &__header {
      @include link-colors(
        $link-color,
        $link-hover,
        $link-hover,
        $link-color,
        $link-color
      );

      .unit-title {
        @include link-colors(
          $link-color,
          $link-hover,
          $link-hover,
          $link-color,
          $link-color
        );
      }
    }

    p {
      margin: 0;
      &.location {
        @include rem(margin-top, $space-half);
      }
    }

    &.form-checkbox {
      margin: 0;
    }
  }
  .lcoation.has-location {
    .icon--floor-plan {
      @include rem(margin-left, $pad);
    }
  }

  .edan-record-event {
    figcaption p {
      font-style: normal;
    }
  }
  .visit-flag-section {
    button {
      background: transparent;
      border: none;
      color: $text-color;
      &:hover,
      &:focus {
        color: $link-hover;
      }
    }
  }
} //end exhibitions-page-wrapper
