@use "sass:math";

.page--colorbox.section-tableau .wrapper--page {
  @include rem(padding-top, $space-and-half);
}
.wrapper--tableau {
  //position: relative;
  @include rem(margin-top, $space-and-half);
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  > iframe {
    margin-bottom: 0;
    margin-top: -27px !important;
    //.page--colorbox & {
    //  margin-top: -60px !important;
    //}
  }
  .first.col-md-7 & {
    width: 380px;
    margin: auto;
  }
}
.paragraphs-item-tableau + .paragraphs-item-tableau.wrapper--colorbox-link {
  margin-top: -24px;
  .btn {
    margin-bottom: 0;
  }
}
.paragraphs-item-tableau.wrapper--colorbox-link + .paragraphs-item-tableau.wrapper--colorbox-link {
  @include rem(margin-top, $space);
}
.colorbox--tableau {
  #cboxClose {
    @include svg-background('close-blue');
  }
  #cboxLoadedContent {
    overflow: hidden;
    > iframe {
      margin-bottom: 0;
     // margin-top: -105px;
    }
  }
}
.paragraphs-item-tableau > .btn {
  margin: 0;
}
.paragraphs-item-tableau-card {
  @include clearfix;
  background-color: $gray-lightest;
  border: 1px solid darken($gray-lightest, 2%);
  >h2,
  >div {
    @include rem(padding, $pad);
  }
  >.last {
    @include rem(padding, $pad 0);
  }
  &:after {
    opacity: .0625;
    -webkit-box-shadow: 0 2px 0 0 #000;
    box-shadow: 0 2px 0 0 #000;

  }
  .tableau-numbers {
    @include rem(margin, 12px 0 $space-and-half);
    a.inner, a.inner:visited {
      color: $text-color
    }
    .small.metatag {
      text-align: right;
      @include rem(margin, $space auto);
    }
    .gamma,
    .beta {
      margin-bottom: 0;
    }
    .beta {
      @include rem(font-size, 32px);
      line-height: math.div(($line-height-base*1.5),32px);
      //font-family: $font;
    }
    .timeframe {
      @include rem(font-size, $font-size-small);
      line-height: $line-height-base/$font-size-small ;
    }
    .column {
      width: 80%;
      margin: auto;
      .item:last-child {
        @include rem(margin-bottom, $space);
      }
    }
    .item {
      position: relative;
      text-align: center;
    }
    span {
      display: block;
    }
    .row .item {
      @include rem(margin-bottom, $space);
    }
  }
  .number {
    font-weight: bold;
    .blue & {
      color: $blue-darkest;
    }
      .orange & {
        color: $orange;
      }
      .burgundy & {
        color: $burgundy;
      }
      .green & {
        color: #31864e;
      }
      .purple & {
        color: $purple;
      }
      .brand-blue & {
        color: $blue-brand ;
      }
  } // end number

  .field--name-field-image-single {
    float: left;
    width: 40%;
    @include rem(margin, 0 16px 8px 0);

    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  .col.first {
    .wrapper--tableau {
      width: 375px;
    }
  }
  @include breakpoint($tab) {

    .tableau-numbers {
      .row {
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 33.333%;
          > .inner {
            @include rem(padding-right, $pad-half);
          }
          &:last-child {
            > .inner {
              padding-right: 0;
            }
          }
        }

        &.items-2,
        &.items-4 {
          .item {
            width: 50%;
          }
        }

      }
    }
    @include rem(padding, $pad);
    > h2,
    > div,
    > .last {
      padding: 0;
    }
    //.wrapper--tableau {
    //  > iframe {
    //    margin-top: -145px;
    //  }
    //}
  }
  @include breakpoint($desk) {
    .wrapper--tableau {
      margin-top: 0;
    }
  }
} //end paragraphs-tableau

@include breakpoint($tab) {
  .node-type-dashboard .pane-node-field-block-content {
    @include set_layout($max-width);
  }
}
