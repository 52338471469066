@use "sass:math";

$space: $line-height-base;
$space-and-half: $space * 1.5;
$space-double: $space * 2;
$space-quad: $space * 4;
$space-half: math.div($space, 2);
$space-third: math.div($space, 3);
$space-quarter: math.div($space, 4);
$pad: 20px;
$pad-and-half: $pad * 1.5;
$pad-double: $pad * 2;
$pad-half: math.div($pad, 2);
$pad-quarter: math.div($pad, 4);

// Breakpoint Settings
$bp-small: 380px;
$bp-med: 600px;
$bp-large: 980px; //1024px;
$bp-wide: 1480px;
$bp-mega: 1920px;

$tab: "all" $bp-med;
$desk: "all" $bp-large;
$wide: "all" $bp-wide;

$max-width: 1280px;

$columns: 12;
$col-width: percentage(math.div(1, $columns));
$col-half-width: percentage(math.div(1, $columns * 2));

//grid settings
$susy: (
  columns: $columns,
  container: 96%,
  global-box-sizing: border-box,
  gutters: 0.5,
  //  debug:              (image:show),
);
