
//#cboxContent {
//  position: static;
//
//}
.colorbox-bar {
  position: fixed;
  top: 0;
  height: 34px;
  width: 100%;
  background: #000;
  opacity: .9;
}
#cboxTitle {
  background: white;
  display: block !important;
  @include rem(padding, $space-half 2%);
  float: none !important;
  position: static;
}

#cboxLoadedContent {
  overflow: auto;
  margin-bottom: 0;
  .cboxIframe {
    header,
    footer {
      display: none;
    }
  }
  -webkit-overflow-scrolling: touch;
  @include breakpoint($desk) {
    overflow: hidden !important;
  }
  .hide {
    display: block !important;
  }
  .paragraphs-item-tableau {
    @include rem(padding-top, $space-and-half);
    background: $header-bg;
    .wrapper--tableau {
      background: white;
      > iframe {
        margin-top: -90px;
      }
    }
  }
}
#cboxClose {
  top: 6px;
  right: 20px;
  bottom: auto;
  height: 22px;
  width: 22px;
  background: {
    repeat: no-repeat;
    position:  50% 50%;
    // size: 70%;
  }
  @include svg-background('close-white');
  &:hover {
    background-position: 50% 50%;
    @include svg-background('close-burgundy');
  }
  @include breakpoint('screen' 480px 979px) {
    @include svg-background('close-burgundy');
  }

}

#cboxWrapper {
  border-radius: 0;
}

#cboxMiddleLeft,
#cboxMiddleRight,
#cboxTopLeft,
#cboxTopCenter,
#cboxTopRight,
#cboxBottomLeft,
#cboxBottomCenter,
#cboxBottomRight {
  width: 0;
  height: 0;
}

.page--colorbox {
  background: white;
  &,
  &.admin-menu {
    margin-top: 0 !important;
  }
  .l-header-preface-wrapper > div,
  .l-header-inner,
  footer {
    display: none;
  }
  //.l-header-preface-wrapper {
  //  height: 36px;
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  opacity: .9;
  //  z-index: 100;
  //}
  //header {
  //  padding-top: 36px;
  //  background: $preface-bg;
  //  position: fixed;
  //  z-index: 99;
  //}
  .wrapper-edan-object {
    margin-top: 36px;
  }
  .l-content-wrapper {
    background: #FFF;
  }
  #admin-menu {
    display: none;
  }
  .wrapper--ogmt .pane-page-header {
    @include rem(margin-top, $space*3);
  }
  .pane-page-header.l-container {
    position: static;
  }
  .wrapper--ogmt,
  .wrapper-edan-object {
    .wrapper--preface-buttons {
      position: fixed;
      height: 36px;
      background: $blue;
      opacity: .9;
      //max-width: 100%;
      //width: 100%;
      top: 0;
      right: 0;
      left: 0;
      z-index: 100;
    }
    .link-wrapper {
      @include set_layout($max-width);
    }
  }

  .back-link {
    float: left;
    width: 40%;
    padding-top: 3px;
    .fa {
      @include rem(padding-right, $pad-half);
    }
    a {
      @include link-colors(white, $text-color, $text-color, white, white);
    }
  }
  .wrapper--si-unit.bg-gray-light {
    display: block;
    background: $header-bg;
    a {
      @include link-colors(white, $link-color-alt, $link-color-alt, white, white);
    }
  }
  .wrapper--addthis {
    padding: 0;
    float: right;
  a {
    svg path {
      fill: #FFF !important;
    }
    &:hover {
      background-color: $burgundy;
      svg path {
        fill: #FFF !important;
      }
    }
  }
    .social-media {
      z-index: 2;
      background: $burgundy;
      a {
        &:hover {
            opacity: .8;
        }
      }
      &.active {
        top: 34px;
      }
    } //end social-media
    .addthis_button_pinterest {
      > div {
        @include svg-background('pinterest-white');

      }
    }
  }
  .share-icon {
    @include svg-background('share-white');
    background-color: transparent;
    &:hover,
    &.active {
      background-color: $burgundy;
      border: 1px solid $gray;
      @include svg-background('share-white');
      border-bottom: 1px solid $burgundy;
    }

  }
  .print {
    @include svg-background('print-white');
    background-color: transparent;
    &:hover,
    &.active {
      background-color: $burgundy;
      border: 1px solid $gray;
      @include svg-background('print-white');
    }

  }
  @include breakpoint('all' 480px) {
    .wrapper--ogmt,
    .wrapper-edan-object {
      .wrapper--preface-buttons .link-wrapper {
        right: 0;
        left: 0;
        padding-right: 45px;
      }
    }

    .back-link {
      @include hide-text;
    }
    .showLink .back-link {
      @include restore-hide;
    }
  }
}
