.node-type-dashboard {
  .menu-block-wrapper {
    @include set_layout(1920px);

  }
  @include breakpoint($desk) {
    .wrapper--description {
      @include span(9);
    }
    .wrapper--dashboard-menu {
      @include span(3 last);
      .menu-block-wrapper > .menu > .active-trail {
        display: block;
        a {
          display: none;
        }
      }
      li {
        display: none;
      }
      li.dashboard-menu {
        &,
        li {
          display: block;
          a,
          li a {
            display: block;
            padding-left: 1em;
          }
        }
      }
    }
    .paragraphs-item-tableau-card {
      clear: both;
    }
  }
}

//.node--dashboard {
//  h1.pane-title {
//    margin: em($space, $font-size-h1) 0;
//  }
//}
//
//.node--dashboard.blue {
//  h2 a {
//    &,
//    &:hover {
//      color: $blue-darkest;
//
//    }
//  }
//}
//
//
//  .node--dashboard.purple {
//    h2 a {
//      &,
//      &:hover {
//        color: $purple;
//      }
//    }
//  }
//  .node--dashboard.burgundy {
//    h2 a {
//      &,
//      &:hover {
//        color: $burgundy;
//      }
//    }
//  }
//  .node--dashboard.orange {
//    h2 a {
//      &,
//      &:hover {
//        color: $orange;
//      }
//    }
//  }
//
//.node--dashboard.brand-blue {
//  h2 a {
//    &,
//    &:hover {
//      color: $blue-brand;
//    }
//  }
//}

