.flexbox .wrapper--search-tabs .edan-search-result .inner {
  padding: 0;
  background-color: $teaser-bg;
  border: 1px solid $teaser-border;

  &:hover,
  &:focus {
    background-color: $teaser-bg-hover;
  }
}

.edan-results-wrapper {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
.edan-search-result {
  padding: 0;
  .title a {
    @include link-colors(
      $text-color,
      $link-hover,
      $link-hover,
      $text-color,
      $text-color
    );
  }
}
.wrapper--search-tabs {
  .wrapper--btn {
    a {
      @include link-colors($text-color, #fff, #fff, $text-color, $text-color);
      &.active {
        color: #fff;
      }
    }
  }
}
.search-results-container .search-results {
  margin: 0;
}
.search-results-container,
.edan-search-results {
  position: relative;
}
.edan-search-result div.field {
  @include rem(margin-bottom, $space-half);
}
.edan-search .object-list .edan-row {
  display: block;
  h3 > a {
    display: grid;
    grid-template-columns: 1fr 2fr;
    @include rem(grid-gap, $pad-half);
  }
}
.search-view.wrapper--btn-toggle,
.search-viewcontrols {
  text-align: right;
  @include rem(margin-bottom, $space-half);

  .wrapper--sisearch & .btn,
  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.search-view.wrapper--btn-toggle {
  @include breakpoint($tab) {
    position: absolute;
    right: 0;
    @include rem(top, $space-half);
  }
}
.search-results {
  // Results collapse button
  .result-collapse__btn {
    display: flex;
    align-items: center;
    background: $white;
    color: $text-color;
    margin: 0;
    width: 100%;

    &:hover,
    &:focus {
      background: $gray-lighter;
      color: $text-color;
    }
    .result-collapse__text {
      @include rem(padding-left, $pad * 0.75);
      margin-right: auto;
      text-align: left;
    }
    .state-icon {
      transition: all 0.25s;
    }
    .museum-icon {
      width: 60px;
    }
    &[aria-expanded="true"] {
      .state-icon {
        transform: rotate(90deg);
      }
    }
  }
}

.edan-results-wrapper.view--list,
.block .view--list {
  .edan-search-result {
    @include rem(margin-bottom, $space-half);

    background: none;
    border: none;
    min-height: auto;

    .collapse:not(.show) {
      display: none;
    }
    button[data-toggle="collapse"] + .node--teaser {
      .b-media-wrapper {
        @include rem(padding-top, $pad * 0.75);
        @include rem(padding-left, $pad * 0.75);
      }
    }
  }
  .node--teaser-long span {
    display: inline;
  }
  //@include breakpoint($tab) {
  //  .node--teaser-long {
  //    .b-media-wrapper {
  //      width: 40%;
  //      max-width: 40%;
  //    }
  //    .b-text-wrapper {
  //      width: 60%;
  //      max-width: 60%;
  //      @include rem(padding-left, 20px);
  //      padding-right: 0;
  //    }
  //  }
  //}
  .tab-pane {
    @include rem(padding, $space $pad-and-half $space 38px);
  }
}
.edan-results-wrapper.view--grid {
  .search-results {
    //display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    //grid-gap: 20px;
    //grid-gap: 2rem;
    //grid-column-gap: 2.2rem;
    @include rem(grid-column-gap, 22px);
    @include rem(grid-row-gap, $space);
    .edan-search-result {
      margin-bottom: 0;
    }
    .result-collapse__btn {
      display: none;
    }
    .node--teaser-long {
      flex-flow: column;
      .inner {
        background: none;
        border: none;
        &:after {
          opacity: 0;
          box-shadow: none;
        }
      }
    }
    .edan-search-result:focus {
      background-color: $gray-lighter;
    }
  }
  .edan-search-result {
    background-color: $teaser-bg;
    border: 1px solid $teaser-border;
    position: relative;
    z-index: 1;
    &:after,
    &:before {
      border-radius: inherit;
      z-index: -1;
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
      -webkit-transition: -webkit-box-shadow 0.7s 10ms
        cubic-bezier(0.19, 1, 0.22, 1);
      transition: -webkit-box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1),
        -webkit-box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:after {
      @include opacity(0.0625);
      -webkit-box-shadow: 0 2px 0 0 #000;
      box-shadow: 0 2px 0 0 #000;
      .bg-gray-light & {
        opacity: 0;
      }
    }
    &:hover {
      background-color: $teaser-bg-hover;
    }
  }
}

.view--list .edan-search-result,
.edan-search-result.grid-item {
  background: none;
  border: none;
}
.wrapper--sisearch {
  margin-top: em($space-half);
  .description span {
    display: block;
    &.btn-text {
      display: inline-block;
    }
    &.text {
      @include rem(padding, $space-quarter 0);
    }
  }
  .page-title {
    font-family: $font-secondary;
    font-weight: 700;
    span {
      font-family: $font;
    }
  }

  .block {
    h2 {
      color: $gray-darker;
    }
    &.block--si_websites {
      .reset-list li {
        @include rem(margin-bottom, $space-half);
      }
    }
  }
  .search-results-container {
    @include rem(padding, $space-half 0);
  }
  .search-block-form {
    @include rem(margin, $space-half 0 $space-half);
  }
  .edan-search-facets {
    float: none;
    margin: 0;
    width: 100%;
  }
  .edan-search-facet-list {
    .root-facet-list {
      margin-top: em($space);
      > li > a {
        @include font-icon($fa-var-angle-right, 30px, 15px);
        &:before {
          transition: transform 0.3s ease;
        }
        display: inline-block;
        &:hover {
          text-decoration: none;
        }
        &.expanded {
          &:before {
            content: unquote('"#{$fa-var-angle-down}"');       }
        }
      }
    }
  }
  .node--teaser {
    a {
      @include link-colors(
        $text-color,
        $link-hover,
        $link-hover,
        $text-color,
        $text-color
      );
    }
  }
  .block--video {
    .node--teaser {
      //.inner {
      //  @include flexbox((
      //          display: flex,
      //          flex-direction: column,
      //         // align-items: center
      //  ));
      //}
      &.placeholder-image {
        .b-media-wrapper {
          background: $gray-dark;
        }
      }
    }
  }

  .block {
    .btn {
      @include rem(margin, $space-half 0);
    }
    @include rem(margin-bottom, $space-and-half);
    p {
      margin: 0;
    }
    .reset-list {
      @include rem(margin-top, $space-half);
    }
  }
  //.explore-buttons {
  //  .btn {
  //    margin-top: em($space, $font-size-h4);
  //  }
  //}
  @include breakpoint($tab) {
    //.layout .col {
    //  width: 100%;
    //  float: none;
    //  margin-right: 0;
    //}
    .view--list {
      .has-media .node--teaser,
      .has-media.node--teaser {
        .inner {
          display: flex;
        }
        .b-media-wrapper {
          max-width: 200px;
          width: 30%;
          //.image-holder {
          //  max-height: 175px;
          //}
        }
        .b-text-wrapper {
          max-width: 70%;
          width: 70%;
          //flex: 1 0 auto;
        }
      }
      .node--teaser-featured .b-text-wrapper {
        @include rem(padding, $space-half 10px $space-half 20px);
      }
      .has-media.event {
        display: flex;
      }
    }
    .grid-wrapper > .content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      grid-column-gap: 20px;
      .edan-search-result {
        display: flex;
      }
    }
    .page-title {
      @include clearfix;
      span {
        float: right;
      }
    }
    .siedu-all-search {
      //.block--articles,
      //.block--search-exhibitions {
      //  .reset-list li {
      //    @include span(6);
      //    &:nth-child(2n) {
      //      @include last;
      //    }
      //    &:nth-child(2n+1) {
      //      clear: both;
      //    }
      //  }
      //}
    }
  }

  @include breakpoint($desk) {
    #search-articles,
    #search-search_all_else {
      .search-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include rem(grid-gap, $pad-double);
        .b-text-wrapper {
          @include rem(padding, 0 0 0 $pad);
        }
      }
      .edan-search-result {
        min-height: auto;
        &.no-media {
          .b-text-wrapper {
            padding: 0;
          }
        }
      }
      .edan-search-result,
      .node--teaser {
        display: flex;
      }
    }
    .expanded {
      #search-articles,
      #search-search_all_else {
        .search-list {
          display: block;
        }
      }
    }
    //.layout .col {
    //  &.col-md-8 {
    //    @include span(8)
    //  }
    //  &.col-md-4 {
    //    @include span(4 last);
    //  }
    //}
    .siedu-all-search {
      .col-1,
      .col-2 {
        h2 {
          margin-top: 0;
          /* The container */
          .container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          /* Hide the browser's default checkbox */
          .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
          }

          /* On mouse-over, add a grey background color */
          .container:hover input ~ .checkmark {
            background-color: #ccc;
          }

          /* When the checkbox is checked, add a blue background */
          .container input:checked ~ .checkmark {
            background-color: #2196f3;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .container .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .col-1 h2 {
        @include rem(margin, $space 0);
      }
      .col-2 {
        h2 {
          margin-top: 0;
        }
        .block {
          border: 1px $gray-lighter solid;
          background: $gray-lightest;
          @include rem(padding, $space $pad);
        }
        //.block--video {
        //  .col {
        //    width: 49.1%;
        //  }
        //}
      }
      .block--articles,
      .block--search-exhibitions {
        .reset-list li {
          float: none;
          width: 100%;
        }
      }

      //.node--teaser {
      //  &.has-media {
      //    .b-media-wrapper {
      //      @include span(1 of 4);
      //    }
      //    .b-text-wrapper {
      //      @include span(3 of 4 last);
      //    }
      //  }
      //}
    }

    .explore-buttons {
      flex-wrap: nowrap;
      //.btn {
      //  width: em(160px, 20px);
      //  //&:nth-child(4n+4) {
      //  //  margin-right: em($pad, 20px);
      //  //}
      //.btn,
      //&.btn-large,
      //&.btn-alt {
      //  @include flex(1 0 14.285%);
      //  width: auto;
      //  max-width: 100%;
      //}
      .btn {
        position: relative;
        //
        //border-bottom: 1px solid$gray-light2;

        &.active {
          border: 1px solid $gray-light2;
          border-bottom: 1px solid white;
          color: $link-hover;
          bottom: -1px;
          background: white;
        }
      }
      //
      //}
    }
    //.siedu-all-search {
    //  .block--video {
    //    .col {
    //      width: 49%;
    //      margin-right: 1%;
    //      margin-bottom: em($space-half);
    //      &:nth-child(2n) {
    //        margin-right: 0;
    //      }
    //    }
    //  }
    //}
  }
}

.page-newsdesk-search {
  .pane-page-header {
    @include rem(margin-bottom, $space-and-half);
  }

  @include breakpoint("screen" 1500px) {
    .expanded {
      #search-articles,
      #search-search_all_else {
        .search-list {
          display: grid;
        }
      }
    }
  }
}
