//.image--circle img {
//  text-align: center;
//  @include clip-path(circle(100px at center));
//  clip-path: url(../images/circle.svg#svgPath);
//}

.max-width,
.page-media img {
  width: 100% !important;
  height: auto !important;
}

img.hide {
  display: none;
}