.slick {
  &.has-arrows {
    @include rem(padding, 0 10px);
  }
}
.slick__arrow {
  button {
    background: transparent;
    border: none;
    padding: 0;
    &:hover {
      box-shadow: none;
      .fill {
        fill: $link-hover
      }
    }
  }
}
.slick-arrow {
  position: absolute !important;
}
.slick-prev {
  left: -15px;
}
.slick-next {
  right: -15px;
}
