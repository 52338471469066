@use "sass:math";
.wrapper--ogmt {
  .l-container {
    position: relative;
  }
  .side-nav {
    display: block;
  }
  .page--colorbox & {
    margin-top: 36px;
  }
  @include rem(padding-bottom, $space);

}
.content-wrapper-ogmt {

  @include breakpoint('screen' em(500px)) {
    .field--type-image {
      max-width: 50%;
    }
  }
  @include breakpoint($tab) {
    .field--type-image {
      max-width: percentage(math.div(1, 3));
    }
  }
  @include breakpoint($desk) {
    .field--type-image {
      max-width: percentage(math.div(1, 4));
    }
  }
  @include breakpoint($wide) {
    .field--type-image {
      max-width: percentage(math.div(1, 6));
    }
  }
}

.content-wrapper-ogmt {
  @include rem(margin-bottom, $space-and-half);

  @include breakpoint($tab) {
    .field--name-field-image {
      float: left;
      @include rem(margin, 0 $pad $space 0);
    }
  }
}
