.l-header {
  position: relative;
  background: $header-bg;

  p {
    margin: 0;
  }

  .node-type-book & {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    @include rem(height, 58px);
  }

  .admin-menu.node-type-book & {
    top: 20px;
  }
  .si-myvisit-link {
    @include link-colors(white, $yellow, $yellow, white, white);
    @include rem(margin-right, $pad-half);
    .fa {
      @include rem(margin-right, 5px);
    }
    &.saved {
      .fa {
        color: $burgundy-light;
      }
    }
  }
}

.l-header-preface-wrapper {
  background: $preface-bg;
  color: white;
  // * {
  //   color: white;
  // }
}

.l-region--header-preface-left {
  // @include rem(padding, $space-half $pad-half);
  // background: $blue-darkest;
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;
  a {
    @include link-colors(white, $yellow, $yellow, white, white);
  }
}
.si-marquee-wrapper {
  .view-content {
    display: block;
    position: relative;
    overflow: hidden;
    width: 400px;
  }
  .views-row {
    display: inline-block;
    width: 450px;
    //  position: absolute;
    //  top: -999em;
    //left: 0;
    //display: block;
    white-space: nowrap; /* keep all text on a single line */
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: scroll-animation 28s linear infinite;
    -webkit-animation: scroll-animation 28s linear infinite;
    animation: scroll-animation 28s linear infinite;
  }
  @include breakpoint($desk) {
    .view-content {
      width: 680px;
    }
    .views-row {
      width: 730px;
    }
  }
}


/* for Firefox */
@-moz-keyframes scroll-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes scroll-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}

@keyframes scroll-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.l-region--header-preface-right {
  &,
  .inner {
    display: flex;
    width: 100%;
  }

  justify-content: flex-end;

  p {
    line-height: 1;
  }

  .block {
    align-items: center;

    &:not(.block--edan-searchtab) {
      display: flex;
    }
  }
  .donate-btn {
    height: 36px;
    line-height: 36px;
    margin: 0 auto;
    @include rem(padding, 0 $pad-half);
    @include button($burgundy-light, white, $green-bright, false, false);
    border: 1px solid $burgundy;
    border-width: 0 1px;
  }

  .inner {
    align-items: center;
  }

  #block-edan-searchtab-edan-searchtab-block-search-form {
    @include rem(margin-right, $pad);
    flex: 1;
  }
}

.l-header-inner {
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
}

.l-region--header {
  position: absolute;
  top: 25%;
  right: 2%;
}

.l-branding {
  @include span(8);
  @include rem(padding, $space 0);
  .node-type-book & {
    @include rem(padding, $space-half 0);
  }
  h1,
  h1 a {
    line-height: 0;
  }
  img {
    width: 100%;
    max-width: 325px !important;
    .node-type-book & {
      max-width: 165px !important;
    }
  }
  .site-name {
    // width: 85%;
    a {
      display: block;
      @include rem(height, 44px);
      //    width: em(240px, $font-size-h1);
      margin-top: 10px;
      background: {
        image: image-url("logos/logo.png");
        repeat: no-repeat;
        position: top left;
        size: contain;
      }

      //@include breakpoint($desk) {
      //    padding-top: 25%;
      //}
      span {
        @extend %hide-text;
      }
    }
  }
} // end l-branding

@include breakpoint($tab) {
  .l-header {
    .si-myvisit-link {
      @include rem(margin-right, $pad);
    }
  }
  .l-header-preface-wrapper .l-container {
    display: flex;
    justify-content: space-between;
  }
  .l-region--header-preface-right {
    width: 100%;
    #block-edan-searchtab-edan-searchtab-block-search-form {
      width: 200px;
    }
  }
  .l-region--header-preface-left,
  .has-preface-left .l-region--header-preface-right,
  .l-region--header-preface-left + .l-region--header-preface-right {
    @include span(6 last);
  }
  .l-region--header-preface-left {
    // background: transparent;
    a {
      @include link-colors(white, $text-color, $text-color, white, white);
    }
  }
  .l-branding {
    @include span(6);
    .site-name a {
      //    width: em(293px, $font-size-h1);
      @include rem(height, 52px);
    }
  }
  .l-region--header-preface-right {
    #block-edan-searchtab-edan-searchtab-block-search-form {
      width: 175px;
    }
    .inner {
      width: unset;
    }
  }

  //.l-header-preface-wrapper {
  //	.l-region--header-preface-left {
  //		@include span(7);
  //	}
  //	.l-region--header-preface-right {
  //		@include pre(1);
  //		@include span(4 last);
  //	}
  //	div.block--search-form{
  //		width: 57%;
  //	}
  //}
}

@include breakpoint($desk) {
  .l-region--header-preface-left {
    width: span(7);
  }
  .l-branding {
    @include span(4);
  }
  .l-region--header-preface-right {
    &,
    .node-type-book & {
      position: static;
      right: auto;
      top: auto;
    }
  }
  .l-region--header-preface-right + .l-region--header-preface-left {
    width: span(5);
  }
}
@include breakpoint("all" 1200px) {
  .l-region--header {
    @include span(8 last);
  }
  .l-region--header {
    &,
    .node-type-book & {
      position: static;
      right: auto;
      top: auto;
    }
  }

}
//@include breakpoint($wide) {
//	.l-header-preface-wrapper {
//		.block--search-form{
//			width: 73%;
//		}
//	}
//}
//.search-form {
//	overflow: hidden;
//	max-height: 0;
//
//	@include breakpoint($tab) {
//		float: right;
//		max-height: none;
//		margin: 0.65em 0.5em 0 0;
//	}
////}
//@include breakpoint('all' 1010px) {
//  .l-region--header-preface-right {
//    .inner > .block{
//      width: 110px;
//    }
//    .inner > #block-edan-searchtab-edan-searchtab-block-search-form{
//      width: 250px;
//    }
//  }
//}
