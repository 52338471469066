// @file
// Styling for floating action button


//Floating Action Button

.region--postscript .page-nav {
  .book--landing & {
    display: none;
  }
  font-weight: $fw-bold;
  @include rem(max-width, 175px);
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 70;
  // opacity: 0;
  opacity: 0;
  @include transition(opacity 400ms, bottom 200ms);
  bottom: -9999px;
  .text {
    color: $gray-dark;
    .show-page-nav-alt & {
      color: white;
    }
    opacity: 0;
    @include rem(font-size, $font-size-small);
  }

  .icon {
    background-color: $burgundy;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: -1px 0 2px 0 rgba(0,0,0,0.12) , 1px 0 2px 0 rgba(0,0,0,0.12) , 0 1px 1px 0 rgba(0,0,0,0.24);
    display: block;
    @include rem(height, $fab-size);
    @include rem(width, $fab-size);
    margin: 0 auto;
    position: relative;
    @include svg-background(arrow-white-up);
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: 60%;
    @include breakpoint($tab) {
      @include rem(height, $fab-size*1.5);
      @include rem(width, $fab-size*1.5);
    }
  }
}

.page-nav {
  &:hover,
  &:active {
    .text {
      opacity: 1;
      //text-shadow: 0 1px 2px $gray-dark;
    }
  }
}

.page-nav {
  .show-page-nav & {
    display: block;
    opacity: 1;
    bottom: 0;
    //
    //animation: fadein 1s ease-out , slideUp .75s cubic-bezier(.14,.57,.66,.96);
    //animation-fill-mode: both;
  }
}

@include breakpoint($wide) {
  .region--postscript .page-nav {
    right: 2%;
  }
}