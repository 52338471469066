.search-wrapper {
    position: relative;
  //  @include clearfix;
    margin: em($space-half) auto;
    @include rem(margin, $space-half auto);
    @include set_layout($bp-mega);
  .field--name-field-tagline {
    @include rem(margin-bottom, $space-half);
  }
    .js & {
      legend {
        width: 98%;
      }
      fieldset.collapsible .fieldset-legend {
        padding-left: 0;
        background: none;
        border-bottom: 1px solid transparent;
        a {
          color: $gray;
          @include rem(padding-bottom, 4px);
          @include font-icon($fa-var-angle-down, 15px, 15px);
          &:before {
            @include rem(left, 5px);
          }
          &:hover {
            color: $text-color;
            text-decoration: none;
            border-bottom-color: $gray;
          }
        }
        .summary {
          display: none;
        }
      }
      fieldset.collapsed .fieldset-legend {
        background: none;
        border-bottom: 1px solid $gray-light;
        a {
          &:before {
            content: unquote('"#{$fa-var-angle-right}"');
          }
        }
      }
    } //end js
    fieldset {
      padding-top: 0;
    }
    //.fieldset-wrapper {
    //  //@include rem(margin-top, $space-half);
    //
    //}
  ol {margin-left: 0}
    .search-results-container {
      @include rem(margin-bottom, $space);
      .page-edan-search-exhibits-current & {
        @include rem(margin-top, $space-and-half);
      }
    }
    .active-facets {
      margin-top: 0;
      .fa, .fa-regular {
        @include rem(margin-right, $pad-half);
        color: $burgundy;
        &:before {
          font-weight: 400;
        }
      }
      li {
        @include rem(margin, $space-half $pad $space-half 0);
      }
    }
    .search-facet-form {
      input[type="submit"],
      .btn-svg {
        //@include button--large;
        width: 100%;
        font-weight: 600;
        letter-spacing: .1px;
        @include rem(font-size, $font-size-base);
        @include rem(margin, $space-half 0);
      }
      .btn-svg {
        display: block;
        text-align: center;
        svg {display: none}
      }
    }
    .filter-title {
      span {
        opacity: 0;
        display: none;
        @include transition(opacity 400ms ease-out);
        &.fa-angle-right {
          display: inline-block;
          opacity: 1;
          transform: rotate(90deg);
          margin-left: 10px;
        }
      }
      @include rem(font-size, $font-size-h4);
      line-height: $line-height-h4;
      @include rem(margin-bottom, $space);
      button {
        @include link-button();
        color: $link-color;
        @include font-icon($fa-var-angle-down, 10px);
        padding-left: 0;
        // Remove SI button arrow.
        &::before {
          content: none;
        }
      }
    }
    //&.expanded .filter-title a {
    //  &:before {
    //    content: "\f106";
    //  }
    //}
    .search-facet-form {
      background: $gray-lightest;
      border: 1px solid $gray-lightest;
      @include transition-property(max-height);
      @include transition-duration(200ms);
      @include rem(margin-bottom, $space);
      .form-type-checkbox {
        @include rem(padding-left, $pad);
        margin-left: 0;
      }
      input[type="checkbox"] {
        @include rem(margin-left, -$pad);
      }
      .js & {
        max-height: 0;
        visibility: hidden;
        display: none;
      }

      legend {
        @extend h4;
        margin-bottom: 0;
        //color: white;

      }
      fieldset {
        @include rem(margin-top, $space);
      }
      .location-wrapper .sub-list {
        @include span(6);
        &.list-1 {
          @include last;
        }
      }
    } //end search-facet-form

    &.expanded {
      .search-facet-form {
        .js & {
          max-height: em(9999px);
          padding: 0 2%;
          @include rem(padding-top, $space);
          @include rem(padding-bottom, $space);
          visibility: visible;
          display: block;
        }
      }
      .filter-title {
        &.fa-angle-right {
          transform: rotate(-90deg);
        }
      }
    }

    .metatag {
      color: $text-color;
      span {
        display: block;
      }
    }

    .form-type-checkbox {
      @include rem(padding, 0 $space-half 0 32px);
    }
    .form-actions {
      margin-bottom: 0;
      @include rem(margin-top, $space-half);
    }
    @include breakpoint($desk) {
      .js & {
        fieldset.collapsed {
          height: auto;
        }
      .search-wrapper {
        @include rem(padding-top, $space);
        .search-facet-form {
          fieldset {
            @include rem(margin-top, 16px);
          }
            //.fieldset-wrapper {
            //  @include rem(margin-top, $space-half);
            //}
          }
          background: $gray-lightest;
        }
        fieldset {
          margin-top: 0;
        }
      }

      &,
      .filter-wrapper {
    //  #exhibit-list .col {
        @include transition-property(all);
        @include transition-duration(200ms);
      }

      .search-form-preface {
        @include transition(margin-left 200ms);
      }
      .filter-title {
       // @include transition(margin-left 200ms);
       // a {
       //   padding-left: 0;
       // }
       // a:before {
       //   content: ""
       // }
        span {
          display: inline-block;
          &.fa-angle-right {
            opacity: 1;
            transform: none;
          }
        }

      }
      &.expanded {
        width: 96%;
        position: relative;
        display: flex;
        justify-content: space-around;
        .no-flexbox & {
          @include clearfix;
        }
        .search-facet-form {
          //width: span(4);
          //position: absolute;
          margin-top: 0;
          top: 0;
        }

        //.js & {
        //  .search-facet-form {
        //    margin-top: 3.5em;
        //
        //  }
        //}
        .search-form-preface {
          position: absolute;
          margin-left: span(4) + gutter();
        }
        .filter-title {
          //a:before {
          //  content: none;
          //}
          span {
            &.fa-angle-right {
              @include opacity(0);
            }
            &.fa-angle-left {
              opacity: 1;
              margin-right: 10px;
            }
          }
        }
        .filter-wrapper {
          width: span(4);
          max-width: span(4);
          margin-right: gutter();
          .no-flexbox & {
            float: left;
          }
        }
        .search-results-container {
          width: span(8);
          max-width: span(8);
          @include rem(margin-top, 21px+$space);
          .page-edan-search-exhibits-current & {
            @include rem(margin-top, 21px+$space-double);
          }
          .no-flexbox & {
            float: right;
          }
        }
        fieldset {
          @include rem(margin-top, $space);
        }
      }

      //.search-list {
      //  @include clearfix;
      //  li {
      //    float: left;
      //    @include span(6);
      //    &.odd {
      //      @include last;
      //    }
      //  }
      //}
    }

    @include breakpoint('screen' 1380px) {
      &.expanded {
        .filter-wrapper {
          width: span(3);
          max-width: span(3);
        }
        .search-results-container {
          width: span(9);
          max-width: span(9);

        }
        //.filter-wrapper {
        //  @include span(3);
        //  max-width: span(3);
        //}
        .search-form-preface {
          margin-left: span(3) + gutter();
        }
        //.search-results-container {
        //  @include span(9 last);
        //  max-width: span(9);
        //}
        //.search-facet-form {
        //  width: span(3);
        //}
				//
        ////.js & {
        ////  .search-facet-form {
        ////    margin-top: 3.5em;
        ////
        ////  }
        ////}
        //.search-form-preface,
        //.search-results-container {
        //  //@include span(8 last);
        //  //max-width: span(8);
        //  margin-left: span(3) + gutter();
        //}

      }

    }
  }
