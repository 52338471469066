//@mixin font-icon($icon, $color: $text-color, $font-size: $font-size-base, $padding: $font-size, $top:0, $pos:0, $after: false) {
// position: relative;
// display: inline-block;
//
// @if $after {
// padding-right: em($padding);
//  &:after {
//    content: $icon;
//    font-family: FontAwesome;
//    font-style: normal;
//    font-weight: normal;
//    text-decoration: inherit;
//    color: $color;
//    position: absolute;
//    font-size: em($font-size);
//   top: em($top);
//   right: em($pos);
//   }
// }
// @else {
// padding-left: em($padding);
//  &:before {
//    content: $icon;
//    font-family: FontAwesome;
//    font-style: normal;
//    font-weight: normal;
//    text-decoration: inherit;
//    color: $color;
//    position: absolute;
//    font-size: em($font-size);
//   top: em($top);
//   left: em($pos);
//   }
// }
//
//}

@mixin font-icon(
  $icon,
  $padding: $font-size-base,
  $font-size: inherit,
  $after: false,
  //$color: inherit,
  //$hover-color: inherit,
  $inline: true
) {
  position: relative;
  @if $inline {
    display: inline-block;
  } @else {
    display: block;
  }

  @if $after {
    &::after {
      //@include rem(top, $top);
      //@include rem(right, $pos);
      //position: absolute;
      content: unquote('"#{ $icon }"');
      font: var(--fa-font-solid);
      text-decoration: inherit;
      @if $font-size != "inherit" {
        @include rem(font-size, $font-size);
      }
      text-decoration: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      @include rem(padding-left, $padding);
      position: relative;
    }

  } @else {
    &::before {
      //@include rem(top, $top);
      //@include rem(left, $pos);
      //position: absolute;
      content: unquote('"#{ $icon }"');
      font:  var(--fa-font-solid);
      @if $font-size != "inherit" {
        @include rem(font-size, $font-size);
      }
      text-decoration: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      @include rem(padding-right, $padding);
      position: relative;
    }
  }
}
