
.node-type-imax-page {
  .panel-col-top,
  .panel-col-bottom {
    .l-container {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .panel-col-top {
    @include rem(margin-bottom, $space-and-half);

    h1 {
      @include rem(margin-bottom, $space);
    }
  }
  .panel-col-bottom {
    .entity-paragraphs-item {
      >.field {
        @include rem(margin, $space-quarter 0 0);

      }
    }
    @include rem(margin-bottom, $space-and-half);
  }

  .view-header {
    text-align: center;
    @include rem(margin, $space 0);

    h2 {
      margin-bottom: 0;
    }
  }

  @include breakpoint($tab) {
    .pane-node-body {
      .pane-title {
        @include rem(margin-bottom, $space);
        padding-right: 160px;
      }
    }
  }
}

.node-type-imax-festival {
  .field--type-image {
    img {
      width: 100% !important;
    }
  }
  @include breakpoint($tab) {
    .movie-list {
      @include clearfix;
      .field--type-image {
        @include rem(width, 150px);
        float: left;
      }
      .movie-info {
        @include rem(margin-left, 170px);
      }
    }

  }
//  .node--movie--teaser-featured {
//    .field--name-field-image {
//      float: left;
//      width: 150px;
//    }
//
//  }
//  .view-showtimes {
//    margin-left: 150px;
//    padding-left: 1em;
//  }
}
