/*------------------------------------*\
    $GLOBAL CLASSES
\*------------------------------------*/

/* Clearfix */
.cf {
  @extend %clearfix;
}

/* Completely remove from the flow and screen readers. */
.is-hidden {
  display: none !important;
  visibility: hidden !important;
}
.bold {
  font-weight: bold;
}
/* Completely remove from the flow but leave available to screen readers. */
.sr-only,
.element-invisible {
  @extend %hide-text;
}

/* Text-Align */
.align-right {
  text-align: right;
}

.align-center,
.center {
  text-align: center;
}

.align-left {
  text-align: left;
}
.inline {
  display: inline-block;
}
/* Display Classes */
.hide {
  display: none !important;
}
.hide-small {
  @media all and (max-width: $bp-med) {
    display: none;
  }
}

.hide-med {
  @include breakpoint($bp-med $bp-large) {
    display: none;
  }
}

.hide-large {
  @media all and (min-width: $bp-large) {
    display: none;
  }
}

//States
.valid {
  color: $valid;
}

.error {
  color: $error;
}

.warning {
  color: $warning;
}

.information {
  color: $information;
}

.font-secondary {
  font-family: $font-secondary;
  font-size: 133%;
  //letter-spacing: -.5px;
  .delta &,
  .beta &,
  .alpha &,
  .epsilon & {
    font-size: 100%;
  }
}

.font-primary {
  font-family: $font;
  //letter-spacing: 0;
}

.clear-l {
  clear: left;
}

.clear-r {
  clear: right;
}

.clear-all {
  clear: both;
}
.border-gray-light {
  border: 1px solid $gray-lighter;
  .content {
    padding: 1em;
  }
}
.bg-blue {
  background: $blue;
  h1,
  h2,* {
    color: white;
  }
  &.border {
    border: 1px solid darken($blue, 4%);
  }
  a {
    @include link-colors(white, $link-color-alt, $link-color-alt, white, white);
  }
}
.bg-gray {
  background: $gray;
}
.bg-gray-dark {
  background: $gray-dark;
}

.bg-gray-darkest {
  background: $gray-darkest;
}
.bg-gray-light {
  background: $gray-lighter;
}
.bg-gray-lighter {
  background: $gray-lightest;
  &.border {
    border: 1px solid darken($gray-lightest, 2%);
  }
}

.bg-green {
  background: $green;
}

.bg-blue-brand {
  background: $blue-brand;
}

.bg-purple {
  background: $purple;

}

.bg-burgundy {
  background: $burgundy;
}
.bg-gray-dark,
.bg-gray-darkest,
.gb-green,
.bg-purple,
.bg-burgundy {
  &,
  * {
    color: white;
  }
}
.image-text {
  background: $blue-darkest;
  border: 1px solid lighten($blue-darkest, 5%);
  color: $blue-brand;
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include rem(font-size, $font-size-h5);
  line-height: $line-height-h5;
  @include rem(padding, $space-half $pad-half);
}
.l-main {
  .bg-burgundy,
  .bg-blue,
  .bg-gray-dark,
  .bg-gray-darkest,
  .bg-purple {
    .group-header .title {
      color: white;
    }
  }
}

.text-blue {
  color: $blue;
}

.text-green {
  color: $green;
}

.text-burgundy {
  color: $burgundy;
}

.sr-only {
  @extend %hide-text;
}

.preface {
  @include rem(font-size, $font-size-small);
  line-height: $line-height-small;
}

.constrain-text {
  max-width: em(1000px);
}

.wrapper--image-text {
  @include rem(margin-bottom, $space);
  .image {
    @include rem(margin-bottom, $space-half);
    //img {
    //  width: 100% !important;
    //  height: auto !important;
    //}
  }
  .text {
    a {
      text-decoration: none;
    }
    h2, h3, h4, h5 {
      @include rem(margin-bottom, $space-half);
    }
  }
}

@include breakpoint($tab) {
  /* Floats */
  .fl-left {
    float: left;
  }
  .fl-right {
   float: right;
  }
  .width-20 {
    max-width: 20%;
  }
  .width-30 {
    max-width: 30%;
  }
  .width-30 {
    max-width: 50%;
  }
  .right-search {
    float: right;
    @include rem(padding, 0 0 $space-half 0);
  }
  .wrapper--image-text {
    display: flex;
    .text {
      @include rem(margin-left, $pad);
    }
    .image {
      max-width: 40%;
      margin: 0;
      //@include rem(margin, 0 $pad 0 0);
      .text {
        @include rem(margin-left, $pad);
      }
      &.width-20 {
        max-width: 20%;
      }
      &.width-30 {
        max-width: 30%;
      }
      &.width-30 {
        max-width: 50%;
      }
    }
    &.right {
      .image {
        order: 1;
        @include rem(margin, 0 0 $space $pad);
      }
    }
  }
}

@include breakpoint('screen' max-width 979px) {
  .bp-desk {
    display: none;
  }
}

@include breakpoint($desk) {
  .bp-desk {
    display: block;
  }
  .bp-mobile {
    display: none;
  }
}
