@include breakpoint("all" 1010px) {
  .paragraphs-item-text-block.constrain-text > .content {
    padding-right: 10%;
  }
  //.node-type-landing-page .pane-node-field-block-content > .paragraphs-item-text-block.slider > .content,
  //.node-type-landing-page .pane-node-field-block-content > .paragraphs-item-text-block.layout-constrain > .content{
  //  padding-right: 0;
  //}
}

.node {
  fieldset.collapsible {
    @include rem(margin-top, $space);
    .fieldset-legend {
      html.js & {
        @include rem(padding-left, 4px);
        background: none;
      }

      .fieldset-title {
        color: $blue-darkest;
        @include rem(font-size, $font-size-h4);
        @include font-icon($fa-var-angle-down, 30px, 15px);
        &:before {
          transition: transform 0.3s ease;
        }
        @include rem(margin-left, $pad-quarter);
      }
    }
    &.collapsed {
      .fieldset-title:before {
        &:before {
          content: unquote('"#{$fa-var-angle-right}"');
        }
      }
    }
    .fieldset-wrapper {
      @include rem(padding-left, 28px);
    }
  }
  .widget-textfield-processed fieldset.collapsible {
    @include rem(margin-top, -4px);
  }
}
.btn.expand {
  display: none;
}
.expand-text {
  .group-main {
    position: relative;
  }
  &.minimize {
    .btn.expand {
      display: block;
      @include rem(margin, $space-and-half auto);
    }
    .group-main {
      overflow: hidden;
      @include rem(height, 450px);
    }
    .gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      background: {
        image: image-url("fade-to-white-vertical-150px.png");
        repeat: repeat-x;
      }
      height: 100px;
      overflow: hidden;
    }
  }
  &.fullHeight {
    .group-main {
      overflow: visible;
      height: auto;
    }
  }
}
