//== Buttons
//

$btn-font-weight:                600;
$btn-font-size:                 $font-size-small;
$btn-default-color:              #FFF;
$btn-bg-color:                 $gray-dark;
$btn-border:             lighten($gray-dark, 10);
$btn-hover:				$blue;
$fab-size: $space-and-half;
