.page-node.node-type-bio {
  .group-wrapper-header {
    @include rem(margin-bottom, $space);

    @include breakpoint($tab) {
      margin-bottom: gutter();
    }
  }
}

.landing--bio-alt {
  .node--teaser-long {
    a.inner {
      h3,
      &:visited h3 {
        color: $link-color;
      }
      &:hover {
        h3 {
          color: $link-hover;
        }
      }
    }
  }
}