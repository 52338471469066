@use "sass:math";

// Font Settings
$font-path: "../fonts/BrandFonts";
//$font                    : 'CooperHewitt', "Helvetica Neue", Helvetica, Arial, sans-serif;
//$font                    : 'BasierCircle', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-secondary: "minion-pro", Georgia, Times, "Times New Roman", serif;
$font-serif: Georgia, Times, "Times New Roman", sans-serif;
$fontAwesomeFamily: var(--fa-style-family-classic);
$fontAwesomeBrandFamily: var(--fa-style-family-classic);
$baseFontSize: 10;
$font-size-base: 16px;
//font sizes are based on perfect fourth scall
$font-size-h1-alt: round($font-size-base * 3.157);
$font-size-h1: round($font-size-base * 3.15);
$font-size-h1-sm: round($font-size-base * 2.8);
$font-size-h2: round($font-size-base * 2.3);
$font-size-h2-sm: round($font-size-base * 2);
$font-size-h3: round($font-size-base * 1.7);
$font-size-h4: round($font-size-base * 1.3);
$font-size-h5: round($font-size-base * 1.2); // minor 3rd
$font-size-tagline: $font-size-h4;
$font-size-h6: $font-size-base; // 16x

$font-size-small: round($font-size-base * 0.8333); // minor 3rd
$font-size-giga: round($font-size-base * 5.063); // 68px
$font-size-mega: round($font-size-base * 3.375);

$baseLineHeight: 1.5;
$line-height-base: $font-size-base * $baseLineHeight;

$line-height-h1-alt: math.div($line-height-base * 2.25, $font-size-h1-alt);
$line-height-h1: math.div($line-height-base * 2.25, $font-size-h1);
$line-height-h1-sm: math.div($line-height-base * 2, $font-size-h1-sm);
$line-height-h2: math.div($line-height-base * 2, $font-size-h2);
$line-height-h3: math.div($line-height-base * 1.5, $font-size-h3);
$line-height-h4: math.div($line-height-base, $font-size-h4);
$line-height-h5: math.div($line-height-base, $font-size-h5);
$line-height-tagline: math.div($line-height-base, $font-size-h5);
$line-height-h6: math.div($line-height-base, $font-size-h6);

$line-height-small: math.div($line-height-base * 0.75, $font-size-small);
$line-height-giga: math.div($line-height-base * 3, $font-size-giga);
$line-height-mega: math.div($line-height-base * 2, $font-size-mega);

// Font Weights
$fw-normal: 400;
$fw-bold: 700;
