ul.sf-menu.sf-accordion,
ul.sf-menu.sf-accordion .sf-hidden {
  display: none !important;
  position: absolute;
}
ul.sf-menu.sf-accordion {
  @include box-shadow(rgba(0, 0, 0, 0.24) 2px 2px 2px 0);
  &,
  ul,
  li {
    float: left;
    width: 100%;
  }
  ul {
    margin: 0;
    padding: 0;
  }

  &.sf-expanded,
  li.sf-expanded > ul {
    left: auto !important;
    position: relative;
    top: auto !important;
    display: block !important;
  }
 li {
   &.menuparent {
     > a {
       @include font-icon($fa-var-plus, 20px, 12px);
       display: block;
     }
     &.sf-expanded {
       background: $mobile-menu;


       > a:before {
         content: unquote('"#{$fa-var-minus}"');
       }
     }
   }
 }
}

.sf-menu,
.sf-menu * {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sf-menu {
  line-height: 1.25;
  z-index: 497;


  a,
  span.nolink {
    display: block;
    position: relative;
  }
  &.sf-horizontal {
    ul {
      left: 0;
      position: absolute;
      top: -99999em;
      @include rem(width, 195px);
      li {
        width: 100%;
      }
    }
    li {
      float: left;
      position: relative;
      z-index: 498;
      &:hover > ul,
      &.sfHover > ul {
        left: 0;
        @include rem(top, 40px);
      }
      li:hover > ul,
      li.sfHover > ul {
        @include rem(left, 195px);

        top: 0;
      }
    }
    .sf-hidden {
      left: -99999em !important;
      top: -99999em !important;
    }
  }
}


.sf-menu.menu,
.sf-menu.menu ul,
.sf-menu.menu li {
  margin: 0;
}

.sf-menu a.sf-with-ul,
.sf-menu span.nolink.sf-with-ul {
  min-width: 1px;
}
.sf-sub-indicator {
  background: url('../images/arrows-ffffff.png') no-repeat -10px -100px;
  display: block;
  height: 10px;
  overflow: hidden;
  position: absolute;
  @include rem(right,12px);
  text-indent: -999em;
  @include rem(top, 17px);
  width: 10px;
}
a > .sf-sub-indicator,
span.nolink > .sf-sub-indicator {
  top: 0.8em;
  background-position: 0 -100px;
}
a:focus > .sf-sub-indicator,
a:hover > .sf-sub-indicator,
a:active > .sf-sub-indicator,
li:hover > a > .sf-sub-indicator,
li.sfHover > a > .sf-sub-indicator,
span.nolink:hover > .sf-sub-indicator,
li:hover > span.nolink > .sf-sub-indicator,
li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: -10px -100px;
}
.sf-menu ul .sf-sub-indicator {
  background-position: -10px 0;
}
.sf-menu ul a > .sf-sub-indicator,
.sf-menu ul span.nolink > .sf-sub-indicator {
  background-position: 0 0;
}
.sf-menu ul a:focus > .sf-sub-indicator,
.sf-menu ul a:hover > .sf-sub-indicator,
.sf-menu ul a:active > .sf-sub-indicator,
.sf-menu ul li:hover > a > .sf-sub-indicator,
.sf-menu ul li.sfHover > a > .sf-sub-indicator,
.sf-menu ul span.nolink:hover > .sf-sub-indicator,
.sf-menu ul li:hover > span.nolink > .sf-sub-indicator,
.sf-menu ul li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: -10px 0;
}
.sf-menu.sf-horizontal.sf-shadow ul,
.sf-menu.sf-vertical.sf-shadow ul,
.sf-menu.sf-navbar.sf-shadow ul ul {
  background: url('../images/shadow.png') no-repeat right bottom;
  padding: 0 8px 9px 0 !important;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.sf-shadow ul.sf-shadow-off {
  background: transparent;
}
.sf-menu.rtl,
.sf-menu.rtl li {
  float: right;
}
.sf-menu.rtl li:hover > ul,
.sf-menu.rtl li.sfHover > ul {
  left: auto;
  right: 0;
}
.sf-menu.rtl li li:hover > ul,
.sf-menu.rtl li li.sfHover > ul {
  left: auto;
  right: 12em;
}
.sf-menu.rtl ul {
  left: auto;
  right: 0;
}
.sf-menu.rtl .sf-sub-indicator {
  left: 0.75em;
  right: auto;
  background: url('../images/arrows-ffffff-rtl.png') no-repeat -10px -100px;
}
.sf-menu.rtl a > .sf-sub-indicator,
.sf-menu.rtl span.nolink > .sf-sub-indicator {
  top: 0.8em;
  background-position: -10px -100px;
}
.sf-menu.rtl a:focus > .sf-sub-indicator,
.sf-menu.rtl a:hover > .sf-sub-indicator,
.sf-menu.rtl a:active > .sf-sub-indicator,
.sf-menu.rtl li:hover > a > .sf-sub-indicator,
.sf-menu.rtl li.sfHover > a > .sf-sub-indicator,
.sf-menu.rtl span.nolink:hover > .sf-sub-indicator,
.sf-menu.rtl li:hover > span.nolink > .sf-sub-indicator,
.sf-menu.rtl li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: 0 -100px;
}
.sf-menu.rtl ul .sf-sub-indicator {
  background-position: 0 0;
}
.sf-menu.rtl ul a > .sf-sub-indicator,
.sf-menu.rtl ul span.nolink > .sf-sub-indicator {
  background-position: -10px 0;
}
.sf-menu.rtl ul a:focus > .sf-sub-indicator,
.sf-menu.rtl ul a:hover > .sf-sub-indicator,
.sf-menu.rtl ul a:active > .sf-sub-indicator,
.sf-menu.rtl ul li:hover > a > .sf-sub-indicator,
.sf-menu.rtl ul li.sfHover > a > .sf-sub-indicator,
.sf-menu.rtl ul span.nolink:hover > .sf-sub-indicator,
.sf-menu.rtl ul li:hover > span.nolink > .sf-sub-indicator,
.sf-menu.rtl ul li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: 0 0;
}
.sf-menu.rtl .sf-hidden,
.sf-menu.rtl.sf-hidden {
  left: auto !important;
  right: -99999em !important;
}
.sf-menu.rtl.sf-horizontal.sf-shadow ul,
.sf-menu.rtl.sf-vertical.sf-shadow ul,
.sf-menu.rtl.sf-navbar.sf-shadow ul ul {
  background-position: bottom left;
  padding: 0 0 9px 8px !important;
  -webkit-border-radius: 8px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius: 8px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomleft: 0;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.sf-vertical.rtl li:hover > ul,
.sf-vertical.rtl li.sfHover > ul {
  left: auto;
  right: 12em;
}
.sf-vertical.rtl .sf-sub-indicator {
  background-position: -10px 0;
}
.sf-vertical.rtl a > .sf-sub-indicator,
.sf-vertical.rtl span.nolink > .sf-sub-indicator {
  background-position: 0 0;
}
.sf-vertical.rtl a:focus > .sf-sub-indicator,
.sf-vertical.rtl a:hover > .sf-sub-indicator,
.sf-vertical.rtl a:active > .sf-sub-indicator,
.sf-vertical.rtl li:hover > a > .sf-sub-indicator,
.sf-vertical.rtl li.sfHover > a > .sf-sub-indicator,
.sf-vertical.rtl span.nolink:hover > .sf-sub-indicator,
.sf-vertical.rtl li:hover > span.nolink > .sf-sub-indicator,
.sf-vertical.rtl li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: -10px 0;
}
.sf-navbar.rtl li li {
  float: right;
}
.sf-navbar.rtl ul .sf-sub-indicator {
  background-position: 0 -100px;
}
.sf-navbar.rtl ul a > .sf-sub-indicator {
  background-position: -10px -100px;
}
.sf-navbar.rtl ul a:focus > .sf-sub-indicator,
.sf-navbar.rtl ul a:hover > .sf-sub-indicator,
.sf-navbar.rtl ul a:active > .sf-sub-indicator,
.sf-navbar.rtl ul li:hover > a > .sf-sub-indicator,
.sf-navbar.rtl ul li.sfHover > a > .sf-sub-indicator,
.sf-navbar.rtl ul span.nolink:hover > .sf-sub-indicator,
.sf-navbar.rtl ul li:hover > span.nolink > .sf-sub-indicator,
.sf-navbar.rtl ul li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: 0 -100px;
}
.sf-navbar.rtl ul ul .sf-sub-indicator {
  background-position: 0 0;
}
.sf-navbar.rtl ul ul a > .sf-sub-indicator,
.sf-navbar.rtl ul ul span.nolink > .sf-sub-indicator {
  background-position: -10px 0;
}
.sf-navbar.rtl ul ul a:focus > .sf-sub-indicator,
.sf-navbar.rtl ul ul a:hover > .sf-sub-indicator,
.sf-navbar.rtl ul ul a:active > .sf-sub-indicator,
.sf-navbar.rtl ul ul li:hover > a > .sf-sub-indicator,
.sf-navbar.rtl ul ul li.sfHover > a > .sf-sub-indicator,
.sf-navbar.rtl ul ul span.nolink:hover > .sf-sub-indicator,
.sf-navbar.rtl ul ul li:hover > span.nolink > .sf-sub-indicator,
.sf-navbar.rtl ul ul li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: 0 0;
}
.sf-navbar.rtl li li:hover > ul,
.sf-navbar.rtl li li.sfHover > ul {
  left: auto;
  right: 0;
}
.sf-navbar.rtl li li li:hover > ul,
.sf-navbar.rtl li li li.sfHover > ul {
  left: auto;
  right: 12em;
}
.sf-navbar.rtl > li > ul {
  background: transparent;
  padding: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
}

