
.l-container,
.layout-constrain > .content,
.layout-constrain .group-header .content,
.layout-constrain .title,
.page-user .l-content-wrapper {
  @include set_layout($max-width);
}
.layout-constrain:not(.paragraphs-item-grid-layout) > .content,
.layout-constrain .group-header .content,
.layout-constrain .title,
.page-user .l-content-wrapper {
  @include clearfix;
}
.l-container .l-container,
.l-container .layout-constrain > .content,
.layout-constrain > .content .title {
  max-width: 100%;
  width: 100%;
  margin: auto;
}

.node-type-imax-page .l-container .layout-constrain > .content {
  @include set_layout($max-width);
}
.page-user .l-content-wrapper {
  @include rem(margin-top, $space-double);
}
.l-container-wide,
.layout-wide > .content,
.layout-wide .title {
  @include set_layout($bp-mega);
}

.layout-constrain .content .content {
  max-width: 100%;
}
.padding,
.padding-extra {
  @include rem(padding, $space 0);
}

.padding-all {
  @include rem(padding, $space $pad);
}

.padding-left {
  @include rem(padding-left, $pad);
}
.padding-right {
  @include rem(padding-right, $pad);
}
.padding-top,
.padding-top-extra {
  @include rem(padding-top, $space);
}

.padding-bottom,
.padding-bottom-extra {
  @include rem(padding-bottom, $space);
}

.spacing,
.spacing-extra {
  @include rem(margin-top, $space !important);
  @include rem(margin-bottom, $space !important);
}

.spacing-half {
  @include rem(margin-top, $space-half !important);
  @include rem(margin-bottom, $space-half !important);
}

.spacing-top,
.spacing-top-extra {
  @include rem(margin-top, $space !important);

}
.spacing-top-half {
  @include rem(margin-top, $space-half !important);
}


.spacing-bottom,
.spacing-bottom-extra {
  @include rem(margin-bottom, $space !important);
}

.spacing-bottom-half {
  @include rem(margin-bottom, $space-half !important);
}

.no-spacing {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

p.no-spacing {
  @include rem(margin-top, $space);
  margin-bottom: 0;

}

//.layout,
//.grid-wrapper {
//  .col {
//    margin-bottom: em($space-and-half);
//    .entity-paragraphs-item & {
//    	margin-bottom: em($space);
//    }
//  }
//}

.cf, .clearfix {
  @extend %clearfix;
}

.ds-3col-stacked-equal,
.ds-2col-fluid,
.ds-2col-stacked-fluid {
  > .group-left,
  > .group-right {
    float: none;
    width: 100%;
  }
}

.ds-3col-stacked-equal  > .group-middle {
  float: none;
  width: 100%;
}


@include breakpoint('all' 768px) {
  .page--theater .panel-col-top > .l-container,
  .two-col-top > .l-container,
  .two-col-bottom > .l-container,
  .ds-2col-stacked-fluid > .content > .inner,
  .panel-3col-stacked .center-wrapper{
    display: flex;
    .no-flexbox & {
      @include clearfix;
    }
  }
  .panel-3col-stacked {
    .center-wrapper {
      .panel-panel {
        @include span(6);
        &.panel-col-first,
        &.panel-col-last {
          @include span(3);
        }
        &.panel-col-last {
          @include last;
        }
      }
    }
  }
  .padding-extra {
    @include rem(padding-top, $space-double);
    @include rem(padding-bottom, $space-double);
  }
  .padding-bottom-extra {
    @include rem(padding-bottom, $space-double);
  }
  .padding-top-top {
    @include rem(padding-top, $space-double);
  }
  .padding-sm {
    @include rem(padding-top, $space);
    @include rem(padding-bottom, $space);
  }

  .padding-extra-sm {
    @include rem(padding-top, $space-double);
    @include rem(padding-bottom, $space-double);
  }

  .spacing-sm {
    //margin: em($space-and-half) 0;
    @include rem(margin-top, $space !important);
    @include rem(margin-bottom, $space !important);

  }
  .spacing-extra,
  .spacing-extra-sm {
    @include rem(margin-top, $space-double !important);
    @include rem(margin-bottom, $space-double !important);
  }
  .spacing-top-extra {
    @include rem(margin-top, $space-double !important);

  }
  .spacing-bottom-extra {
    @include rem(margin-bottom, $space-double !important);
  }

  .no-spacing-sm {
    margin: 0 !important;
  }

  .layout,
  .layout > .content,
  .node > .content {
    @include clearfix;
    > .col-sm-6 {
      &:nth-child(2n) {
        @include last;
      }
      @include span(6);

      .col-sm-8 {
        @include span(4 of 6);
      }
      .col-sm-4 {
        @include span(2 of 6);
      }
    }
    > .col-sm-9 {
      @include span(9);
    }
    > .col-sm-3 {
      @include span(3);
    }
    > .col-sm-8 {
      @include span(8);
    }
    > .col-sm-4 {
      @include span(4);
    }
    > .col-sm-5 {
      @include span(5);
    }
    > .col-sm-7 {
      @include span(7);
    }
    .col.last {
      @include last;
    }
  }
  .panel-2col-stacked .panel-col-middle.has-two-col {
    .panel-panel {
      @include span(6);
      &.panel-col-last {
        @include last;
      }
      .pane-page-header & {
        &,
        &.panel-panel {
          float: none;
          margin: 0;
          width: 100%;
        }
      }
    }

  }
  .ds-2col-fluid,
  .ds-2col-stacked-fluid {
    .group-left,
    .group-right {
      @include span(6);
    }
    .group-right {
      @include last;
    }
    &.paragraphs-item-two-column-right {
      .group-left {
        @include span(9);
      }
      .group-right {
        @include span(3 last);
      }
    }
    &.paragraphs-item-two-column-left {
      .group-left {
        @include span(3);
      }
      .group-right {
        @include span(9 last);
      }
    }
  }

  //vertically align content
  .vertically-centered {
    > div {
      position: relative;
      height: 100%;
      @include set_layout($max-width);
    }
    .inner {
      width: 100%;
      margin: auto;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      .footer-fix .last & {
        top: 30%;
        -webkit-transform: translateY(-30%);
        -moz-transform: translateY(-30%);
        -ms-transform: translateY(-30%);
        -o-transform: translateY(-30%);
        transform: translateY(-30%);

      }

      .book-export & {
        position: relative;
        top: 0;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }
    }
  }
} //end tab

@include breakpoint($desk) {
  .padding-md {
    @include rem(padding-top, $space);
    @include rem(padding-bottom, $space);
  }

  .padding-extra-md {
    @include rem(padding-top, $space-double);
    @include rem(padding-bottom, $space-double);
  }

  .spacing-md {
    @include rem(margin-top, $space);
    @include rem(margin-bottom, $space);

  }

  .spacing-extra-md {
    @include rem(margin-top, $space-double);
    @include rem(margin-bottom, $space-double);

  }

  .no-spacing-md {
    margin: 0;
  }

  .entity-paragraphs-item .layout .col {
    margin-bottom: 0;
  }
  .layout,
  .layout > .content,
  .node > .content {
    > .col-md.last {
      @include last;
    }
    > .col-md-3 {
      @include span(3);
      &.col-sm-6:nth-child(2n) {
        @include span(3);
        &.last {
          @include last;
        }
      }
    }
    > .col-md-4 {
      @include span(4);
    }
    > .col-md-5 {
      @include span(5);
    }
    > .col-md-6 {
      @include span(6);
    }
    > .col-md-7 {
      @include span(7);
    }
    > .col-md-8 {
      @include span(8);
    }
    > .col-md-9 {
      @include span(9);
      .col-md-9 & {
        @include span(6 of 9);
      }
    }
    > .col.last,
    .col.last-md {
      @include last;
    }
    &.four-column {
      .col-fourth {
        margin-right: 0;
      }
    }
  }
  .ds-3col-stacked-equal {
    @include clearfix;

    > .group-left,
    > .group-middle,
    > .group-right {
      @include span(4);
      flex: 1 0 auto;
    }
    > .group-right {
      @include last;
    }
  }
}

@include breakpoint($wide) {
  .layout,
  .layout > .content {
    > .col-wd-3 {
      @include span(3);
    }
    > .col-wd-4 {
      @include span(4);
    }
    > .col.last {
      @include last;
    }
  }
  .l-container,
  .layout-constrain > .content,
  .layout-constrain .group-header .content,
  .page-user .l-content-wrapper {
    width: 100%;
  }
}


@include breakpoint('all' $bp-mega*1.04) {
  .l-container-wide {
    width: 100%;
  }
}
