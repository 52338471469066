.si-tabs {
  @include clearfix;
  @include rem(margin-bottom, $space);
  .r-tabs-nav {
    @extend %reset-list;
  }
  &.r-tabs {
    .r-tabs-nav {
      display: none;
    }
    .r-tabs-accordion-title {
      display: block;
      .no-js & {
        display: none;
      }
    }
    .r-tabs-panel,
    .tab-content-title {
      @include transition(opacity 0 .5s, height .8s);
      opacity: 0;
      height: 0;
      overflow: hidden;
      //display: none;
      .no-js & {
        height: 100%;
      }
    }

    .r-tabs-panel.r-tabs-state-active {
      display: block;
      opacity: 1;
      height: 100%;
    }
    /* Disabled tab */
    .r-tabs-nav .r-tabs-state-disabled {
      @include opacity(0.5);
    }
  } // end r-tabs

  // Accordion Tabs
  .panel-heading,
  .r-tabs-accordion-title {
    font-family: $font;
    line-height: $line-height-h3;
    margin: 0;
    a {
      background: transparent;
      border: 1px solid $gray-lighter;
      margin-top: -1px;
      color: $text-color;
      position: relative;
      font-weight: bold;
      @include rem(font-size, $font-size-h3);
    }
    .alt-tab & {
      background: transparent;
      &:hover {
        background: transparent;
        color: $blue;
        &:before {
          color: $blue;
        }
      }
    }
  }
  .r-tabs-accordion-title.r-tabs-state-active a,
  .panel-heading a {
    background: $blue;
    border: 1px solid $blue;
    color: white;
    .alt-tab & {
      background: transparent;
      color: $blue;
      border: none;
      &:before {
        color: $blue;
      }
    }
  }
  .r-tabs-accordion-title a {
    @include font-icon($fa-var-angle-right, 15px, inherit, false, false);
    @include rem(padding, $space-half $pad*.75 $space-half 40px);
    &:before {
      @include rem(margin-left, -30px);
    }
  }
  .r-tabs-accordion-title.r-tabs-state-active a {
    &:before {
      content: unquote('"#{$fa-var-angle-down}"');;
      @include rem(margin-left, -30px);
    }
  }
  .panel-heading a {
    @include rem(padding, $space-half $pad*.75);
    display: block;
    .text {@include rem(padding-left, 15px);}
    .icon {
      transition: all 0.25s;
      @include font-icon($fa-var-angle-down, 0, inherit, false, true);
    }
  }
  .panel-heading a.collapsed {
    background: $gray-lightest;
    border: 1px solid $gray-lighter;
    display: block;
    color: $text-color;
    .icon {
      transform: rotate(-90deg);
    }
    .alt-tab & {
      border: none;
    }
  }

  &.accordion-tab .panel,
  &.menu-tab .tab-wrapper {
    border-bottom: 1px solid $gray-lighter;
    .alt-tab & {
      border-bottom: none
    }
    > .tab-pane {
      border: 1px solid $gray-lighter;
      border-width: 0 1px;
      @include rem(padding, $space $pad);
      .alt-tab & {
        border: none;
      }
    }
  }
  // End Accordion Tabs
  // Responsive Tabs
  &.responsive-tab {
    .r-tabs-nav {
      // default tab style
      .r-tabs-anchor {
        @include rem(padding, 11px $pad $space-half);
        color: $gray-dark;
        border: 1px solid $gray-light;
        border-width: 1px 0 1px 1px;
        position: relative;
        background: $gray-lighter;
        @include rem(top,2px);
        display: block;
      }
      .last .r-tabs-anchor {
        border-right-width: 1px;
      }
      // Active state tab anchor
      .r-tabs-state-active .r-tabs-anchor {
        background: white;
        @include rem(margin, $space-half $pad);
        .edan-record--location & {
          margin: 0;
        }
        border-bottom: 1px transparent;
        color: $blue;
        @include rem(bottom, -1px);
        font-weight: bold;
      }
    } //end r-tabs-nav
    .tab-wrapper {
      border-bottom: 1px solid $gray-light;
      > .tab-pane.r-tabs-state-active {
        border: 1px solid $gray-light;
        border-width: 1px 1px 0px;
        @include rem(padding, $space $pad);
      }
    }
  }  // End Responsive Tabs

  @include breakpoint($desk) {
    .tab-content-title {
      display: block;
    }
    &.r-tabs {
      .r-tabs-nav {
        display: block;
      }
      .r-tabs-accordion-title {
        display: none;
      }
      &.responsive-tab {
        .r-tabs-tab {
          display: block;
          float: left;
        }
      }
      &.menu-tab {
        > .r-tabs-nav {
          @include span(2);
          @include rem(margin-bottom, $space-half);
          .tab-title {
            @include rem(font-size, $font-size-h4);
          }
        }
        > .tab-wrapper {
          @include span(10 last);
          border: none;
          > .tab-pane {
            border: none;
            padding: 0;
          }
        }
      }
    }
  } //end breakpoint($desk)

} //end .si-tabs

.si-collapsible-block,
.si-tabs {
  .collapse {
    display: none;

    .no-js & {
      display: block;
    }

    &.in,
    &.show {
      display: block;
    }

    // [converter] extracted tr&.in to tr.collapse.in
    // [converter] extracted tbody&.in to tbody.collapse.in
  }

  tr.collapse.in {
    display: table-row;
  }

  tbody.collapse.in {
    display: table-row-group;
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    @include transition-property(height, visibility);
    @include transition-duration(.35s);
    @include transition-timing-function(ease);
  }

  .fade {
    opacity: 0;
    @include transition(opacity .15s linear);

    &.in {
      opacity: 1;
    }
  }
}
div.horizontal-tabs {
  @include rem(margin-top, $space);
  border: none;
  ul.horizontal-tabs-list {
    background: none;
    border: none;
    display: block;
    margin-left: 2px;
    @include clearfix;
    li {
      background: none;
      border: none;
      padding: 0;
      display: block;
      a {
        display: block;
        @include rem(padding, 11px $pad $space-half);
        color: $gray-dark;
        border: 1px solid $gray-dark;
        border-width: 1px 0 1px 1px;
        position: relative;
        background: $gray;
        @include rem(top, 1px);
        strong {
          font-weight: 400;
        }
      }
      &:last-child {
        a {
          border-right-width: 1px;
        }
      }
      &.selected {
        background: none;
        padding: 0;
        a {
          background: white;
          @include rem(padding, $space-half $pad);
          border-bottom: 1px transparent;
          @include rem(top, 1px);
          @include rem(bottom, -1px);
          &,
          strong {
            font-weight: bold;
            color: $blue;
          }
        }
      }
    }
  }
  fieldset.horizontal-tabs-pane {
    @include rem(padding, $space $pad);
    border: 1px solid $gray-dark;
    border-width: 1px 1px 0;
  }
  border-bottom: 1px solid $gray-dark;
}
