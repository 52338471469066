.paragraphs-item-ticket-info {
  @include rem(margin, 8px 0 0);
  .btn,
  .field--name-field-start-date,
  .field--name-field-end-date {
    @include rem(margin-top, 8px);
    @include rem(margin-bottom, 8px);
  }
  .owl-item & {
    .field--name-field-start-date,
    .field--name-field-end-date {
      margin-top: 0;
      margin-bottom: 0;
    }
    .btn.orange {
      @include rem(margin-bottom, 8px);
    }
  }
}