.side-nav {
  .sf-menu li {
    float: none;
  }
  p {
    margin: 0;
  }
  .pane-title {
    @include rem(font-size, $font-size-base);
    line-height: $baseLineHeight;
    font-family: $font;
    color: $text-color;
    font-weight: normal;
    margin: 0;
    border: 1px solid darken($gray-lightest, 5%);
    border-width: 1px 0 0;
    a {
      background-color: $gray-lightest;
      display: block;
      @include rem(padding, $space-half $pad);
      @include transition(background-color 0.5s);
      @include link-colors(
        $text-color,
        $burgundy,
        $burgundy,
        $text-color,
        $text-color
      );

      &.active {
        background-color: darken($gray-lightest, 5%);
        color: $burgundy;
        font-weight: 600;
      }
      &:hover {
        background-color: darken($gray-lightest, 5%);
        text-decoration: none;
        font-weight: 400;
      }
    }
  }
  .newsdesk & {
    .sf-depth-1 {
      display: none;
    }
  }
  .sf-accordion-toggle a {
    width: 100%;
    @include button;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    margin: 0;
    &.sf-expanded {
      background: $blue;
      border-bottom: none;
    }
  }
  .menu {
    @extend %reset-list;
    width: 100%;
    background-color: $gray-lightest;
    a {
      background-color: $gray-lightest;
      display: block;
      @include rem(padding, $space-half $pad);

      @include transition(background-color 0.5s);
      @include link-colors(
        $text-color,
        $burgundy,
        $burgundy,
        $text-color,
        $text-color
      );

      &.active {
        background-color: darken($gray-lightest, 5%);
        color: $burgundy;
        font-weight: 600;
      }
      &.active-trail {
        .newsdesk & {
          background-color: darken($gray-lightest, 5%);
          color: $burgundy;
          font-weight: 600;
        }
      }
      &:hover {
        background-color: darken($gray-lightest, 5%);
        text-decoration: none;
        font-weight: 400;
      }
    }

    ul {
      @include transition(opacity 0.2 ease-in, max-height 0.4s ease-in);
      max-height: 0;
      overflow: hidden;
      opacity: 0;
    }
    li {
      li {
        a {
          @include rem(padding-left, $pad-double);
          @include rem(padding-top, $space-half);
        }
        li {
          a {
            @include rem(padding-left, 60px);
          }
        }
        li {
          a {
            @include rem(padding-left, 70px);
          }
        }
      }
      &.active {
        a {
          background-color: darken($gray-lightest, 5%);
        }
        > a {
          color: $burgundy;
          font-weight: 400;
        }
      }
      &.hasChildren {
        > a {
          @include font-icon($fa-var-angle-down, 5px, inherit, true);
        }

        &.sf-depth-1 {
          > a:after {
            content: none;
          }
        }
        &.sfHover {
          @include transition(all 0.5s ease-in);
          > a {
            display: block;
            &:after {
              content: unquote('"#{$fa-var-angle-up}"');
            }
          }
          &.active-trail {
            > a:after {
              content: "";
            }
          }
        }
      } //end hasChildren
      &.sfHover,
      &.active-trail,
      &.sfHover.active-trail {
        > ul {
          max-height: 9999px;
          opacity: 1;
        }
      }
      &.sfHover {
        a {
          background-color: darken($gray-lightest, 5%);
        }
      }
    } // end li
    > li {
      border-top: 1px solid darken($gray-lightest, 5%);
    }
  }

  display: none;
  .ogmt-wrapper &,
  .newsdesk & {
    display: block;
  }

  @include breakpoint($desk) {
    display: block;
    @include clearfix;

    border: 1px solid darken($gray-lightest, 5%);
    border-width: 0 1px 1px;
    .sf-hidden {
      position: static;
      left: auto !important;
      top: auto !important;
    }
  }
} //end side-nav
