.inline-list {
	> li {
		display: inline-block;
	}
  &.reset {
    @extend %reset-list;
  }
}

.flex-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.no-flexbox & {
		li {
			display: inline-block;
		}
	}
}
.float-list {
	@include clearfix;
	@extend %reset-list;
	li {
		display: block;
		float: left;
	}
}
/* Social List */
.social-list {
	li {
		margin: 0 0.4rem $space 0;
	}
	
	a {
		font-size: 1.6em;
	}
}

ul.menu {
	@extend %reset-list;
	.leaf, .expanded, .collapsed {
		list-style-image: none;
		list-style-type: none;
	}
}
/* Headline List */
.headline-list {
	margin-bottom: $space;

	&.flush {
		margin: 0;
	}

	h4 {
		font-weight: normal;
	}

	li {
		padding: $pad-quarter 0;
		border-top: 1px solid $gray-lightest;
	}
}

/* Post List */
.post-list {
	li {
		margin-bottom: $space;
	}
}

/* Bullet List */
.bullet-list {
	list-style: square;
	margin: 0 0 1em 1.2em;
	line-height: 1.3;

	li {
		margin-bottom: $space;
	}
}

/* Text List */
.reset-list {
	@extend %reset-list;
	@include rem(margin,0 0 $space);

	li {
		@include rem(margin-bottom, $space);
		&.spacing-half {
			@include rem(margin-bottom, $space-half);
		}
		&.spacing-bottom {
			@include rem(margin-bottom, $space-and-half);
		}
		&.spacing {
			@include rem(margin, $space-and-half 0);
		}
		&.no-spacing {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

.split-list {
	@extend %clearfix;
	.sub-list {
		@extend %reset-list;
	}
	&.col-2 {
		.sub-list {
			@include span(6);
			&.list-1 {
				@include last;
			}
		}
	}
}
