/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/

@use "sass:math";

$button-size : 24px;
$transition: .3s; // increase this to see the transformations in slow-motion
$color: white;

@mixin line {
  background: $color;
  border-radius: 2px;
  display: block;
  height: 3px;
  transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
  width: $button-size;
}


.lines-button {
  background: $mobile-nav-btn;
  border: 0;
  position: relative;
  // box-shadow: -1px 0 2px 0 rgba(0,0,0,0.24) , 1px 0 2px 0 rgba(0,0,0,0.24) , 0 1px 1px 0 rgba(0,0,0,0.34);
  cursor: pointer;
  display: inline-block;
  @include rem(padding, $space*.75 $pad-half);
  //padding: 20px 12px 26px;
  user-select: none;
  border-radius: 0;
 // @include rem(margin, 18px 0);
  margin: 0;
  &:hover {
    opacity: 1;
  }

  &:active {
    background: rgba(0,0,0,.1);
    transition: none;
  }
  .text {
    //bottom: 1px;
    //display: block;
    //@include rem(font-size,$font-size-small);
    //left: 0;
    //position: absolute;
    //text-align: center;
    //width: 100%;
    @extend %hide-text
  }
}


.lines {
  //create middle line
  @include line;
  position: relative;
  top: -.13em;

  /*create the upper and lower lines as pseudo-elements of the middle line*/
  &:before, &:after {

    @include line;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 1.71429px center;
  }
  &:before { top: math.div($button-size,3); }
  &:after { top: -(math.div($button-size,3)); }
}

.sf-expanded.lines-button {
  //.text {
  //  opacity: 0;
  //}
  .lines {
    background: transparent;
    top: .4em;
    &:before, &:after {
      transform-origin: 50% 50%;
      top: -4px;
    }
    &:before {
      transform: rotate3d(0,0,1,45deg);
    }
    &:after {
      transform: rotate3d(0,0,1,-45deg);
    }
  }
}

.read-more {
  @include font-icon($fa-var-angle-double-right, 10px, 14px, true);
  &:after {
    top: 10%;
  }
}
.btn {
  @include button;
  @include rem(margin-bottom, $space);
  letter-spacing: .1px;
  @include rem(padding, $space/6 $pad * 1.25);
  color: white;
  &:focus,
  &:hover,
  &:active {
    color: white;
  }
  &:visited {
    color: $white;
  }
  &.active {
    background-color: $blue;
    color: $white;
  }
  .l-region--left-aside,
  .l-region--right-aside {
    margin-bottom: 0;
  }
  &.blue {
    @include button($blue, white, $gray-dark);
    &.active {
      background-color: #21292f;
    }
    .wrapper--explore & {
      border: none;
    }
    //  @include font-smoothing;
  }
  &.orange {
    @include button($orange, white, darken($orange, 10%));
    // @include font-smoothing;
    &.inactive {
      //background-color: darken($orange, 10%);
      @include opacity(.3);
      color: $gray-lightest;
    }
    .wrapper--explore & {
      border: none;
    }
  }
  &.purple {
    @include button($purple, white, darken($purple, 10%));
    // @include font-smoothing;
    .wrapper--explore & {
      border: none;
    }
  }
  &.plum {
    @include button($plum, white, darken($plum, 10%));
    .wrapper--explore & {
      border: none;
    }
  }
  &.green {
    @include button($green, white, darken($green, 10%));
    //@include font-smoothing;
    .wrapper--explore & {
      border: none;
    }
  }
  &.burgundy {
    @include button($burgundy, white, darken($burgundy, 10%));
    // @include font-smoothing;
    .wrapper--explore & {
      border: none;
    }
  }
  &.blue-brand,
  &.brand-blue {
    @include button($blue-brand, white, darken($blue-brand, 10%));
    // @include font-smoothing;
    .wrapper--explore & {
      border: none;
    }
  }

  &.blue-dark {
    @include button($blue-darkest, white, $gray-dark, 10%);
    // @include font-smoothing;
    .wrapper--explore & {
      border: none;
    }
  }
  &.btn-alt {
    @include button($gray-lighter, $gray-dark, darken($gray-lighter, 10%));
    //@include link-colors($text-color, $text-color, $text-color, $text-color, $text-color);
    &.active {
      background-color: $burgundy;
      color: white;
      border: 1px solid desaturate(lighten($burgundy, 15%), 5%);
    }
    //&.active {
    //  @include button($burgundy, white, darken($burgundy, 10%));
    //}
    &:hover,
    &.active {
      .sf-menu & {
        box-shadow: none;
      }
    }
    .sf-menu & {
      @include rem(padding, 8px $pad-half);
      border: none;
      background: none;
      box-shadow: none;
    }
  }
  &.btn-large {
    //font-size: em($font-size-h4, $btn-font-size);
    @include button--large;
    &.h3 {
      @include rem(font-size, $font-size-h3);
      line-height: $line-height-h3;
      //color: $header-color;
    }
  }
  //end btn
  //&.blue,
  //&.green,
  //&.purple,
  //&.orange {
  //	@include link-colors(white, white, white, white, white);
  //}
  &.read-more {
    @include font-icon($fa-var-angle-double-right, 10px, 14px, true);
  }
}

.btn-alt.fullsize {
  @include svg-background('fullscreen');
  background-repeat: no-repeat;
  @include rem(padding, $space $pad);
}

.btn + .btn {
  @include rem(margin-left, 15px);
  [dir="rtl"] & {
    margin-left: 0;
    @include rem(margin-right, 15px);
  }
}

.btn-text {
  @include font-icon($fa-var-angle-double-right, 10px,14px, true);
  &:after {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
  &:hover {
    color: $link-hover;
  }
  &.btn {
    @include rem(padding-right, $space);
    &:after {
      top: .3em;
      right: .25em;
      &:hover {
        color: white;
      }
    }
    &:hover {
      color: white;
    }
    &.btn-alt {
      &:hover {

      }
    }
  }
  .view-footer {
    @include rem(font-size, $font-size-small);
  }
}

.wrapper--btn-toggle {
 .btn,
 .btn+.btn {
   margin: 0;
 }
  .btn {
    border-width: 1px 1px 1px 0;
    @include rem(padding, $space/6 $pad-half);
    &,
    &:focus,
    &:hover,
    &:active,
    &.active {
      @include box-shadow(none);
    }
    &.first {
      border-left-width: 1px;
    }
  }
.label {
  @extend h5;
  display: inline-block;
  @include rem(margin-right, $pad-half);
  }
}
button.btn-svg {
  background: transparent;
  border: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .label {
    @extend %hide-text;
  }
  .fill {
    fill: $link-color;
  }
  &:hover,
  &:focus {
    background: transparent;
    .fill {
      fill: $burgundy;
    }
  }
}
.wrapper--btn {
  //background: $gray-lighter;
  //@include background-image(linear-gradient(to right, #fff, $gray-lighter 10%,$gray-lighter 90%, #fff));
  @include rem(margin, $space 0);
  text-align: center;
  ul {
    @extend %reset-list;
  }
  li {
    @include rem(margin,0 0 $space);
  }
  .btn {
    &,
    &.btn-large,
    &.btn-alt {
      margin: 0;
      @include transition(background-color .2s)
    }
    width: 100%;
  }
  .btn.btn-large {
    @include rem(font-size, $font-size-h4);
    span {
      @include rem(font-size, $font-size-small);
    }
    &.active,
    &:hover {
      // margin: em($space, $font-size-h4) auto;
      @include rem(font-size, $font-size-h4);
    }
    &:focus {
      outline: .25rem solid #c55776;
      outline-offset: 0;
      z-index: 2;
    }
  }
}
.mejs__button > button {
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px;
  background: transparent url('../images/mejs-controls.svg');
  z-index: 1000;
  &:hover {
    background-color: transparent;
  }
}

.mejs__mute > button {
  background-position: -60px 0;
}

.mejs__unmute > button {
  background-position: -40px 0;
}
.mejs__container-fullscreen > button {
  background-position: -80px 0;
}
@include breakpoint($tab) {
  .wrapper--btn {
    .l-container-wide {
      width: 98%;
    }
    ul {
      width: 100%;
      //&:before,
      //&:after{
      //  flex-basis: 0;
      //  order: 1;
      //}
      //display: flex;
      //flex-wrap: wrap;
      //justify-content: flex-start;
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax(145px, 1fr) );
      &.tabs-6 {
        grid-template-columns: repeat(6, 1fr);
      }
      &.tabs-7 {
        grid-template-columns: repeat(7, 1fr);
      }
      &.tabs-8 {
        grid-template-columns: repeat(8, 1fr);
      }
      &.tabs-9 {
        grid-template-columns: repeat(9, 1fr);
      }
      &.tabs-10 {
        grid-template-columns: repeat(10, 1fr);
      }
      li {
        display: flex;
      }

    }
    .no-flexbox & {
      @include clearfix;
    }
    li {
      .no-flexbox & {
        float: left;
      }
      //flex: 1 0 auto;
      //width: 49.5%;
      //max-width: 49.5%;
      //display: flex;
      a {
        display: block;
        width: 100%;
        max-width: 100%;
        float: none;
        &.btn,
        &.btn-large,
        &.btn-alt {
          &,
          &:hover,
          &.active,
          &.active:hover {
            @include box-shadow(none);
          }
        }
        .wrapper--sisearch & {
          line-height: $line-height-h4;
          span {
            @include rem(font-size, 20px);
            display: block;
          }
        }
      }

    }
  }

}

@include breakpoint($desk) {
  .wrapper--btn {
    .wrapper--sisearch &,
    .exhibitions-page-wrapper &,
    .node-type-dashboard & {
      border-bottom: 1px solid $gray-light2;
      //.btn-alt {
      //  &.active {
      //    @include link-colors($link-hover, white, white, $link-hover, $link-hover)
      //  }
      //}
    }
    .views-field-title {
      width: 100%;
    }
    li {
      margin-bottom: 0;
    }
  }

  //.exhibitions-page-wrapper .wrapper--btn li,
  //.wrapper--btn .four li,
  //.wrapper--btn .tabs-4 li,
  //.wrapper--btn.four li{
  //  width: 24.95%;
  //  max-width: 24.95%;
  //}
  //.wrapper--btn .seven li,
  //.wrapper--btn .tabs-7 li,
  //.wrapper--btn.seven li{
  //  width: 14.25%;
  //  max-width: 14.25%;
  //}
  //.wrapper--btn .five li,
  //.wrapper--btn .tabs-5 li,
  //.wrapper--btn.five li{
  //  width: 19.95%;
  //  max-width: 19.95%;
  //}
  //.wrapper--btn .six li,
  //.wrapper--btn.six li {
  //  width: 16.25%;
  //  max-width: 16.25%;
  //}
  .wrapper--sisearch,
  .exhibitions-page-wrapper,
  .node-type-dashboard {
    .wrapper--btn
    li {
      a {
        border-right-width: 0;
      }
      li:first-child {
        a {
          border-right-width: 1px;
        }
      }
      .btn.btn-alt.active {
        border: 1px solid $gray-light2;
        border-bottom: 1px solid white;
        color: $link-hover;
        bottom: -1px;
        background: white;
        &:hover {
          background-color: $burgundy;
          color: white;
        }
      }
    }

  }
}


