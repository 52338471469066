.edan-record--location {
  .location-details {
    @include rem(margin, 0 auto $space-and-half);
  }
  .location-info-wrapper {
    img {
      width: 100%;
    }
    border: 1px solid $gray-lighter;
    p:last-child {
      margin: 0;
    }
  }
  .location-address {
    @include rem(font-size, $font-size-h4);

    a {
      @include rem(font-size, $font-size-base);
    }
    p {
      margin: 0;
    }
  }
  .location-info {
    @include rem(padding, $space-half $pad $space-and-half);
    position: relative;
  }

  // Accordion Tabs
  .si-tabs {
    //.panel-heading,
    //.r-tabs-accordion-title {
    //  a,
    //  a.collapsed {
    //    background: none;
    //    border: none;
    //    // border-bottom: 1px solid d$blue-darkest;
    //    display: block;
    //    color: $text-color;
    //    position: relative;
    //    //font-weight: normal;
    //    font-family: $font;
    //    //font-size: em($font-size-h2, $font-size-h3);
    //    @include rem(font-size, $font-size-h2);
    //    @include rem(padding, $space-half 15px 0);
    //    @include rem(padding-left, 40px);

    //  }
    //}

    .panel-heading {
      a,
      a.collapsed {
        padding: 0;
        background: transparent;
        border: none;
        color: $header-color;
      }
      a.expanded {
        background: none;
        border: none;
        //border-bottom: 1px solid $blue-darkest;
        color: $text-color;
        &:before {
          //content: $fa-var-minus;
          color: $text-color;
        }
      }
    }

    &.accordion-tab {
      margin-bottom: 0;
      .panel {
        border-bottom: none;
        @include rem(margin-bottom, $space);

        &:last-child {
          margin-bottom: 0;
        }
        > .tab-pane {
          border: none;
          @include rem(padding, $space $pad-and-half $space 38px);
        }
        //.views-field-title {
        //  display: none;
        //}
      }
    }

    &.responsive-tab {
      .l-container-wide {
        width: 100%;
      }
      .r-tabs-anchor {
        @include transition(color 300ms ease-out);
        font-size: em($font-size-h3);
        @include rem(font-size, $font-size-h3);
        @include rem(padding, 16px 10px 16px 30px);
        @include rem(padding-left, 40px);
        // @include rem(padding-left, 18px);
        background-color: $gray-lighter;
        border: 1px solid $gray-lighter;
        border-bottom: 1px solid white;
        position: relative;
        z-index: 1;
        &:focus,
        &:hover {
          background-color: darken($gray-lighter, 10%);
          border-color: desaturate(
            lighten(darken($gray-lighter, 10%), 25%),
            5%
          );
          text-decoration: none;
        }
        text-align: left;
      }
      .r-tabs-accordion-title:last-child {
        .r-tabs-anchor {
          border-bottom-width: 1px;
        }
      }
      .si-tabs-nav,
      .tab-wrapper {
        width: $bp-mega;
        max-width: 96%;
        margin: 0 auto;
      }
      .tab-wrapper {
        @include clearfix;
        border-bottom: none;
        > .tab-pane {
          border-width: 1px;
          border-color: $gray-lighter;
        }
      }
      .r-tabs-accordion-title.r-tabs-state-active a {
        &,
        &:before {
          color: $burgundy;
        }
      }
    }

    @include breakpoint($desk) {
      &.responsive-tab {
        .wrapper--tabs-nav {
          border-bottom: 1px solid $gray-light2;
        }
        .r-tabs-nav {
          // default tab style
          @include clearfix;
          @include transition(
            color 300ms ease-out,
            background-color 300ms ease-out,
            border 300ms ease-out
          );

          &:before,
          &:after {
            flex-basis: 0;
            order: 1;
          }
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          .r-tabs-tab {
            float: left;
            flex: 1 0 auto;
            width: 32.3%;
            max-width: 32.3%;
            margin-right: 1px;
            display: flex;
          }

          .r-tabs-anchor {
            cursor: pointer;
            font-family: $font;
            text-align: center;
            width: 100%;
            font-weight: bold;
            &:focus,
            &:hover,
            &:active {
              text-decoration: none;
              border: 1px solid $gray-light2;
              border-bottom: 1px solid white;
              color: $link-hover;
              bottom: -1px;
              background: white;
            }
            top: auto;
            display: block;
          }
          .last .r-tabs-anchor {
            border-right-width: 1px;
          }
          // Active state tab anchor
          .r-tabs-state-active .r-tabs-anchor {
            background: white;
            color: $link-hover;
            border: 1px solid $gray-light2;
            border-bottom: 1px solid white;
          }
        } //end r-tabs-nav
        .tab-wrapper {
          border-bottom: none;
          > .tab-pane {
            border: none;
            @include rem(margin, $space 0);
          }
        }
      }
    } //end breakpoint($desk)
    @include breakpoint("all" 1780px) {
      .si-tabs-nav,
      .tab-wrapper {
        max-width: 100%;
      }
    }
  } //end si-tabs
} //end edan-record-location
