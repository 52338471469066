.highlight-box {
  border: 1px solid $gray-lighter;
  background: $gray-lightest;
  @include rem(padding, $space-half $pad);
  .title {
    color: $orange;
  }
}

.pane-block-31 {
  .btn {
    width: 100%;
  }
}

#myVisitApp {
  padding: 0;
  .node--teaser {
    display: flex;
    margin-bottom: .5rem;
  }
  .node--teaser .b-media-wrapper {
    flex: 0 0 30%;
    max-width: 30%;
    padding-right: 2rem;
  }
  .node--teaser .b-media-wrapper img {
    max-width: 100%;
  }
  .node--teaser .b-text-wrapper {
    flex-grow: 1;
    padding: 0 2rem;
    text-align: left;

    span {
      display: inline-block;
    }
  }
}
