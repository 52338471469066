.ui-datepicker {
  width: 27em;
  max-width: 100%;
  @include rem(margin, 0 0 $space);
  background: $gray-lightest;
  .ui-datepicker-header {
    position: relative;
    text-align: center;
    @include rem(font-size, $font-size-h5);
    font-weight: bold;
    .ui-icon {
      @extend %hide-text;
    }
    .ui-datepicker-prev,
    .ui-datepicker-next {
      cursor: pointer;
      -moz-background-size: 1em 1.5em;
      -webkit-background-size: 1em 1.5em;
      background-size: 1em 1.5em;
      background-position: center;
      background-repeat: no-repeat;
      width: 1.5em;
      height: 1em;
      top: .75em;
      position: absolute;
    }
    .ui-datepicker-prev {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
      left: 0;
    }
    .ui-datepicker-next {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
      right: 0;
    }
  }
  th,
  td {
    border: none;
    text-align: center;
  }
  td {
    padding: .1em;
    span,
    a {
      padding: .2em .5em;
    }
    a {
      font-weight: bold;
    }
  }
  th {
    border-bottom: 1px solid $gray-lighter;
  }
  //td.active {
  //  background: none;
  .ui-datepicker-current-day .ui-state-default,
    .ui-state-active {
      color: white;
      background: $link-hover;
      border-radius: 50%;
    //}
  }

  //.ui-state-default {
  //  color: $link-hover;
  //  font-weight: 700;
  //}
  th {
    background: none;
  }
  table {
    border: none;
  }
}
.ui-datepicker-calendar {
  @include rem(margin-top, $space);
}
.view-filters {
  position: relative;
  .loading {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.date-display-single .date-display-wrapper,
.date-display-time-range{
    display: block;
}
