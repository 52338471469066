.l-main .wrapper-edan-object.bg-none .group-header .header-theme {
  z-index: 0;
}

.edan-record-event {
  figcaption p {
    font-style: italic;
  }
}
.edan-viewer-container {
  .card-header .btn,
  .btn {
    @include rem(margin-bottom, $space-half);
    text-align: center;
  }
  .btn + .btn {
    margin-left: 0;
  }
}
.media-container {
  .btn {
    color: $link-color;
  }
  .media-inner {
    .btn {
      @include link-colors(white, white, white, white, white);
      &:focus,
      &:hover,
      &:active {
        background: $blue;
      }
    }
  }
}

.edan-record {
  .back-link {
    @include rem(margin-bottom, $space-half);
  }
  .tabWrapper .tabHeading button.btn-tab {
    &,
    &.collapsed {
      span {
        position: relative;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $btn-hover;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform 333ms ease-in-out;
      }
      &:focus,
      &:hover {
        background-color: transparent;
        color: #fff;
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }
  .collapse-header {
    @include rem(margin-bottom, $space);
  }

  .edan-search-result {
    border: none;
    background: none;
  }
  &.no-usage {
    .media-metadata {
      display: none;
    }
  }
  &.edan-object {
    h1 {
      @include rem(font-size, $font-size-h1-sm);
      line-height: $line-height-h1-sm;
    }
    h2 {
      @include rem(font-size, $font-size-h2-sm);
    }
  }
  figure {
    display: block !important;
  }
  .media-container.view-zoomable figure {
    display: none !important;
  }
  .has-media {
    .record-section {
      flex-direction: column;
    }
    //.wrapper--addthis {
    //  margin: 0;
    //  padding-bottom: 0;
    //}
    .edan-media-wrapper {
      order: 1;
    }
    .recordDetails {
      order: 2;
    }
  }
  .accordion .inner {
    width: 100%;
  }
  &:not(.l-container) {
    .pageHeader {
      position: relative;
      padding: 0 3%;
      @include rem(padding-top, $space);
    }
  }
  &.l-container {
   @include rem(padding, $space 0);
  }
  .caption {
    font-style: italic;
    max-width: 98%;
    @include rem(font-size, $font-size-small);
    line-height: $line-height-small;
  }
  .media-slider {
    @include rem(margin-top, 0);
    border-top: 1px solid $gray-lighter;
    @include rem(padding-top, $space-half);
  }
  .media-metadata {
    border: none;
    @include rem(padding-bottom, $space-half);
    @include rem(margin, $space-half auto 0);
  }

  .btn-tab {
    @include rem(padding, $space-half $pad-half);
    text-align: center;
    color: $text-color;
    &:hover,
    &:focus {
      color: $link-hover;
    }
  }
  .recordDetails {
    .btn {
      @extend .btn;
    }
  }
  &.ead_component,
  &.ead_collection {
    .related {
      .edan-row {
        &,
        span {
          display: block;
          width: 100%;
        }
      }
      .edan-search-result {
        background-color: $gray-lightest;
        border: 1px solid darken($gray-lightest, 2%);
        position: relative;
        &:after, &:before {
          border-radius: inherit;
          z-index: -1;
          position: absolute;
          content: "";
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: 0;
          padding: 0;
          -webkit-transition: -webkit-box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1);
          transition: -webkit-box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1);
          transition: box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1);
          transition: box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1), -webkit-box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1)
        }
        &:after {
          @include opacity(.0625);
          -webkit-box-shadow: 0 2px 0 0 #000;
          box-shadow: 0 2px 0 0 #000;
        }
      }
      .title {
        @extend h4;
        margin-bottom: 0;
      }
      .edan-record-info {
        @include rem(padding, $space-half $pad*.75);
      }
    }
  }
  .has-text-media {
    max-width: 100%;
    width: 100%;
    .media-container .btn {
      @include button;
      @include rem(margin-bottom, $space-half);
      text-transform: capitalize;
      letter-spacing: .1px;
      @include rem(padding, $space/6 $pad * 1.25);
      color: white;
      &:focus,
      &:hover,
      &:active {
        color: white;
      }
    }
    .media-container.type--electronic_resource .btn {
      background: transparent;
    }
  }
  .related .image {
    margin-bottom: 0 !important;
  }
}

.popover {
  @include rem(font-size, $font-size-small);
  line-height: $line-height-small;
}
@media (min-width: 600px) {
  .edan-record .has-text-media {
    .pageHeader {
      grid-column: span 2;
      @include rem(margin-bottom, $space-and-half);
    }
    .edan-media-wrapper {
      order: 1;
      padding: 0;
      margin: 0;
    }
    .recordDetails {
      order: 2;
      height: auto;
    }
  }
}

@include breakpoint('all' 768px) {
  .edan-record {
    .has-media {

      .record-section {
      display: flex;
      }
    }
    &.l-container {
      .has-media {
        .recordDetails {
          padding: 0;
        }
      }
			.recordDetails {
				overflow-y: visible;
			}
    }
    &.has-usage .has-media {
      height: 100%;
      &.node--teaser {
        height: auto;
      }
      .record-section {
        display: block;
      }
      .pageHeader,
      .edan-media-wrapper {
        width: 65%;
      }
      .pageHeader {
        padding: 0 3%;
        @include rem(padding-top, $space);
      }
      .recordDetails {
        @include rem(padding, $space $pad);
        position: absolute;
        right: 0;
        width: 35%;
        top: 0;
        height: 100%;
        border: 1px solid desaturate(darken($gray-lighter, 5%), 5%);
      }

      //.record-section {
      //  grid-template-columns: 62% 1fr;
      //}
      &.has-text-media {
        @include set_layout($max-width);
        .pageHeader,
        .edan-media-wrapper,
        .recordDetails {
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
        .recordDetails {
          position: static;
          border: none;
          overflow-y: auto;
        }
      }
    }
  }
}
@include breakpoint('all' 860px) {
  .edan-record {
    .type--images .media-inner {
      padding-bottom: 56.25%;
      position: relative;
      img {
        max-height: 100% !important;
        width: auto !important;
      }
       .image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
         display: flex;
         justify-content: center;
         align-items: center;
      }
    }
    &.ead_component,
    &.ead_collection {
      .related {
        .search-results {
          display: grid;
          grid-template-columns: repeat(auto-fit, 260px);
          @include rem(grid-column-gap, $pad);
          @include rem(grid-row-gap, $space);
        }
        .edan-search-result {
          display: flex;
          margin-bottom: 0;
          &.no-media {
            align-items: center;
          }
        }

        img {
          max-height: 300px;
          width: auto;
          margin: auto;
          &.b-error {
            height: 250px !important;
          }
        }
      }
    }
    &.l-container {
      @include rem(padding, $space-double 0);
    }

    .has-media  .recordDetails {
      background: #fff;
    }

    .tabWrapper {
      .tabHeading button.btn {
        border: none;
      }
      .tab-pane {
        border: none;
      }
    }
    .has-text-media {
      .tabWrapper {
        .tabHeading button.btn {
          border: 1px solid desaturate(darken($gray-lighter, 5%), 5%);
          border-bottom-color: #fff;
        }
        .tab-pane {
          border: 1px solid desaturate(darken($gray-lighter, 5%), 5%);
          border-top-color: #fff;
          @include rem(padding, $space $pad);
        }
      }
    }
    &:not(.l-container),
    &.has-text-media {
      .page-title {
        padding-right: 150px;
      }
      .wrapper--preface-buttons {
        position: absolute;
        right: 3%;
        @include rem(top, $space-and-half);
        padding: 0;
        &.has-backlink {
          @include rem(top, 56px);
        }
      }
    }
    &.has-text-media {
      .wrapper--preface-buttons {
        right: 0;
      }
    }
    &.no-usage .has-media {
      .record-section {
        grid-template-columns: 55% 1fr;
        display: grid;
      }
      .pageHeader {
        grid-column: span 2;
        @include rem(margin-bottom, $space-and-half);
      }
      .edan-media-wrapper {
         order: 1;
        padding: 0;
        margin: 0;
      }
      .recordDetails {
        order: 2;
        height: auto;
      }
    }

    &.has-related .has-media {
      .recordDetails {
        padding: 0;
      }
    }
  }
}

@include breakpoint('all' 1680px) {
  .edan-record {
    &.has-usage .has-media {
      .pageHeader {
        padding-left: 10%;
      }
      .media-metadata {
        padding-left: 7%;
      }
    }
  }
  .link-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
  }
}

