.vocabulary-theater {
	&.view-mode-teaser {
		h2, p {
			margin: 0;
			text-align: center;
		}
		p {
			font-weight: normal;
		}
		h2 {
			// color: $blue-darkest;
			font-family: $font-secondary;
			@include rem(font-size, $font-size-h2);
			line-height: $line-height-h2;
		}
		text-align: center;
		p {
			display: inline-block;
			&:after {
				content: '|';
				padding-left: 6px;
			}
			&:last-child:after {
				content: none;
				padding-left: 0;
			}

		}
		@include rem(margin-bottom, $space-half);
		.short-name {
			display: none;
		}
	}
}

.page--theater {
	.l-main {
		.panel-col-top {
			background: darken($gray-darkest, 5%);
			* {
				color: white;
			}
			.l-container {
				max-width: 100%;
				width: 100%;
			}
			color: white;
			img {
				width: 100%;
			}
			//.col--left.col-sm-6 {
			//  max-width: 100%;
			//  width: 100%;
			//}
			//.col--right.col-sm-6 {
			//  @include set_layout($max-width);
			//}
			p {
				margin: 0;
			}
			h1 {
				@include rem(margin-bottom, $space-half);
				font-weight: 500;
			}
			.field--name-field-tagline {
				@include rem(margin-top, $space-half);
			}
			#mini-panel-page_header_theater, #mini-panel-page_header_category {
				margin: $space-half 2%;
				margin: rem($space-half) 2%;
			}
		}

		.panel-col-bottom {
			@extend %clearfix;
			.panel-col-first .pane-taxonomy-term-field-text-block {
				background-color: $gray-lightest;
				border: 1px solid darken($gray-lightest, 2%);
			}

			iframe {
				margin-bottom: 0;
			}
			.pane-taxonomy-term-field-text-block {
				background: $gray-lightest;
				@include rem(margin-bottom, $space-and-half);
				p {
					margin: 0;
				}
			}
			.pane-views-panes {
				.views-row {
					@include rem(margin-bottom, $space-and-half);
				}
			}
		}

		.panel-col-middle,
		.panel-col-bottom {
			@include rem(margin, $space-and-half auto);
		}

	}
	// end l-main

	.view-theaters {
		.view-title {
			text-align: center;
		}
		.ui-datepicker {
			margin: 0 auto em($space-and-half);
		}
		p {
			@include rem(margin-bottom, $space-quarter);
		}
		.paragraphs-item-ticket-info {
			color: $orange;
			@include rem(margin, $space-and-half 0);
			//.start-date {
			//  color: $text-color;
			//}
		}

		position: relative;
		@include rem(margin, $space-and-half auto);
		.views-row {
			@include clearfix;
			.link {
				@include rem(margin-bottom, $space-half);
			}
			.pane-theaters-panel-picker-theater {
				@include rem(margin-top, $space-and-half);
			}
		}

		.views-field-field-image,
		.field--name-field-image {
			a {
				display: block;
				line-height: 0;
			}
			img {
				border: 1px solid darken($gray-lightest, 2%);
				width: 100% !important;
			}
			float: left;
			width: 100px
		}
		.movie-info {
			h3 {
				@include rem(margin-bottom, $space-half);
			}
			margin-left: 100px;
			@include rem(padding-left, 15px);
		}
		.btn + .btn {
			@include rem(margin-left, $pad/4);
		}
	}
	&.node-type-imax-festival {
		.view-theaters {
			.paragraphs-item-ticket-info {
				color: $text-color;
				font-weight: bold;
				@include rem(margin, $space-half 0 0);
				//.start-date {
				//  color: $text-color;
				//}
			}
		}
	}
	.view-showtimes {
		@include rem(margin-top, $space);
		&.wrapper--showtimes {
			@include rem(margin-top, $space-half);
		}
	}

	@include breakpoint('all' 560px) {
		&.node-type-imax-festival {
			.view-theaters {
				.wrapper--showtimes,
				.paragraphs-item-ticket-info {
					margin-left: 100px;
					@include rem(padding-left, 15px);
				}

			}
		}
	}

	@include breakpoint($tab) {
		.l-main {
			.panel-col-top {
				.l-container {
					margin-right: 2%;
				}
				.media,
				#mini-panel-page_header_theater, #mini-panel-page_header_category {
					@include span(6);
				}
				#mini-panel-page_header_theater, #mini-panel-page_header_category {
					@include last;
					@include rem(margin, $space 0);
				}
				.pane-taxonomy-term-field-image-single {
					overflow: hidden;
					img {
						height: 100%;
						width: auto;
						max-width: 1000% !important;
					}
				}
			}
			.panel-col-bottom {
				.pane-taxonomy-term-field-text-block {
					@include clearfix;
					.l-container > div {
						@include span(6);
						&:last-child {
							@include last;
						}
					}
				}
			}
		}
		//end l-main

		.view-theaters {
			.views-field-field-image,
			.field--name-field-image {
				width: 150px;
			}
			.movie-info,
			.wrapper--showtimes {
				margin-left: 150px;
				@include rem(padding-left, $pad);
			}

			.btn + .btn {
				@include rem(margin-left, $pad-half);
			}
		}
		&.node-type-imax-festival {
			.view-theaters {
				.paragraphs-item-ticket-info,
				.wrapper--showtimes {
					margin-left: 150px;
					@include rem(padding-left, $pad);
				}
			}
		}

		.pane-term-description {
			@include rem(padding-top, $space-double);
		}
	}
	@include breakpoint($desk) {
		.l-main {
			.panel-col-top {
				.l-container {
					@include set_layout($max-width);
				}
			}
			.panel-col-middle {
				.panel-title {
					text-align: left;
					@include pre(4);
				}
			}
		}

		.view-display-id-panel_picker__theater {
			> .view-filters {
				@include span(4);
			}
			> .view-content {
				@include span(8 last);
			}
			//.views-field-field-image {
			//  @include span(3 of 9);
			//}
		}

	}
	@include breakpoint($wide) {
		.view-theaters {
			.views-row {
				.views-field-field-image {
					@include span(2 of 9);
				}
				.movie-info {
					// width: span(7 of 9 last);
					@include span(7 of 9 last);
					margin-left: 0;
					padding-left: 0;
				}
			}
		}

		&.node-type-imax-festival {
			.view-theaters {
				.views-field-field-image,
				.field--name-field-image {
					width: 150px;
					//margin-right: gutter();
				}
				.wrapper--showtimes,
				.movie-info,
				.paragraphs-item-ticket-info {
					//@include span(10);
					@include rem(paddding-left, $pad);
					margin-right: 0;
				}
			}
		}

	}

}
