.pane-menu {
  border: 1px solid darken($gray-lightest, 2%);
  border-width: 1px 0;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  select {
    border: none;
    text-align: center;
    width: 100%;
  }
  > .menu {
    > li {
      text-align: center;
      padding: 10px;
      @include rem(padding-top, $space-half);
      @include rem(padding-bottom, $space-half);
      width: 100%;
      border-bottom: 1px solid darken($gray-lightest, 2%);
      &:first-child,
      &:last-child {
        border-bottom: none;
      }
      ul {
        width: 195px;
        border: 1px solid darken($gray-lightest, 2%);
        background: #fff;
      }
      > ul {
        width: 100.5%;
        border-top: 1px solid #fff;
      }

      &:hover > ul,
      &.sfHover > ul {
        left: 0;
        @include rem(top, 43px);
      }
      li {
        @include rem(padding-top, $space-quarter);
        @include rem(padding-bottom, $space-quarter);
        float: none;
        &:hover > ul,
        &.sfHover > ul {
          left: 100%;
        }
      }
    }
  }

  @include breakpoint($tab) {
    width: 100%;
    > .menu {
      display: flex;
      justify-content: space-around;

      @include set_layout($max-width);
      > li {
        border-bottom: none;
        border-right: 1px solid darken($gray-lightest, 2%);
        &:last-child {
          border-right: none;
        }
      }
    }
  }


}
