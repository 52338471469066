.front {
  .paragraphs-item-four-column {
    .b-text-wrapper {
      text-align: center;
    }
    h3 {
      text-align: center;
    }
    .col-second h3 a {
      @include link-colors($green, $link-hover, $link-hover, $green, $green);
      color: $green;
    }
    .col-third h3 a {
      @include link-colors($purple, $link-hover, $link-hover, $purple, $purple);
    }
    .col-fourth h3 a {
      @include link-colors($burgundy, $link-hover, $link-hover, $burgundy, $burgundy);
    }
    img {
      display: block;
      @include rem(margin, 0 auto $space-half);

    }
  }
  .view-slideshows {
    border-bottom: $gray-darker solid .5em;
    background: $gray-darker;
  }

@include breakpoint($tab) {
  #paragraphs-item-824 img {
    display: none;
  }
}

  @include breakpoint($desk) {
    #paragraphs-item-824 img {
      display: block;
    }
  }
}