@use "sass:math";

.image-holder {
  width: 100%;
  height: 75vw;
  max-height: 450px;
  background-position: 50% 10%;
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 0;
  display: block;
}
.exhibitions-wrapper {
  @include clearfix;
  position: relative;
  .metatag {
    color: $text-color;
    span {
      display: block;
    }
  }
  .edan-search-result {
    background: $gray-lighter;
  }

  .grid-wrapper > .content .col {
    margin-right: 0;
  }

  .card--exhibit {
    &:hover {
      text-decoration: none;
    }
  }
  .card {
    span {
      display: block;
    }
  }
  .no-media {
    .image-holder {
      height: 0;
    }
  }
  .name {
    color: $text-color;
    @include rem(margin-bottom, $space-half);
  }
  a:hover {
    border-bottom: none;
  }
  //.location {
  //  @include rem(font-size, $font-size-small)
  //}
  .col {
    @include rem(margin-bottom, $space);
    &:hover,
    a:focus,
    &:focus-within {
      background-color: $gray-lighter;
      color: $text-color;
    }
    a:focus-visible {
      outline-offset: -2px;
    }
  }
  &.swiper-container {
    .col {
      margin-bottom: 0;
    }
    .name {
      color: white;
    }
  }
  .b-text-wrapper {
    @include rem(padding, 18px);

    p {
      @include rem(margin, $space-half 0 0);

      &.metatag {
        @include rem(margin-top, 20px);
      }
    }
  }
  .callout {
    display: block;
    color: $red;
    font-weight: 600;
  }
  .date {
    font-weight: 500;
  }
  .summary {
    color: lighten($text-color, 10%);
  }
  .swiper-slide {
    flex: 1 0 auto;
    margin-bottom: 0;
    background: $gray-dark;
    height: auto;
    border: 1px solid lighten($gray-dark, 5%);

    h1,
    h2,
    h3,
    h4,
    h5,
    h5,
    p,
    span {
      color: white;
    }
    //.card--exhibit:hover * {
    //  color: $text-color;
    //}
  }
  .entity-paragraphs-item & {
    position: static;
    &.exhibtions-page {
      position: relative;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    height: $space;
  }
  &.block {
    .wrapper--btn-toggle {
      display: none;
    }
  }
  .wrapper--btn-toggle {
    @include rem(margin-bottom, $space);
  }

  @include breakpoint("all" 540px) {
    .image-holder,
    .no-media .image-holder {
      max-height: 410px;
      height: 410px;
      height: 53vw;
    }
    #exhibit-list {
      //border: 1px solid $gray-lighter;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      //  grid-gap: 1px;
      //  background: $gray-lighter;
      .col {
        grid-column: span 6;
        //border: 1px solid $gray-lighter;
        //border-width: 0 1px 0 1px;
      }
    }
    &,
    &.expanded {
      .view--list #exhibit-list {
        display: block;
        .has-media.event {
          display: flex;
          @include rem(margin-bottom, $space);
        }
        .b-media-wrapper {
          max-width: 200px;
          width: 30%;
          //.image-holder {
          //  max-height: 175px;
          //}
          .card--exhibit {
            height: 100%;
            min-height: 200px;
            max-height: 300px;
          }
          .image-holder {
            height: 100% !important;
            max-height: 100% !important;
            background-size: cover;
          }
        }
        .b-text-wrapper {
          max-width: 70%;
          width: 70%;
          //flex: 1 0 auto;
        }
        .card--exhibit {
          display: block;
        }
        .b-media-wrapper
        .col {
          &:first-child,
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            .image-holder,
            &.no-media .image-holder {
              max-height: 200px;
              height: 0;
              padding-top: 20%;
            }
          }
        }
      }
    }
    &.swiper-container {
      .col {
        &:before,
        &:after {
          flex-basis: 0;
          order: 1;
        }
        flex: 1 0 auto;
        .no-flexbox & {
          float: left;
        }
        &.swiper-slide {
          border-color: lighten($gray-dark, 5%);
          border-width: 1px 0 1px 1px;
        }
        width: 50%;
        max-width: 50%;
      }
    }
    .col {
      margin-bottom: 0;
    }
    .edan-search-result {
      background: $gray-lightest;
      margin-bottom: 0;
      &.swiper-slide {
        background-color: $gray-dark;
        &:hover {
          background-color: darken($gray-dark, 5%);
        }
        a:hover {
          border-bottom: none;
        }
      }
    }

    .card--exhibit {
      display: flex;
    }
    .b-text-wrapper {
      flex: 1 0 auto;
      word-wrap: break-word;
    }

    .no-media {
      background: $gray-dark;
      //@include flexbox((
      //  display: flex,
      //  align-items: center
      //));
      * {
        color: white;
      }
      &:hover {
        * {
          color: $text-color;
        }
      }
    }
    // end col
    .card--exhibit {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: space-between;
      width: 100%;
    }
  }

  @include breakpoint("all" 768px) {
    .image-holder,
    .no-media .image-holder {
      max-height: 348px;
      height: 348px;
      height: 35.5vw;
    }
    //.no-media {
    //  @include rem(padding-top, 290px);
    //}
    &.swiper-container {
      .col {
        width: percentage(math.div(1,3));
        max-width: percentage(math.div(1,3));
      }
    }

    #exhibit-list {
      .col {
        grid-column: span 4;
        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          grid-column: span 6;
          .image-holder,
          &.no-media .image-holder {
            max-height: 500px;
            height: 500px;
            height: 50vw;
          }
        }
      }
    }

    .wrapper--btn-toggle {
      text-align: right;
    }
  }

  @include breakpoint($desk) {
    .swiper-wrapper {
      .col {
        .image-holder,
        &.no-media .image-holder {
          max-height: 374px;
          height: 374px;
          height: 35.5vw;
        }
        //&.no-media {
        //  @include rem(padding-top, 290px);
        //}
      }
    }
    #exhibit-list {
      .col {
        grid-column: span 3;
      }
    }
    &.expanded {
      max-width: $bp-mega;
      #exhibit-list {
        .col {
          &,
          &:first-child,
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            grid-column: span 6;
            .image-holder,
            &.no-media .image-holder {
              max-height: 508px;
              height: 508px;
              height: 35vw;
            }
          }
        }
      }
    }

    .wrapper--btn-toggle {
      position: absolute;
      @include rem(top, $space-half);
      right: 0;
    }
  }
  @include breakpoint($wide) {
    &.swiper-container {
      .col {
        width: percentage(math.div(1,4));
        max-width: percentage(math.div(1,4));
      }
    }
  }
  @include breakpoint("screen" 1480px) {
    //figure {
    //  height: 320px;
    //}
    .swiper-wrapper,
    .search-wrapper {
      .image-holder,
      .no-media .image-holder {
        max-height: 360px;
        height: 360px;
        height: 20vw;
      }
      //.no-media {
      //  padding-top: 360px;
      //  padding-top: 40vw;
      //}
    }

    #exhibit-list {
      .col {
        &,
        &:nth-child(3),
        &:nth-child(4) {
          grid-column: span 2;
          .image-holder,
          &.no-media .image-holder {
            max-height: 348px;
            height: 348px;
            height: 35.5vw;
          }
        }
        &:first-child,
        &:nth-child(2) {
          grid-column: span 4;
          .image-holder,
          &.no-media .image-holder {
            max-height: 525px;
            height: 525px;
            height: 33vw;
          }
        }
      }
    }
    &.expanded {
      #exhibit-list {
        .col {
          grid-column: span 3;
          .image-holder,
          &.no-media .image-holder {
            max-height: 388px;
            height: 388px;
            height: 21vw;
          }
          &:first-child,
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            grid-column: span 6;
            .image-holder,
            &.no-media .image-holder {
              max-height: 640px;
              height: 640px;
              height: 32vw;
            }
          }
        }
      }
    }
  }
  @include breakpoint("screen" 1920px) {
    &.expanded {
      max-width: 2000px;
      #exhibit-list {
        .col {
          &,
          &:nth-child(3),
          &:nth-child(4) {
            grid-column: span 2;
            .image-holder,
            &.no-media .image-holder {
              max-height: 265px;
              height: 273px;
              height: 12vw;
            }
            //&.no-media {
            //  @include rem(padding-top, 210px);
            //}
          }

          &:first-child,
          &:nth-child(2) {
            grid-column: span 4;
            .image-holder,
            &.no-media .image-holder {
              max-height: 508px;
              height: 508px;
              height: 20vw;
            }
            //&.no-media {
            //  @include rem(padding-top, 390px);
            //}
          }
        }
      }
    }
  }
}

//end exhibitions-wrapper
.entity-paragraphs-item.slider h2 {
  @include rem(margin-bottom, $space-and-half);
}

.exhbition-block {
  position: relative;
}

.tab-wrapper {
  .metatag span.location {
    display: none;
  }
}
