/*------------------------------------*\
    $MESSAGING
\*------------------------------------*/

// Alerts
.alert {
	text-align: center;
	padding: $pad;
	//margin-bottom: $space-half;
	//border: 1px solid $gray;
	//background: $gray-light;
	width: 100%;
	overflow: hidden;
	@include rem(height,$line-height-base + $pad);
}

//.alert-error {
//	color: $error;
//	border-color: $error;
//	background: #ffbebe;
//}