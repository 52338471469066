/**
 * $BASE-TYPOGRAPHY
 */

/*
$fontbase = 16
$line-height-base = 26
*/


html {
	font-size: 62.5%;
	@include breakpoint('screen' 1280px) {
	//	font-size: 74.21875%; //increase font to 19px
		font-size: 70.3125%; // increase font to 18px
	}

}
body {
	background: $footer-bg;
	font-family: $font;
	@include rem(font-size, $font-size-base);
	-webkit-text-size-adjust: 100%;
	color: $text-color;
	line-height: $baseLineHeight;

	&.page--colorbox,
	&.book-export,
	&.cke_contents_ltr,
	&.ckeditor-edit-wrapper {
		background: white;
	}
	&.cke_contents_ltr,
	&.ckeditor-edit-wrapper {
		@include rem(padding, 10px)
	}
	&.cke_editable,
	&.cke_ltr {
		background: #fff;
		@include rem(padding, $space-half $pad-half);
	}
}
//body::-webkit-scrollbar {
//	width: 1em;
//}
//
//body::-webkit-scrollbar-track {
//	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//}
//
//body::-webkit-scrollbar-thumb {
//	background-color: darkgrey;
//	outline: 1px solid slategrey;
//}
.l-content-wrapper {
	background: #FFF;
	overflow-wrap: break-word;
	word-wrap: break-word;
	.node-type-book & {
		padding-top: 65px;
		background: $gray-darker;
		position: relative;
	}
	.node-type-promotion & {
		background: none;
	}
}

.l-main {
	.node-type-book & {
		background: #FFF;
	}
	> .layout {
		position: relative;
	}
	.page-media {
		@include rem(margin-bottom, $space-and-half);
	}
	//.l-region--main-content {
	//	margin-bottom: em($space-and-half);
	//}
}

.wrapper--page {
	width: 100%;
	overflow: hidden;
}

%hide-text {
	@include hide-text;
}

%clearfix {
	@include clearfix();
}

%reset-list {
	@include reset-list();
}
