.snapshot-logo {
  img {width: 75px !important; height: 75px !important;}
  @include rem(margin-bottom, $space-half);
  @include rem(font-size, $font-size-h2);
  @include link-colors($header-color, $link-hover, $header-color, $header-color, $link-hover);
  line-height: .973;
  display: flex;
  align-items: center;
  font-weight: normal;
  .text {
    @include rem(padding-left, $space-half);
    display: flex;
    flex-flow: column;
  }
  .small {
    @include rem(font-size, $font-size-h3);
    font-style: italic;
    line-height: inherit;
  }

}
.snapshot-wrapper {
  @include rem(margin, $space auto);
  font-family: $font-secondary;
  .tagline {
    color: $blue-darkest;
    font-style: italic;
    @include rem(font-size, $font-size-tagline);
    line-height: $line-height-tagline;
    @include rem(margin-top, $space);
  }
}


@include breakpoint($tab) {
  .snapshot-wrapper {
    @include rem(margin, $space auto $space-and-half);
    display: flex;
    align-items: center;
    .tagline {
      margin-top: 0;
      @include rem(padding-left, $pad);
      @include rem(max-width, 260px);
    }
  }
  .snapshot-logo {
    border-right: 3px $blue-brand solid;
    margin-bottom: 0;
    @include rem(padding-right, $pad);
  }

}
