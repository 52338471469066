@use "sass:math";

.l-navigation,
.l-region--header {
  .block--superfish {
    width: 98%;
    .block__content {
      position: relative;
    }
  }
  .sf-accordion-toggle {
    text-align: right;
  }
  //indent goal number
  #menu-5547-si_book_nav_328692 .sf-depth-2 > a {
    @include rem(paddin-left, 26px);
    span {
      display: inline-block;
    }
    .index {
      @include rem(margin-left, -16px);
    }
  }
  .sf-menu {
    line-height: $baseLineHeight;
    margin: 0;
    padding: 0;
    background: $mobile-menu;
    width: 100%;
    max-width: 30em;
    &.sf-accordion.sf-expanded {
      top: -5.6px !important;
      top: -.56rem !important;

    }
    &.sf-navbar {
      width: 100%;
    }
    ul {
      padding-left: 0;
    }
    #menu-3905-1 .sf-clone-parent,
    #menu-5547-si_book_nav_328692 .sf-clone-parent {
      display: none !important;
    }
    li {
      a {
        @include rem(font-size, $font-size-base);
        font-family: $font;
        letter-spacing: 1px;
        //text-transform: uppercase;
        font-weight: 500;
        @include link-colors(white, white, white, white, white);
        @include rem(padding, math.div($space,3) $pad-half);
      }
      ul {
        background: $mobile-menu;
        @include rem(padding-left, $pad-and-half);
      }
    }
    //top-level
    > li {
      > a {
        @include rem(padding-left, $pad);
        @include rem(margin-left, 8px);
      }
    }
  } //end mobile .sf-menu

  @include breakpoint(max-width 1200px) {
    .sf-accordion {
      li.active-trail {
        background-color: #000;
        position: relative;
        &::before {
          content: '';
          border-left: 3px solid $yellow;
          width: 3px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
        }
        > a {
          color: $yellow;
        }
      }
    }
  }

  @include breakpoint('all' 1200px) {
    background: $header-bg;
    .block--superfish,
    .block--si-custom {
      position: relative;
      width: auto;
      top: auto;
      right: auto;
      display: flex;
      justify-content: flex-end;
      .node-type-book & {
        @include rem(padding-right, 28px+$pad-half);
      }
      //.block__content {
      //  @include clearfix;
      //  float: right;
      //}
    } // end block--superfish
    .sf-menu.sf-style-none {
      background: $header-bg;
      box-shadow: none;
     display: flex;
      justify-content: flex-end;
      max-width: 100%;
      li {
        a {
        //  @include font-smoothing;
          @include link-colors(white, $link-color-alt, $link-color-alt, white, $link-color-alt);
          &.active {
            color: $link-color-alt;
          }
        }
        ul {
          background: $preface-bg;
          //background: darken($header-bg, 12%);// $header-bg-darkest;
          //background: $blue;
        }
        &.sf-depth-2 {
          ul {
            display: none !important;
          }
        }
        &.active-trail > a {
          // @include background-image(linear-gradient(to bottom, $blue, $mobile-menu 70%));
          // background: darken($header-bg, 12%);// $header-bg-darkest;
          color: $link-color-alt;
        }
      } //end li
      //a > .sf-sub-indicator, span.nolink > .sf-sub-indicator {
      //  top: .8em;
      //  right: .5em;
      //}
      >li {
        border-top: 3px solid transparent;
        @include transition(.4s border-top ease-in, .4s background ease-in);
        &.sfHover {
          background: $preface-bg;
          border-top: 3px solid $yellow;
//          background: darken($header-bg, 12%);// $header-bg-darkest;
        }

        &.menuparent {
          .node-type-book & {
            > a {
              @include font-icon($fa-var-angle-down, 5px, inherit, true);
            }
            &.sfHover {
              > a {
                &:after {
                  content: unquote('"#{$fa-var-angle-up}"');
                }
              }
            }
            &.active-trail > a:after {
              color: $yellow;
            }
          }
        }
        > a {
          display: block;
          @include rem(font-size, $font-size-h5);
          @include rem(padding, 22px 15px $pad-half);
          @include rem(line-height, 69px);
          margin-left: 0;
          .node-type-book & {
            line-height: 1.5;
            @include rem(padding, 12px $pad-half 15px);

          }
          &:before {
            content: none;
          }
        }
        &.sf-expanded {
          background: $header-bg;
          > a:before {
            content: '';
          }
        }

        > ul,
        > ol {
          padding-left: 0;
          border-radius: 0;
          @include transition(all .4s ease-in);
         // @include box-shadow(rgba(black, .2) 0 5px 5px);
          @include box-shadow(rgba(black, .3) 1px 1px 2px);
         // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          border-bottom: none;
          @include rem(min-width, 48px);

          .node-type-book & {
            @include rem(min-width, 350px);
          }
        }
        &.sfHover {
          > ul {
            //top: 5.85em !important;
            //@include rem(line-height, 69px);
            top: 100px !important;
            top: rem(100px) !important;
            left: 0 !important;
            .node-type-book & {
              //top: 3.65em !important;
              top: 54px !important;
              top: rem(54px) !important;
            }
          }
          &.last {
            > ul {
              left: auto !important;
              right: 0 !important;
            }
          }
        }
      } // end top-level li
    } //end sf-menu.sf-style-none

  } // end breakpoint($desk)
  @include breakpoint($wide) {
    .sf-menu.sf-style-none > li > a {
      @include rem(padding, 22px $pad-half $pad-half);
    }
  }
  @include breakpoint('all' 1680px) {
    .sf-menu.sf-style-none {
      > li.sf-depth-1.last.sfHover {
        > ul {
          top: 100px !important;
          top: rem(100px) !important;
          left: 0 !important;
          right: auto !important;
          .node-type-book & {
            top: 55px !important;
            top: rem(55px) !important;
          }
        }
      }
    }

  }
} //end l-navigation

