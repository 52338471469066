.paragraphs-item-grid-layout.bg-overlay {
  .node--teaser-long {
    .inner {
      background: none;
      border: none;
      -ms-transform: translateY(0);
      transform: translateY(0);
      transition: transform .45s, box-shadow .3s ease-in-out;
    }

    .b-text-wrapper {
      text-shadow: 2px 1px 2px rgba(0, 0, 0, .3);

      * {
        color: white;
      }

      position: absolute;
      top: 60%;
      //bottom: 60%;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;

      h3 {
        @include rem(font-size, $font-size-h3);
        line-height: $line-height-h3;
        text-align: center;
      }

      z-index: 10;
    }

    .group-wrapper {
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      align-items: center;
      //justify-content: center;
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 75%;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      transition: opacity .5s, bottom .5s ease-in-out;
      left: 0;
      bottom: 0;
      opacity: .8;
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.98) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 50%,rgba(0,0,0,0.98) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 50%,rgba(0,0,0,0.98) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#fa000000',GradientType=0 ); /* IE6-9 */
    }
  }

  .metadata {
    padding: 0;
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0;
    z-index: 5;
    text-align: center;
    transition: opacity .5s, bottom .5s ease-in-out;

    * {
      color: white;
    }

    opacity: 0;
  }

  .inner:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .25);

    .overlay:before {
      //bottom: 0;
      opacity: 1;
    }

    .metadata {
      bottom: 30px;
      opacity: 1;

      a:before {
        color: white;
      }
    }
  }
}

.paragraphs-item-grid-layout.border,
.grid-wrapper.border {
  .inner {
    background: none;

    &:before,
    &:after {
      box-shadow: none;
    }
  }
}
.view .col-md-4 > ul,
.view .col-wd-2 > ul,
.view .col-md-3 > ul,
.view .col-md-5 > ul,
.view .col-md-2 > ul {
  li {
    @include rem(margin-bottom, $space !important);
  }
}

.views-view-grid.horizontal > div {
  @include rem(margin-bottom, $space);
}
@include breakpoint($tab) {
  .paragraphs-item-grid-layout > .content.field-group-html-element {
    display: block;
  }
  .paragraphs-item-grid-layout > .content,
  .view .col-md-4 > ul,
  .view .col-wd-2 > ul,
  .view .col-md-3 > ul,
  .view .col-md-5 > ul,
  .view .col-md-2 > ul,
  .views-view-grid.horizontal {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    @include rem(grid-gap, $space);
    > div,
    .node-teaser,
    > li {
      margin-top: 0;
      display: flex;
      margin-bottom: 0 !important;
    }
  }

  .paragraphs-item-grid-layout.col-sm-2 > .content,
  .view .col-md-2 > ul,
  .views-view-grid.horizontal.col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

}


@include breakpoint($desk) {
  .paragraphs-item-grid-layout.bg-overlay {
    .node--teaser-long {
      .b-text-wrapper {
        h3 {
          @include rem(font-size, $font-size-h2);
          line-height: $line-height-h2;
        }
        p {
          @include rem(font-size, $font-size-h5);
          line-height: $line-height-h5;

        }
      }
    }
  }
  .paragraphs-item-grid-layout.col-md-3 > .content,
  .view .col-md-3 > ul {
    grid-template-columns: repeat(4, 1fr);
  }
  .paragraphs-item-grid-layout.col-md-3 > .content,
  .view .col-md-3 > ul {

  }
  .views-view-grid.horizontal.col-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .paragraphs-item-grid-layout.col-md-5 > .content,
  .view .col-md-5 > ul,
  .paragraphs-item-grid-layout.col-wd-2 > .content,
  .view .col-wd-2 > ul,
  .views-view-grid.horizontal,
  .paragraphs-item-grid-layout.col-md-4 > .content,
  .view .col-md-4 > ul,
  .views-view-grid.horizontal.col-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@include breakpoint($wide) {
  .paragraphs-item-grid-layout.col-wd-3 > .content,
  .view .col-wd-3 > ul {
    grid-template-columns: repeat(4, 1fr);
  }
  .paragraphs-item-grid-layout.col-md-5 > .content,
  .view .col-md-5 > ul,
  .views-view-grid.horizontal.col-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .paragraphs-item-grid-layout.col-wd-2 > .content,
  .view .col-wd-2 > ul,
  .views-view-grid.horizontal.col-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
