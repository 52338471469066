.vocabulary-media-contacts {
    p {
      margin-bottom: 0;
    }
  ul {
    @extend %reset-list;
  }

  &.view-mode-teaser {
    @include rem(margin-bottom, $space);
  }
  &.view-mode-teaser_2 {

  }
  .field--name-field-tagline {
    font-family: $font;
    font-style: normal;
  }
}

.page-taxonomy-term.newsdesk {
  .l-region--hero {
    position: relative;
    @include rem(margin-bottom, $space);
    .pane-panels-mini {
      background: darken($gray-darkest, 5%);
      * {
        color: white;
      }
      color: white;
      img {
        width: 100%;
      }
     // @include rem(margin-bottom, $space-and-half);
    }
    .col--right {
      @include rem(padding-top, $space);
      @include rem(padding-bottom, $space);
    }
    .l-container {
      width: 100%;
    }
    .col--right {
      width: 96%;
    }
  }
  .l-region--main-content {
    .field--type-image {
      border: 1px solid darken($gray-lightest, 2%);
      background: $gray-lightest;
      //  @include rem(margin-top, $space);
      @include rem(padding, $space-half $pad*.75);
      text-align: center;
    }
  }

    @include breakpoint($tab) {
      .l-region--hero {
        .col--right {
          width: span(6);
        }
        @include rem(margin-bottom, $space-and-half);

        .pane-panels-mini {
        @include rem(margin-bottom, $space-and-half);
      }
      }
      .pane-addthis-addthis-block > .wrapper--addthis {
        top: 0;
      }
      .pane-taxonomy-term-field-media-contact {
          .items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .taxonomy-term {
              width: span(6);
              max-width: span(6);
            }
          }
      }
    }

    @include breakpoint($desk) {
      .pane-addthis-addthis-block > .wrapper--addthis {
        @include rem(top, -$space-half);
      }
      .pane-taxonomy-term-field-media-contact {
        .items {
          .taxonomy-term {
            width: span(3);
            max-width: span(3);
          }
        }
      }
    }

}

