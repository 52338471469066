@mixin visit-flag() {
  @include rem(padding, $pad-half);

  background-color: transparent;
  border: 1px solid transparent;
  color: $gray;
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  text-shadow: -1px 1px white, -1px -1px white, 1px 1px 0px white,
    1px -1px white;
  transition: background-color 300ms ease-out, border-color 320ms ease-out;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: transparent;
    border-color: desaturate(lighten($btn-hover, 25%), 5%);
    color: $visit-saved;

    i {
      &:before {
        color: $gray;

        &:hover,
        &:focus,
        &:focus-visible {
          color: $gray;
        }
      }
    }
  }

  &.clicked-focus {
    color: $gray;

    &:hover,
    &:focus,
    &:focus-visible {
      color: $gray;
    }

    i {
      &:before {
        color: $gray;

        &:hover,
        &:focus,
        &:focus-visible {
          color: $gray;
        }
      }
    }
  }

  &.saved {
    color: $visit-saved;

    i {
      &:before {
        color: $visit-saved;

        &:hover,
        &:focus,
        &:focus-visible {
          color: $visit-saved;
        }
      }
    }
  }

  &.saved.clicked-focus {
    color: $visit-saved;

    &:hover,
    &:focus-visible {
      color: $visit-saved;
    }

    i {
      &:before {
        color: $visit-saved;

        &:hover,
        &:focus,
        &:focus-visible {
          color: $visit-saved;
        }
      }
    }
  }
}

$visit-saved: $burgundy;
// MyVisit
.myvisit__details {
  position: relative;
  .btn {
    margin-bottom: 0;
  }
  a {
    @include link-colors(
      $link-color,
      $link-hover,
      $link-hover,
      $link-color,
      $link-color
    );
  }
  .myvisit__btn {
    background-color: transparent;
    border-color: transparent;
  }
  .myvisit__btn-save-list,
  .myvisit__btn-login {
    &.disabled {
      display: none;
    }
  }
  .myvisit__container {
    position: absolute;
    background-color: $white;
    color: $text-color;
    box-shadow: 6px 6px 10px rgba($gray-darker, 0.5);

    @include rem(padding, $pad-half);
    @include rem(margin-top, $pad-half);
    z-index: 1000;

    &::before {
      display: block;
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 $pad-half $pad-half $pad-half;
      border-color: transparent transparent $white transparent;
      transform: rotate(0deg);

      position: absolute;
      top: -$pad-half;
      left: $pad-double;
    }
  }
  .myvisit__lists {
    @include rem(margin, $pad-half);

    list-style: none;
    margin-bottom: 0;
  }
}

// Visit Flag

.visit-flag {
  .my-visit,
  .edan-search-result &,
  .location-info &,
  .location-wrapper &,
  .visit-flag-section &,
  .exhbition-block .swiper-slide & {
    @include visit-flag;
  }
}

.my-visit p.visit-flag {
  position: relative;
  top: auto;
  right: auto;
  @include rem(margin-bottom, $space);
}

.visit-flag-section {
  margin-top: -20px;

  $this: &;

  #{$this}__button {
    position: static;
    top: auto;
    right: auto;
  }
}
